/* eslint-disable react/no-array-index-key */
import { useState, useEffect } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { css } from '@emotion/react';

import useBackKeyEvent from '@hook/useBackKeyEvent';
import useHistoryHook from '@hook/useHistory';

import { AlertDataType } from '@type/webView/alert';

import {
  alertAtom,
  alertCloseSelector,
} from '@recoils/orderPaymentDelivery/alert';

import { palette } from '@lib/styles/palette';
import typography from '@lib/styles/typography';
import { htmlStringToReactElement } from '@lib/utils/parser';

import AlertPotal from '../Alert/AlertPotal';
import Image from '../Image';

const Alert = () => {
  const { history } = useHistoryHook();
  const [callBack, setCallBack] = useState<{
    onClick?: AlertDataType['onClick'];
  }>({
    onClick: undefined,
  });

  const {
    className,
    show,
    title,
    text,
    imageUrl,
    subText,
    boxDescription,
    notice,
    onClick,
    bottomText,
    bottomBackground,
    onCancel,
  } = useRecoilValue(alertAtom);
  const closeAlert = useSetRecoilState(alertCloseSelector);

  let titleArr: JSX.Element[] = [];
  if (title) {
    if (typeof title === 'string') {
      titleArr = title.split('\n').map((t, i) => (
        <h1 css={titleStyle} key={`alertTitle_${t}_${i}`}>
          {t}
        </h1>
      ));
    } else titleArr = [title];
  }

  let textArr: JSX.Element[] | JSX.Element = [];
  if (typeof text === 'string') {
    textArr = text.split('\n').map((t, i) => {
      const style = subText ? titleStyle : textStyle;
      return (
        <p css={style} key={`alertText_${t}_${i}`}>
          {htmlStringToReactElement(t)}
        </p>
      );
    });
  } else textArr = [text];

  let subTextArr: JSX.Element[] = [];
  if (subText) {
    if (typeof subText === 'string') {
      subTextArr = subText.split('\n').map((t, i) => (
        <p css={subTextStyle} key={`alertSubText_${t}_${i}`}>
          {htmlStringToReactElement(t)}
        </p>
      ));
    } else subTextArr = [subText];
  }

  let boxDescriptionArr: JSX.Element[] = [];
  if (boxDescription) {
    if (typeof boxDescription === 'string') {
      boxDescriptionArr = boxDescription
        .split('\n')
        .map((t, i) => (
          <p key={`alertBoxDescription_${t}_${i}`}>
            {htmlStringToReactElement(t)}
          </p>
        ));
    } else boxDescriptionArr = [boxDescription];
  }

  let noticeArr: JSX.Element[] = [];
  if (notice) {
    if (typeof notice === 'string') {
      noticeArr = notice.split('\n').map((t, i) => (
        <p css={noticeStyle} key={`alertNotice_${t}_${i}`}>
          {htmlStringToReactElement(t)}
        </p>
      ));
    } else noticeArr = [notice];
  }

  const footer = Array.isArray(bottomText) ? (
    bottomText.slice(0, 2).map((t, i) => {
      return (
        <button
          key={`alertBottomText_${t}`}
          css={[footerButtonStyle, i !== 0 && rebrandingStyle]}
          type="button"
          onClick={() => {
            closeAlert();
            if (i !== 0) setCallBack({ onClick });
            else setCallBack({ onClick: onCancel });
          }}
        >
          {t}
        </button>
      );
    })
  ) : (
    <button
      css={[
        footerButtonStyle,
        rebrandingStyle,
        bottomBackground === 'black' && blackBgStyle,
      ]}
      type="button"
      onClick={() => {
        closeAlert();
        setCallBack({ onClick });
      }}
    >
      {bottomText}
    </button>
  );

  useBackKeyEvent(closeAlert, show);

  useEffect(() => {
    if (!show && callBack.onClick) {
      callBack.onClick();
      setCallBack({ onClick: undefined });
    }
  }, [show]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      closeAlert();
    });

    return () => {
      unlisten();
    };
  }, []);

  return (
    // <PopUp show={show} css={popUpStyle}>
    <AlertPotal show={show} css={popUpStyle}>
      <div className={className} css={alertStyle}>
        <div css={alertBodyStyle}>
          {titleArr?.length > 0 ? titleArr : title}
          {textArr?.length > 0 ? textArr : text}
          {subTextArr?.length > 0 && <div>{subTextArr}</div>}
          {boxDescriptionArr?.length > 0 && (
            <div css={descriptionBoxStyle}>{boxDescriptionArr}</div>
          )}
          {noticeArr?.length > 0 ? noticeArr : notice}
          {imageUrl && (
            <Image
              src={imageUrl}
              alt={`${text}`}
              className="alertImage"
              dimOpacity={0.05}
            />
          )}
        </div>
        <div css={alertFooterStyle(Array.isArray(bottomText))}>{footer}</div>
      </div>
    </AlertPotal>
  );
};

const popUpStyle = css`
  z-index: 600;
`;

const alertStyle = css`
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
`;

const alertBodyStyle = css`
  padding: 28px 20px 24px;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 1.5;
  background-color: ${palette.white};

  .alertImage {
    margin-top: 20px;

    & > img {
      width: 100%;
      height: auto;
    }
  }
`;

const alertFooterStyle = (isMultiButtons: boolean) => css`
  display: flex;
  width: 100%;
  padding: 0 20px 20px 20px;
  background-color: ${palette.white};
  justify-content: space-between;

  ${isMultiButtons &&
  css`
    button:first-of-type {
      flex: 0 1 49%;
    }

    button:last-of-type {
      flex: 0 1 49%;
    }
  `}
`;

const footerButtonStyle = css`
  display: inline-block;
  width: 100%;
  height: 100%;
  min-height: 44px;
  padding: 13px;
  color: ${palette.gray900};
  text-align: center;
  vertical-align: middle;
  background-color: ${palette.white};
  font-size: 14px;
  font-weight: 500;
  border: 1px solid ${palette.gray400};
  border-radius: 4px;

  &:last-child {
    border-right: none;
  }
`;

const blackBgStyle = css`
  color: ${palette.white};
  border: 1px solid ${palette.black10};
  background-color: ${palette.black10};
`;

const rebrandingStyle = css`
  color: ${palette.white};
  border: 1px solid ${palette.gray900};
  background-color: ${palette.gray900};
`;

const titleStyle = css`
  margin-bottom: 12px;
  font-size: 20px;
  color: ${palette.gray900};
  font-weight: 600;
`;

const textStyle = css`
  ${typography.b2}
  color: ${palette.gray900};
  span {
    color: #ff0000;
  }
`;

const subTextStyle = css`
  font-size: 16px;
  color: ${palette.orderGray700};
`;

const descriptionBoxStyle = css`
  width: 100%;
  margin-top: 14px;
  margin-bottom: 14px;
  padding: 9px 11px;
  border-radius: 4px;
  background-color: ${palette.gray200};
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  color: ${palette.gray700};
  span {
    color: #ff0000;
  }
`;

const noticeStyle = css`
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  color: ${palette.gray700};
`;

export default Alert;
