import { GOOGLE_CLICK_ID, ASSOCIATED_AD_KEYWORD, AF_KEYWORDS } from '../constants/smartScript';

const isSkippedURL = ({ url, skipKeys, errorMsg }) => {
  // search if this page referred and contains one of the given keys
  if (url) {
    const lowerURL = decodeURIComponent(url.toLowerCase());
    if (lowerURL) {
      const skipKey = skipKeys.find(key => lowerURL.includes(key.toLowerCase()));
      !!skipKey && console.debug(errorMsg, skipKey);
      return !!skipKey;
    }
  }
  return false;
};

const getAFImpressionURL = (oneLinkParts, finalParams) => {
  //we take the domain[4] and onelink id[5] from oneLink URL
  return `https://impressions.${oneLinkParts[4]}${oneLinkParts[5]}?${finalParams}`;
};

const getGoogleClickIdParameters = (gciKey, currentURLParams) => {
  const gciParam = currentURLParams[GOOGLE_CLICK_ID];
  const result = {};
  if (gciParam) {
    console.debug('This user comes from Google AdWords');
    result[gciKey] = gciParam;
    const keywordParam = currentURLParams[ASSOCIATED_AD_KEYWORD];
    if (keywordParam) {
      console.debug('There is a keyword associated with the ad');
      result[AF_KEYWORDS] = keywordParam;
    }
  } else {
    console.debug('This user comes from SRN or custom network');
  }
  return result;
};

export { isSkippedURL, getAFImpressionURL, getGoogleClickIdParameters };
