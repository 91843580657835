/* eslint-disable consistent-return */
import { useEffect, useLayoutEffect, useState } from 'react';

import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

interface ReturnType {
  setLocked: (locked: boolean) => void;
}

function useLockedBody(initialLocked = false): ReturnType {
  const [locked, setLocked] = useState(initialLocked);

  // Do the side effect before render
  useLayoutEffect(() => {
    if (!locked) {
      return;
    }

    // Save initial body style
    const originalPaddingRight = document.body.style.paddingRight;

    // Lock body scroll
    // disableBodyScroll(document.body);
    document.body.style.overflow = 'hidden';
    /* 이외의 브라우저 */

    // Get the scrollBar width
    const root = document.getElementById('___gatsby'); // or root
    const scrollBarWidth = root ? root.offsetWidth - root.scrollWidth : 0;

    // Avoid width reflow
    if (scrollBarWidth) {
      document.body.style.paddingRight = `${scrollBarWidth}px`;
    }

    return () => {
      // enableBodyScroll(document.body);
      document.body.style.overflow = '';
      if (scrollBarWidth) {
        document.body.style.paddingRight = originalPaddingRight;
      }
    };
  }, [locked]);

  // Update state if initialValue changes
  useEffect(() => {
    if (locked !== initialLocked) {
      setLocked(initialLocked);
    }

    // return () => {
    //   clearAllBodyScrollLocks();
    // };
  }, [initialLocked]);

  return { setLocked };
}

export default useLockedBody;
