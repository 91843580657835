import { ReactElement, ReactNode } from 'react';

import styled from '@emotion/styled';

import useGoBack from '@hook/useGoBack';

import { ReactComponent as ArrowLeft } from '@components/common/Icon/svg/arrowleft.svg';
import { ReactComponent as ShareIcon } from '@components/common/Icon/svg/share-IOS.svg';

import { DOMAIN } from '@lib/constants/const';
import { palette } from '@lib/styles/palette';
import { interfaceSharePopup } from '@lib/utils/interface';
import { shareURL } from '@lib/utils/share';

type Props = { children: ReactNode };

const Layout = ({ children }: Props): ReactElement => {
  const goBack = useGoBack();

  const onShowSharePopup = () => {
    return (
      interfaceSharePopup('SHARE_URL', {
        title: '3월 중요일 이벤트',
        linkUrl: `${DOMAIN}/event/important-day/202403`,
      }) ||
      shareURL({
        title: '3월 중요일 이벤트',
        url: `${DOMAIN}/event/important-day/202403`,
      })
    );
  };

  return (
    <Main>
      <Header>
        <HeadContainer>
          <ArrowLeft style={{ cursor: 'pointer' }} onClick={goBack} />
          <h1 className="center">3월 중요일 이벤트</h1>
          <ShareIcon style={{ cursor: 'pointer' }} onClick={onShowSharePopup} />
        </HeadContainer>
      </Header>

      <ContentContainer>{children}</ContentContainer>
    </Main>
  );
};

const Main = styled.main`
  height: 100vh;
  -webkit-touch-callout: none;
`;

const Header = styled.header`
  position: relative;
  width: 100%;
  height: 44px;
`;

const HeadContainer = styled.div`
  width: 100%;
  background: rgba(255, 255, 255, 1);
  top: 0;
  z-index: 99;
  height: 44px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  padding: 0 20px;

  .center {
    width: calc(100% - 100px);
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  background-color: ${palette.white};
`;

export default Layout;
