import { ReactElement } from 'react';
import { useInView } from 'react-intersection-observer';

import cn from 'classnames';

import styled from '@emotion/styled';

import ImageComponent from '@components/common/Image';

import { importantDayImageSrc } from '@lib/data/ImportantDay/Prize';
import { day, isAfterTime } from '@lib/utils/time';

import { List, Wrapper, Item, Date } from './Ui';

type Props = {
  date: string;
  className?: string;
  prizes: {
    title: string;
    link: string;
    imgSrc: string;
    priceBefore: string;
    pricePercent: string;
    priceAfter: string;
    delivery: boolean;
  }[];
};

const PrizeList = ({ date, prizes, className }: Props): ReactElement => {
  const [ref, inView] = useInView({ triggerOnce: true });

  const onClickOpenAppBtn = (path: string) => {
    const scheme = 'jnapps3://?';

    window.open(`${scheme}${path}`);
  };

  const isActive =
    date === '0306' ||
    (date === '0307' && isAfterTime(day(), day('2024-03-07 12:00:00'))) ||
    (date === '0308' && isAfterTime(day(), day('2024-03-08 11:30:00')));

  return (
    <Wrapper className={className} ref={ref}>
      <Date
        isSquare={false}
        alt={`${date} Open`}
        src={`${importantDayImageSrc}/prize/202403/date_${date}_${
          isActive ? 'active' : 'inactive'
        }.png`}
      />

      <List>
        {inView &&
          prizes.map((prize) => (
            <Item key={prize.title}>
              <button
                style={{ width: '100%' }}
                disabled={!isActive}
                onClick={() => onClickOpenAppBtn(prize.link)}
              >
                <Image
                  lazy={true}
                  src={prize.imgSrc}
                  alt={prize.title}
                  className={cn({ inactive: !isActive })}
                />
              </button>
            </Item>
          ))}
      </List>
    </Wrapper>
  );
};

const Image = styled(ImageComponent)`
  width: 100%;
  aspect-ratio: 248 / 411;
  position: relative;

  &.inactive::after {
    pointer-events: none;
    background-image: url('https://common.joongna.com/image/important_day/prize/202403/prize_disabled.png');
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    aspect-ratio: 1 / 1;
    z-index: 10;
  }
`;

export default PrizeList;
