/* eslint-disable @typescript-eslint/no-shadow */
import {
  ReactElement,
  ReactNode,
  useEffect,
  useCallback,
  PropsWithChildren,
} from 'react';
import ReactDOM from 'react-dom';

import { clearAllBodyScrollLocks } from 'body-scroll-lock';

import { css, SerializedStyles } from '@emotion/react';

import useBoolean from '@hook/useBoolean';
import useLockedBody from '@hook/useLockedBody';

import Dim from '@components/web/popup/Dim';
import PopUp from '@components/web/popup/PopUp';
import PopUpAppDownload from '@components/web/popup/PopUpAppDownload';
import PopUpFullPage from '@components/web/popup/PopUpFullPage';
import Toast from '@components/web/popup/Toast';

type PopupType =
  | 'dimPopup'
  | 'PagePopUp'
  | 'PagePopUpAlpah'
  | 'AppDownload'
  | 'dim'
  | 'toast';

interface PopupChildren {
  children?: ReactNode;
}
interface PopupSwitch extends PopupChildren {
  isShow: boolean;
  opacity: SerializedStyles;
  toggle: () => void;
  onShow: () => void;
  onHide: () => void;
}

interface UsePortal {
  isShowPopUp: boolean;
  onTogglePopUp: () => void;
  onShowPopUp: () => void;
  onHidePopUp: () => void;
  PopUp: ({ children }: PopupChildren) => ReactElement;
}

const usePortal = (
  type: PopupType = 'dimPopup',
  dimOpacity?: number,
): UsePortal => {
  const {
    value: isShowPopUp,
    toggle,
    setTrue: onShow,
    setFalse: onHide,
  } = useBoolean(false);
  const { setLocked } = useLockedBody();

  const popUpEle = document.getElementById('popUpPortal') as HTMLElement;
  const Portal = ({ children }: PopupChildren) =>
    ReactDOM.createPortal(children, popUpEle);

  useEffect(() => {
    if (type === 'toast') return;

    if (type !== 'AppDownload') {
      setLocked(isShowPopUp);
    }
  }, [isShowPopUp]);

  /** type에 맞게 팝업 형태 선택 */
  const SwitchPopUp = ({
    children,
    isShow,
    toggle,
    onShow,
    onHide,
    opacity,
  }: PopupSwitch) => {
    switch (type) {
      case 'AppDownload':
        return <PopUpAppDownload toggle={toggle} opacity={opacity} />;
      case 'PagePopUp':
        return (
          <PopUpFullPage onHide={onHide} opacity={opacity}>
            {children}
          </PopUpFullPage>
        );
      case 'PagePopUpAlpah':
        return (
          <PopUpFullPage
            onHide={onHide}
            opacity={opacity}
            backgroundAlpha={0.96}
          >
            {children}
          </PopUpFullPage>
        );
      case 'dim':
        return (
          <Dim onHide={onHide} opacity={opacity}>
            {children}
          </Dim>
        );
      case 'toast':
        return (
          <Toast onShow={onShow} onHide={onHide}>
            {children}
          </Toast>
        );
      default:
        return (
          <PopUp toggle={toggle} onHide={onHide} opacity={opacity}>
            {children}
          </PopUp>
        );
    }
  };

  /** portal을 통해 팝업 노출 */
  const CreatePopUp = ({ children }: PopupChildren) => {
    useEffect(() => {
      return () => {
        // @common/PopUp과 usePortal 동시 사용 시 scrollLock 제거를 위한 조건
        if (popUpEle.children.length === 1) clearAllBodyScrollLocks();
      };
    }, []);

    if (!isShowPopUp) return <></>;
    return (
      <Portal>
        <SwitchPopUp
          isShow={isShowPopUp}
          toggle={toggle}
          onShow={onShow}
          onHide={onHide}
          opacity={css`
            opacity: ${dimOpacity ?? 0.6};
          `}
        >
          {children}
        </SwitchPopUp>
      </Portal>
    );
  };

  return {
    isShowPopUp,
    onTogglePopUp: toggle,
    onShowPopUp: onShow,
    onHidePopUp: onHide,
    PopUp: CreatePopUp,
  };
};

export default usePortal;
