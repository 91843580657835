import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  RefObject,
} from 'react';
import { Link } from 'react-router-dom';

import { css } from '@emotion/react';

import { palette } from '@lib/styles/palette';
import { hexToRgba } from '@lib/utils/parser';

type ButtonType = 'primary' | 'secondary' | 'light' | 'link' | 'gray';
type ButtonSize = 'small' | 'medium' | 'large';
type ButtonShape = 'square' | 'round' | 'semiRound' | 'circle';

interface ButtonProps {
  to?: string;
  href?: string;
  children?: React.ReactNode | React.ReactNode[];
  type?: ButtonType;
  size?: ButtonSize;
  shape?: ButtonShape;
  // onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  onClick?: (e: any, x?: any) => void;
  onMouseEnter?: (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement> | undefined,
  ) => void;
  onMouseLeave?: (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement> | undefined,
  ) => void;
  disabled?: boolean;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  width?: string;
  height?: string;
  padding?: string;
  color?: string;
  fontSize?: string;
  fontWeight?: number;
  borderColor?: string;
  backgroundColor?: string;
  loading?: boolean;
  fullWidth?: boolean;
  className?: string;
  replace?: boolean;
}

function Button(
  {
    to = '',
    href = '',
    children,
    onClick = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    type = 'primary',
    size = 'medium',
    shape = 'semiRound',
    loading,
    leftIcon,
    rightIcon,
    width,
    height,
    padding,
    color,
    fontSize,
    fontWeight,
    borderColor,
    backgroundColor,
    fullWidth = false,
    disabled = false,
    className = '',
    replace = false,
    ...props
  }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement | HTMLAnchorElement>,
): ReactElement {
  const buttonStyle = [
    wrap,
    buttonCss(
      type,
      size,
      shape,
      width,
      height,
      padding,
      color,
      fontSize,
      fontWeight,
      borderColor,
      backgroundColor,
      fullWidth,
      disabled,
      leftIcon,
      rightIcon,
    ),
  ];
  if (to) {
    return (
      <Link
        to={to}
        css={buttonStyle}
        className={className}
        onClick={onClick}
        replace={replace}
        {...props}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={ref as RefObject<HTMLAnchorElement>}
      >
        {leftIcon && leftIcon}
        {children}
        {rightIcon && rightIcon}
      </Link>
    );
  }

  if (href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        css={buttonStyle}
        className={className}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        {...props}
      >
        {loading && <i className="fas fa-spinner fa-spin" />}
        {leftIcon && leftIcon}
        {children}
        {rightIcon && rightIcon}
      </a>
    );
  }

  return (
    <button
      css={buttonStyle}
      className={className}
      disabled={disabled}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={ref as RefObject<HTMLButtonElement>}
      {...props}
    >
      {loading && <i className="fas fa-spinner fa-spin" />}
      {leftIcon && leftIcon}
      {children}
      {rightIcon && rightIcon}
    </button>
  );
}

const wrap = css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: none;
  outline: none;
`;

const buttonSize = (size: ButtonSize) => {
  switch (size) {
    case 'small':
      return css`
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
        border-radius: 0.125rem;
      `;
    case 'large':
      return css`
        padding: 1rem 2rem;
        font-size: 1.2rem;
        border-radius: 0.5rem;
      `;
    case 'medium':
    default:
      return css`
        padding: 0.8rem 1.5rem;
        font-size: 1rem;
        border-radius: 0.25rem;
      `;
  }
};

const butttonColor = (
  type: ButtonType,
  backgroundColor?: string,
  borderColor?: string,
  color?: string,
) => {
  switch (type) {
    case 'secondary':
      return css`
        background-color: ${backgroundColor || palette.white};
        color: ${color || palette.orange9};
        border: 1px solid ${`${borderColor || palette.orange9}`};
        svg {
          stroke: ${backgroundColor || palette.orange9};
        }

        &:hover {
          color: ${hexToRgba(color || palette.orange9, 0.7)};
          border: 1px solid ${hexToRgba(borderColor || palette.orange9, 0.7)};
          svg {
            stroke: ${hexToRgba(borderColor || palette.orange9, 0.7)};
          }
        }
      `;
    case 'light':
      return css`
        background-color: transparent;
        color: ${color || palette.black10};
        border: 1px solid ${hexToRgba(borderColor || palette.black10, 0.3)};
        svg {
          color: ${color || palette.black10};
        }

        &:hover {
          color: ${hexToRgba(color || palette.black10, 0.7)};
          border: 1px solid ${hexToRgba(borderColor || palette.black10, 0.6)};

          svg {
            stroke: ${hexToRgba(color || palette.black10, 0.7)};
          }
        }
      `;
    case 'link':
      return css`
        background-color: transparent;
        border: none;
        color: ${color || palette.orange9};
        &:hover {
          color: ${hexToRgba(color || palette.orange9, 0.7)};
        }
      `;
    case 'gray':
      return css`
        background-color: #f5f5f5;
        color: #0a0400;
        border: 1px solid #f5f5f5;
        svg {
          stroke: #0a0400;
        }
      `;
    case 'primary':
    default:
      return css`
        background-color: ${backgroundColor || palette.orange9};
        border: 1px solid ${backgroundColor || palette.orange9};
        color: ${color || palette.white};
        svg {
          stroke: ${color || palette.white};
        }

        &:hover {
          background-color: ${hexToRgba(
            backgroundColor || palette.orange9,
            0.7,
          )};
          border: 1px solid
            ${hexToRgba(backgroundColor || palette.orange9, 0.7)};
          color: ${color || palette.white};
          svg {
            stroke: ${color || palette.white};
          }
        }
      `;
  }
};

const buttonShape = (shape: ButtonShape) => {
  switch (shape) {
    case 'round':
      return css`
        border-radius: 2rem;
      `;
    case 'circle':
      return css`
        border-radius: 50%;
      `;
    case 'square':
      return css`
        border-radius: 0;
      `;
    case 'semiRound':
    default:
      return css`
        border-radius: 0.25rem;
      `;
  }
};

const buttonCss = (
  type: ButtonType,
  size: ButtonSize,
  shape: ButtonShape,
  width?: string,
  height?: string,
  padding?: string,
  color?: string,
  fontSize?: string,
  fontWeight?: number,
  borderColor?: string,
  backgroundColor?: string,
  fullWidth?: boolean,
  disabled?: boolean,
  leftIcon?: ReactElement,
  rightIcon?: ReactElement,
) => css`
  ${buttonSize(size)}
  ${butttonColor(type, backgroundColor, borderColor, color)}
  ${buttonShape(shape)}
  ${disabled &&
  css`
    background-color: #ffa94d;
    border: 1px solid #ffa94d;
    color: #fff;
    cursor: not-allowed;
    opacity: 0.8;
  `}
  width: ${fullWidth && '100%'};
  width: ${width && width};
  height: ${height && height};
  padding: ${padding && padding};
  color: ${color && color};
  font-size: ${fontSize && fontSize};
  display: flex;
  justify-content: ${leftIcon || rightIcon ? 'space-between' : 'center'};
  gap: 0 0.5rem;
  font-weight: ${fontWeight && fontWeight};
`;

const FancyButton = forwardRef(Button);
export default FancyButton;
