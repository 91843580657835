import { useCallback } from 'react';
import { isDesktop } from 'react-device-detect';

import useOpenApp from '@hook/deepLink/useOpenApp';

import getDeepLink from '@lib/utils/getDeepLink';

const useOpenAppWithWebUrl = () => {
  const openApp = useOpenApp();

  return useCallback(
    (url: string, goAppStore = true) => {
      if (isDesktop) return null;
      const deepLink = getDeepLink(url);
      if (deepLink) openApp(deepLink, goAppStore);

      return deepLink;
    },
    [openApp],
  );
};

export default useOpenAppWithWebUrl;
