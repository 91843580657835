import {
  AF_URL_SCHEME,
  VALID_AF_URL_PARTS_LENGTH,
  GCLID_EXCLUDE_PARAMS_KEYS,
  AF_CUSTOM_EXCLUDE_PARAMS_KEYS
} from './constants/smartScript';
import { getParameterValue, getURLParametersKV, stringifyParameters } from './utils/smartScript';
import { isSkippedURL, getGoogleClickIdParameters } from './services/smartScript';
import QRCode from './qr/qr';
QRCode();

(function () {
  const generateOneLinkURL = (parameters = { afParameters: {} }) => {
    const {
      oneLinkURL, //[string]
      //each inner parameter of [mediaSource,campaign,channel,ad,adSet,deepLinkValue] should accept config object:
      //{
      //  keys:[string[]],
      //  overrideValues: {key:value},
      //  defaultValue: [string]
      //}
      afParameters: {
        mediaSource,
        campaign,
        channel,
        ad,
        adSet,
        deepLinkValue,
        afSub1,
        afSub2,
        afSub3,
        afSub4,
        afSub5,
        afCustom, //array of {paramKey: [string], keys:[string[]], overrideValues: {key:value}, defaultValue: [string]}
        googleClickIdKey // [string]
      } = {},
      referrerSkipList = [], //[string[]]
      urlSkipList = [] //[string[]]
    } = parameters;

    const oneLinkURLParts = (oneLinkURL || '')?.toString().match(AF_URL_SCHEME);
    if (!oneLinkURLParts || oneLinkURLParts?.length < VALID_AF_URL_PARTS_LENGTH) {
      console.error(
        "oneLinkURL is missing or not in the correct format, can't generate URL",
        oneLinkURL
      );
      return null;
    }

    if (mediaSource?.keys?.length === 0 && !mediaSource?.defaultValue) {
      console.error(
        "mediaSource is missing (default value was not supplied), can't generate URL",
        mediaSource
      );
      return null;
    }

    if (
      isSkippedURL({
        url: document.referrer,
        skipKeys: referrerSkipList,
        errorMsg: 'Generate url is skipped. HTTP referrer contains key:'
      })
    ) {
      return null;
    }

    if (
      isSkippedURL({
        url: document.URL,
        skipKeys: urlSkipList,
        errorMsg: 'Generate url is skipped. URL contains string:'
      })
    ) {
      return null;
    }

    // af_js_web=true and af_ss_ver=[version] will be added to every URL that was generated through this script
    const afParams = { af_js_web: true, af_ss_ver: window.AF_SMART_SCRIPT.version };
    const currentURLParams = getURLParametersKV(window.location.search);

    if (mediaSource) {
      const pidValue = getParameterValue(currentURLParams, mediaSource);
      if (!pidValue) {
        console.error(
          "mediaSource was not found in the URL and default value was not supplied, can't generate URL",
          mediaSource
        );
        return null;
      }
      afParams['pid'] = pidValue;
    }

    if (campaign) {
      afParams['c'] = getParameterValue(currentURLParams, campaign);
    }

    if (channel) {
      afParams['af_channel'] = getParameterValue(currentURLParams, channel);
    }

    if (ad) {
      afParams['af_ad'] = getParameterValue(currentURLParams, ad);
    }

    if (adSet) {
      afParams['af_adset'] = getParameterValue(currentURLParams, adSet);
    }

    if (deepLinkValue) {
      afParams['deep_link_value'] = getParameterValue(currentURLParams, deepLinkValue);
    }

    const afSubs = [afSub1, afSub2, afSub3, afSub4, afSub5];
    afSubs.forEach((afSub, index) => {
      if (afSub) {
        afParams[`af_sub${index + 1}`] = getParameterValue(currentURLParams, afSub);
      }
    });

    if (googleClickIdKey) {
      if (GCLID_EXCLUDE_PARAMS_KEYS.find(k => k === googleClickIdKey)) {
        console.debug(
          "Google Click Id ParamKey can't override AF Parameters keys",
          googleClickIdKey
        );
      } else {
        const googleParameters = getGoogleClickIdParameters(googleClickIdKey, currentURLParams);
        Object.keys(googleParameters).forEach(gpk => {
          afParams[gpk] = googleParameters[gpk];
        });
      }
    }

    if (Array.isArray(afCustom)) {
      afCustom.forEach(customParam => {
        if (customParam?.paramKey) {
          const isOverrideExistingKey = AF_CUSTOM_EXCLUDE_PARAMS_KEYS.find(
            k => k === customParam?.paramKey
          );
          if (customParam?.paramKey === googleClickIdKey || isOverrideExistingKey) {
            console.debug(
              "Custom parameter ParamKey can't override Google-Click-Id or AF Parameters keys",
              customParam
            );
          } else {
            afParams[[customParam.paramKey]] = getParameterValue(currentURLParams, customParam);
          }
        }
      });
    }

    const finalParams = stringifyParameters(afParams);
    const finalURL = oneLinkURL + finalParams.replace('&', '?');
    console.debug('Generated OneLink URL', finalURL);

    window.AF_SMART_SCRIPT.displayQrCode = function (htmlId) {
      if (!finalURL) {
        console.debug('ClickURL is not valid');
        return null;
      }
      return new QRCode(document.getElementById(htmlId), {
        text: `${finalURL}&af_ss_qr=true`
      });
    };
    return { clickURL: finalURL };
  };
  window.AF_SMART_SCRIPT = { generateOneLinkURL, version: '2_1_0' };
})();
