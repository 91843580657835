import { atom, DefaultValue, selector } from 'recoil';

import { AlertDataType } from '@type/webView/alert';

const initialState: AlertDataType = {
  show: false,
  className: '',
  text: '잠시 후 다시 시도해주세요.',
  title: '',
  subText: '',
  boxDescription: '',
  notice: '',
  bottomText: '확인',
  bottomBackground: 'white',
  onClick: () => {},
  onCancel: () => {},
};

const alertAtom = atom<AlertDataType>({
  key: 'alertAtom',
  default: initialState,
});

/**
 * alert open, close 액션 생성함수
 *
 * open : const openAlert = useSetRecoilState(alertOpenSelector)
 * close : const closeAlert = useSetRecoilState(alertCloseSelector)
 */
const alertOpenSelector = selector<AlertDataType>({
  key: 'alertOpenSelector',
  get: ({ get }) => get(alertAtom),
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(alertAtom);
    } else {
      set(alertAtom, {
        ...initialState,
        ...newValue,
        show: true,
      });
    }
  },
});

const alertCloseSelector = selector({
  key: 'alertCloseSelector',
  get: () => {},
  set: ({ get, set }) => {
    set(alertAtom, {
      ...get(alertAtom),
      show: false,
    });
  },
});

export { alertAtom, alertOpenSelector, alertCloseSelector };
