const sizeType = {
  sizeFont: {
    large: '18px',
    middle: '14px',
    small: '11px',
  },
  sizeHeight: {
    large: '56px',
    middle: '30px',
    small: '20px',
  },
  sizeLayout: {
    large: '1280px',
    middle: '768px',
    small: '375px',
  },
};
export default sizeType;
