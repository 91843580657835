import React, { ReactElement, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { css } from '@emotion/react';

import usePortal from '@hook/usePortal';

import useOpenAppWithWebUrl from '@lib/hook/deepLink/useOpenAppWithWebUrl';
import { palette } from '@lib/styles/palette';
import { getToken } from '@lib/utils/auth';
import * as AppInterface from '@lib/utils/interface';
import { isAppDevice } from '@lib/utils/webview';

import AppDownloadModal from '../AppDownloadModal';

interface ReplyCreateRouteButtonPropsType {
  to: string;
}

export default function ReplyCreateRouteButton({
  to,
}: ReplyCreateRouteButtonPropsType): ReactElement {
  const openAppWithWebUrl = useOpenAppWithWebUrl();
  const [isLink, setIsLink] = useState(true);

  useEffect(() => {
    setIsLink(isAppDevice());
  }, []);

  const { PopUp, onHidePopUp, onShowPopUp } = usePortal();

  return (
    <>
      {isLink ? (
        <Link
          to={to}
          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
            if (getToken()) return;

            e.preventDefault();
            AppInterface.showLoginPopup();
          }}
        >
          답글
        </Link>
      ) : (
        <>
          <button
            css={buttonStyle}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              onShowPopUp();
            }}
          >
            답글
          </button>
          <PopUp>
            <AppDownloadModal
              onClick={() => {
                openAppWithWebUrl(window.location.href);
              }}
              onHide={onHidePopUp}
            />
          </PopUp>
        </>
      )}
    </>
  );
}

const buttonStyle = css`
  background-color: transparent;
  color: ${palette.jnGreen_text};
`;
