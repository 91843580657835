import { atom, selectorFamily } from 'recoil';

interface KeyboardVisibleAtom {
  visible: boolean;
  init: boolean;
}

export const keyboardVisibleAtom = atom<KeyboardVisibleAtom>({
  key: 'keyboardVisibleAtom',
  default: {
    visible: false,
    init: false,
  },
});

export const keyboardVisibleSelector = selectorFamily<
  KeyboardVisibleAtom[keyof KeyboardVisibleAtom],
  keyof KeyboardVisibleAtom
>({
  key: 'keyboardVisibleSelector',
  get:
    (key) =>
    ({ get }) =>
      get(keyboardVisibleAtom)[key],
  set:
    (key) =>
    ({ get, set }, newValue) => {
      set(keyboardVisibleAtom, {
        ...get(keyboardVisibleAtom),
        [key]: newValue,
      });
    },
});
