import { atom, selector } from 'recoil';

interface ToastAtom {
  message: string;
  isTriggered: boolean;
}

export const toastAtom = atom<ToastAtom>({
  key: 'toastAtom',
  default: {
    message: '',
    isTriggered: false,
  },
});

export const toastMessageSelector = selector<ToastAtom['message']>({
  key: 'toastMessageSelector',
  get: ({ get }) => get(toastAtom).message,
  set: ({ set }, newValue) => {
    set(toastAtom, {
      isTriggered: true,
      message: newValue as ToastAtom['message'],
    });
  },
});

export const toastIsTriggeredSelector = selector<ToastAtom['isTriggered']>({
  key: 'toastIsTriggeredSelector',
  get: ({ get }) => get(toastAtom).isTriggered,
  set: ({ get, set }, newValue) => {
    set(toastAtom, {
      isTriggered: newValue as ToastAtom['isTriggered'],
      message: get(toastAtom).message,
    });
  },
});
