import { useState, useEffect } from 'react';

import { useRecoilValue } from 'recoil';

import { TokenInfo } from '@type/web/token';

import { userStateInfo } from '@recoils/user/atom';

const useUserInfo = () => {
  const { userSeq, userId, nickName, profileImgUrl } =
    useRecoilValue(userStateInfo);

  const [tokenInfo, setTokenInfo] = useState({
    lastPasswordResetDate: '',
    storeSeq: 0,
    userStatus: 0,
  });

  useEffect(() => {
    const jnLoginInfo = localStorage.getItem('jn_login_info');
    if (jnLoginInfo !== null) {
      const info: TokenInfo = JSON.parse(jnLoginInfo);
      const { lastPasswordResetDate, storeSeq, userStatus } = info;
      setTokenInfo({
        lastPasswordResetDate,
        storeSeq,
        userStatus,
      });
    }
  }, [userSeq]);

  return {
    isLogin: userSeq !== 0,
    userSeq,
    userId,
    nickName,
    profileImgUrl,
    ...tokenInfo,
  };
};

export default useUserInfo;
