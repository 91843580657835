import { css } from '@emotion/react';

import { ReviewFeedbackInfoType } from '@type/api/main/orderHistory';

import BorderLine from '@components/common/BorderLine';
import Icon from '@components/common/Icon/Icon';

import { palette } from '@lib/styles/palette';

interface DetailReviewFeedbackProps {
  title: string;
  feedbackData: ReviewFeedbackInfoType[] | undefined;
}

function DetailReviewFeedback({
  title,
  feedbackData,
}: DetailReviewFeedbackProps): JSX.Element {
  const isEmptyData = feedbackData?.every(
    (feedback) => feedback.feedbackCount === 0,
  );
  const isbadFeedback = isEmptyData && title === '이런점이 아쉬워요';
  if (!feedbackData || isbadFeedback) return <></>;

  return (
    <>
      <section css={feedbackInfoStyle}>
        <header>{title}</header>

        {isEmptyData ? (
          <div css={isEmptyDataStyle}>
            <Icon icon="chatFilled" />
            <p>받은 평가가 없습니다.</p>
          </div>
        ) : (
          <ul className="feedbackList">
            {feedbackData.map((feedback) => {
              if (!feedback.feedbackCount) return null;
              return (
                <li key={feedback.tag}>
                  <p>{feedback.tag}</p>
                  <div className="feedbackCntWrap">
                    <Icon icon="userSolidGray" />
                    <p>{feedback.feedbackCount}</p>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </section>
      <BorderLine />
    </>
  );
}

export default DetailReviewFeedback;

const feedbackInfoStyle = css`
  padding: 32px 20px 24px;

  header {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    margin-bottom: 20px;
  }

  .feedbackList {
    display: flex;
    flex-direction: column;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .feedbackCntWrap {
        display: flex;
        min-width: 36px;
        max-width: 54px;

        p {
          font-weight: 500;
          color: ${palette.gray600};
          margin-left: 2px;
        }
      }
    }
  }
`;

const isEmptyDataStyle = css`
  padding: 28px 0 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: ${palette.gray700};
    margin-top: 16px;
  }
`;
