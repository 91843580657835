import getUTF8 from '@lib/utils/getUTF8';
import getQueryStringSafe from '@lib/utils/path/getQueryStringSafe';

function getQueryString(name: string, url = window.location.href) {
  try {
    const results = new RegExp(`[?&]${name}=([^&#]*)`).exec(url);
    if (results === null) {
      return '';
    }
    let result = results[1];
    if (result.indexOf('+') > 0) {
      result = decodeURI(results[1]).replace(/\+/g, ' ');
    }
    return decodeURIComponent(getUTF8(result)) || '';
  } catch (e) {
    console.log(e);
    return getQueryStringSafe(name, url);
  }
}

export default getQueryString;
