import { ReactElement, ReactNode, useEffect, useRef } from 'react';

import { css, SerializedStyles } from '@emotion/react';

import useKeyDown from '@hook/useKeyDown';

import { palette } from '@lib/styles/palette';

interface PopUpChildren {
  toggle: () => void;
  children: ReactNode;
  opacity: SerializedStyles;
  onHide: () => void;
}

function PopUp({
  toggle,
  children,
  opacity,
  onHide,
}: PopUpChildren): ReactElement {
  const popRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const popupElement = popRef.current;
    if (popupElement) {
      popupElement.lastElementChild?.addEventListener('click', () => {
        toggle();
      });
    }
  }, [popRef]);

  /** ESC 눌렀을 경우 팝업 닫기 */
  useKeyDown('Escape', onHide);

  return (
    <div css={popUp} ref={popRef}>
      <div>{children}</div>
      <div css={[dim, opacity]} onClick={onHide} />
    </div>
  );
}

const popUp = css`
  display: flex;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  z-index: 500;
  bottom: 0;
`;

const dim = css`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: ${palette.black10};
  opacity: 0.9;
`;

export default PopUp;
