import axios, { AxiosInstance } from 'axios';

import { ContentTypeKeys, ApiHeaders, ApiResponse } from '@type/web/api';

import {
  API_BASE_URL,
  MAIN_API_BASE_URL,
  BOOT_API_URL,
  EDGE_API_URL,
  SEARCH_API_URL,
  WIKI_SEARCH_API_URL,
  STANDARD_PRICE_API_URL,
  POINT_API_URL,
  PRODUCT_URL,
  ORDER_API_URL,
  DELIVERY_API_URL,
  CSA_API_URL,
  TERMS_URL,
} from '@lib/constants/const';
import { getToken } from '@lib/utils/auth';
import {
  getAppDataByStorage,
  getOsType,
  isAppDevice,
} from '@lib/utils/webview';

export const ContentType = {
  JSON_TYPE: 'application/json',
  FORM_TYPE: 'application/x-www-form-urlencoded',
  MULTIPART_TYPE: 'multipart/form-data',
};

export function getHeaders(type = 'JSON_TYPE'): ApiHeaders {
  let contentType = ContentType.JSON_TYPE;
  if (type === 'FORM') contentType = ContentType.FORM_TYPE;
  if (type === 'MULTIPART') contentType = ContentType.MULTIPART_TYPE;

  const token = getToken();

  const userAgentData = getAppDataByStorage(true) || {};
  // 웹뷰일 경우 uuid 값 세팅. userAgentData.uuid = undifined || '' || string
  const uuid =
    userAgentData.uuid && isAppDevice()
      ? { device_uuid: userAgentData.uuid }
      : {};

  const osType = getOsType();

  const headers = {
    Authorization: token ? `Bearer ${token}` : '',
    'Content-Type': contentType,
    'Os-Type': osType,
    'App-Version': userAgentData['App-Version'] || null,
    ...uuid,
  };

  return headers;
}

export const createAxiosInstance = (
  baseURL: string,
  headerType: ContentTypeKeys = 'JSON_TYPE',
): AxiosInstance => {
  const instance = axios.create({
    baseURL,
    headers: getHeaders(headerType),
  });

  if (baseURL.includes('/static/terms')) {
    instance.interceptors.request.use(async (request) => {
      request.headers.Authorization = '';
      return request;
    });
  }

  return instance;
};
export const createMediaAxiosInstance = (baseURL: string): AxiosInstance => {
  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'image/*',
    },
  });
};

const baseAxios = createAxiosInstance(`${API_BASE_URL}`);
const mainBaseAxios = createAxiosInstance(`${MAIN_API_BASE_URL}`);
const bootAxios = createAxiosInstance(`${BOOT_API_URL}`);
const edgeAxios = createAxiosInstance(`${EDGE_API_URL}`);
const pointEventAxios = createAxiosInstance(`${POINT_API_URL}`);
const pointAxios = createAxiosInstance(`${POINT_API_URL}`);
const searchAxios = createAxiosInstance(`${SEARCH_API_URL}`);
const standardAxios = createAxiosInstance(`${STANDARD_PRICE_API_URL}`);
const wikiSearchAxios = createAxiosInstance(`${WIKI_SEARCH_API_URL}`);
const productAxios = createAxiosInstance(`${PRODUCT_URL}`);
const orderAxios = createAxiosInstance(`${ORDER_API_URL}`);
const deliveryAxios = createAxiosInstance(`${DELIVERY_API_URL}`);
const csaAxios = createAxiosInstance(`${CSA_API_URL}`);
const termsAxios = createAxiosInstance(`${TERMS_URL}`);

export const AxiosDomain = {
  base: baseAxios,
  mainBase: mainBaseAxios,
  boot: bootAxios,
  edge: edgeAxios,
  pointEvent: pointEventAxios,
  point: pointAxios,
  search: searchAxios,
  standard: standardAxios,
  wikiSearch: wikiSearchAxios,
  product: productAxios,
  order: orderAxios,
  delivery: deliveryAxios,
  csa: csaAxios,
  terms: termsAxios,
};

type Method = 'get' | 'post' | 'put' | 'delete';
export const axiosRequest = async <T>(
  axiosInstance: AxiosInstance,
  method: Method = 'get',
  url: string,
  data?: any,
  headers?: any,
  params?: any,
  validStatusList: number[] = [],
): Promise<ApiResponse<T>> => {
  const instance = await axiosInstance.request<ApiResponse<T>>({
    method,
    url,
    ...(data && { data }),
    ...{
      headers: {
        ...getHeaders(),
        ...headers,
      },
    },
    ...(params && { params }),
    /* https://axios-http.com/kr/docs/req_config
     200 번대가 아닌 다른 HTTP 응답 상태에서도 응답으로 받은 값(data, message 등)을 사용해야 하는 경우
     Promise 가 거부되지 않고 이행되어야 응답을 꺼내서 사용할 수 있습니다.
     props인 validStatusList에 허락할 HTTP 응답 상태를 담아 전달하면 됩니다.
    */
    validateStatus: (status) => {
      return (
        (status >= 200 && status < 300) ||
        !!validStatusList.find((validStatus) => validStatus === status)
      );
    },
  });

  return instance.data;
};
