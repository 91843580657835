import { useMediaQuery } from 'react-responsive';

export interface ResponsiveType {
  isMobile: boolean;
  isMobileUp: boolean;
  isTablet: boolean;
  isPC: boolean;
}

const useResponsive = (): ResponsiveType => {
  const isMobile = useMediaQuery({
    query: '(max-width: 599px)',
  });
  const isMobileUp = useMediaQuery({
    query: '(min-width: 600px)',
  });
  const isTablet = useMediaQuery({
    query: '(min-width: 600px) and (max-width: 1023px)',
  });
  const isPC = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  return { isMobile, isMobileUp, isTablet, isPC };
};

export default useResponsive;
