import { useQuery } from 'react-query';

import { css } from '@emotion/react';

import useHistoryHook from '@hook/useHistory';

import { ProductReviewContentType } from '@type/api/main/orderHistory';

import { getUserStatusInfo } from '@api/base/user';

import Image from '@components/common/Image';

import { REVIEWER_TYPE } from '@lib/constants/orderPaymentDelivery';
import { palette } from '@lib/styles/palette';
import { getToken } from '@lib/utils/auth';
import { getImgSrc, htmlStringToReactElement } from '@lib/utils/parser';
import { openMainWebPage } from '@lib/utils/webview';

interface DetailReviewItemProps {
  reviewData: ProductReviewContentType | undefined;
}

function DetailReviewItem({ reviewData }: DetailReviewItemProps): JSX.Element {
  const { push, params } = useHistoryHook();
  const { storeSeq } = params as { storeSeq: string };

  const isLogin = !!getToken();
  const { data: userData } = useQuery(['user-simple-data'], getUserStatusInfo, {
    enabled: !!isLogin,
  });
  const isMyStore = userData && userData?.data.userSeq.toString() === storeSeq;

  if (!reviewData) return <></>;

  const {
    productReviewContent,
    reviewer,
    imageUrl,
    orderNo,
    reviewerStoreSeq,
    regDate,
    nickName,
  } = reviewData;
  const reviewTypeKor = reviewer === REVIEWER_TYPE.seller ? '판매자' : '구매자';
  const formatDate = regDate.split(' ');

  return (
    <li css={reviewItemStyle}>
      <button
        className="userInfoWrap"
        onClick={() => openMainWebPage(`/store/${reviewerStoreSeq}`)}
      >
        <div className="profileImgWrap">
          <Image src={getImgSrc(imageUrl)} alt={`${orderNo}_${nickName}`} />
        </div>
        <div>
          <p>{nickName}</p>
          <span className="infoContent">{reviewTypeKor}</span>
          <span>{formatDate[0]}</span>
        </div>
      </button>
      <button
        disabled={!isLogin}
        css={reviewItemWrapStyle}
        onClick={() => {
          push(
            `/review/detail/${orderNo}?reviewFrom=${
              reviewer === REVIEWER_TYPE.seller ? 'sell' : 'buy'
            }&reviewType=${isMyStore ? 'receive' : 'sent'}`,
          );
        }}
      >
        <p>{htmlStringToReactElement(productReviewContent)}</p>
      </button>
    </li>
  );
}

export default DetailReviewItem;

const reviewItemStyle = css`
  :not(:last-of-type) {
    margin-bottom: 24px;
  }
  .userInfoWrap {
    display: flex;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: ${palette.gray900};

    p {
      text-align: left;
    }

    span {
      font-weight: 400;
      color: ${palette.gray500};
    }

    .infoContent::after {
      content: '';
      display: inline-block;
      border: 1px solid ${palette.gray300};
      width: 1px;
      height: 10px;
      margin: 0 8px;
    }
  }

  .profileImgWrap {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    margin-right: 12px;

    > div {
      border-radius: 100px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
`;

const reviewItemWrapStyle = css`
  width: 100%;
  padding: 10px 12px;
  background-color: ${palette.gray200};
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${palette.gray900};
  text-align: left;
  word-break: break-all;
  cursor: pointer;

  :disabled {
    cursor: default;
  }
`;
