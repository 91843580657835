import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import qs from 'qs';

interface StateType {
  from?: string;
  seq: number;
  url: string;
}
const useHistoryHook = () => {
  const history = useHistory();
  const location = useLocation<StateType>();
  const params = useParams<any>();
  const routeMatch = useRouteMatch();

  const push = (path = '/', state = {}) => {
    history.push(path, {
      from: `${location.pathname}${location.search}`,
      ...state,
    });
  };

  const replace = (path = '/', state = {}) => {
    history.replace(path, {
      from: `${location.pathname}${location.search}`,
      ...state,
    });
  };

  const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });

  const updateQueryString = (addQueryString: Record<string, string>) => {
    const newQueryString = {
      ...queryString,
      ...addQueryString,
    };
    const parameter = qs.stringify(newQueryString, { encodeValuesOnly: true });

    return parameter;
  };

  const pushQueryString = (
    addQueryString: Record<string, string>,
    pathname?: string,
  ) => {
    const parameter = updateQueryString(addQueryString);

    push(`${pathname || location.pathname}?${parameter}`);
  };

  return {
    history,
    location,
    params,
    routeMatch,
    push,
    replace,
    queryString,
    updateQueryString,
    pushQueryString,
  };
};

export default useHistoryHook;
