import LinkInfoType from '@type/web/LinkInfoType';

import { SHARE_URL } from '@lib/constants/const';

// 메인웹에서 개발완료된 페이지들의 딥링크 map
const linkInfos: LinkInfoType[] = [
  {
    web: '/product/detail/naver/:articleSeq',
    app: '://?cafeproductseq={articleSeq}',
  },
  {
    web: '/product/detail/:productSeq',
    app: `://?productseq={productSeq}`,
  },
  {
    web: '/search/product',
    app: `://?searchword={searchword}&searchtab=app`,
  },
  {
    web: '/wiki',
    app: `://?openweb=${SHARE_URL}/wiki&nofooter=Y`,
  },
  {
    web: '/cs/qna',
    app: '://?applink=qaseq&qnaCategorySeq={qnaCategorySeq}',
  },
  /** TODO 이벤트, 컨텐츠, 출석이벤트는 아직 모웹 앱링크 -> 앱링크 생성 완료되면 수정필요 */
  {
    web: '/event/detail/:eventSeq',
    app: '://?eventseq={eventSeq}',
  },
  {
    web: '/content/detail/:contentSeq',
    app: '://?contentseq={contentSeq}',
  },
  {
    web: '/point-event/:date',
    app: '://?pointevent=2&date={date}',
  },
  // {
  //   web: '/store/',
  //   app: `://?storeseq=`,
  // },
  // {
  //   web: '/cs/qna-detail/',
  //   app: `://?qaseq=`,
  // },
  // {
  //   web: '/cs/qna-reinquiry/suggest',
  //   app: `://?applink=opinion`,
  // },
  // {
  //   web: '/cs/qna',
  //   app: `://?applink=qaseq`,
  // },
  // {
  //   web: '/member/dispute',
  //   app: `://?applink=qaseq`,
  // },
  // {
  //   web: '/search-category',
  //   app: `://?applink=categorysearch`,
  // },
  // {
  //   web: '/cs/notice/service',
  //   app: `://?applink=notice`,
  // },
  // {
  //   web: '/cs/info/web',
  //   app: `://?applink=infouse`,
  // },
  // {
  //   web: '/mystore',
  //   app: `://?applink=mystore`,
  // },
  // {
  //   web: '/event-detail/',
  //   app: `://?eventseq=`,
  // },
  // {
  //   web: '/privacy-policy',
  //   app: `://?applink=terms`,
  // },
  // {
  //   web: '/terms',
  //   app: `://?applink=agreeguide`,
  // },
  // {
  //   web: '/point-event/',
  //   app: `://?pointevent=`,
  // },
  // {
  //   web: '/allproduct-list/',
  //   app: `://?saleproductList=`,
  // },
  // {
  //   web: '/peace',
  //   app: `://?applink=peaceTab`,
  // },
  // {
  //   web: '/location',
  //   app: `://?applink=ourhometab`,
  // },
  // {
  //   web: '/mytag',
  //   app: `://?applink=mytagtab`,
  // },
  // {
  //   web: '/auto/product-regist',
  //   app: `://?applink=autoregist`,
  // },
  // {
  //   web: '/product-regist',
  //   app: `://?applink=productreg`,
  // },
  // {
  //   web: '/auto/product-detail/',
  //   app: `://?autoproductseq=`,
  // },
  // {
  //   web: '/auto/product-edit/',
  //   app: `://?autoedit=`,
  // },
  // {
  //   web: '/auto/garage',
  //   app: `://?applink=garagelist`,
  // },
  // {
  //   web: '/mypage',
  //   app: `://?applink=sellerrevenue`,
  // },
  // {
  //   web: '/auto/repairShop/',
  //   app: `://?repairShop=`,
  // },
  // {
  //   web: '/plan/',
  //   app: `://?plan=`,
  // },
  // {
  //   web: '/order-history/list/sell',
  //   app: `://?applink=salelist`,
  // },
  // {
  //   web: '/order-history/detail/sell?no=',
  //   app: `://?saleseq=`,
  // },
  // {
  //   web: '/order-history/list/buy',
  //   app: `://?applink=buylist`,
  // },
  // {
  //   web: '/order-history/detail/buy?no=',
  //   app: `://?buyseq=`,
  // },
  // {
  //   web: '/notify',
  //   app: `://?applink=alarm`,
  // },
  // {
  //   web: '/maintab/town',
  //   app: `://?applink=ourhometab`,
  // },
  // {
  //   web: '/maintab/ticket',
  //   app: `://?applink=ticket`,
  // },
  // {
  //   web: '/search-category',
  //   app: `://?applink=categorySearch`,
  // },
  // {
  //   web: '/category-seller',
  //   app: `://?applink=searchmain`,
  // },
  // {
  //   web: '/search-list',
  //   app: `://?`,
  // },
  // {
  //   web: '/search/price',
  //   app: `://?searchPriceWord=`,
  // },
  // default 딥링크
  {
    web: '/',
    app: `://?applink=main`,
  },
];

export default linkInfos;
