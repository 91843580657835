import { map, keys } from 'lodash';

type QueryObjectType = {
  [index: string]: string;
};

// json 형태로 입력 받은 데이터로 현재 쿼리스트링에 이미 값이 있으면 대체, 값이 없다면 추가, 값이 null or undefined일 경우 삭제
const replaceQueryString = (inputQuery = {}, url = window.location.href) => {
  const oldQueryString = url.indexOf('?') > -1 ? url.split('?')[1] : '';
  if (inputQuery !== null && typeof inputQuery === 'object') {
    let newQueryObj: QueryObjectType = {};

    // old path string to new path obj
    if (oldQueryString !== '') {
      const queryList = oldQueryString.split('&');
      map(queryList, (query) => {
        const splitQuery = query.split('=');
        const qKey = splitQuery[0];
        const qValue = splitQuery[1];
        newQueryObj[qKey] = qValue;
      });
    }

    // change or insert inputed path to new obj
    newQueryObj = { ...newQueryObj, ...inputQuery };

    // generate new path string
    let newQueryString = '';
    const newKeys = keys(newQueryObj);
    map(newKeys, (key) => {
      if (
        newQueryObj[key] !== undefined &&
        newQueryObj[key] !== null &&
        newQueryObj[key] !== ''
      ) {
        if (newQueryString === '')
          newQueryString = `?${key}=${newQueryObj[key]}`;
        else newQueryString = `${newQueryString}&${key}=${newQueryObj[key]}`;
      }
    });
    return newQueryString;
  }
  return `?${oldQueryString}`;
};

export default replaceQueryString;
