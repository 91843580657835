import { useCallback } from 'react';
import {
  isDesktop,
  isAndroid,
  isIOS,
  isSafari,
  isChrome,
} from 'react-device-detect';
import { useHistory } from 'react-router-dom';

import {
  APP_SCHEMA,
  APP_PACKAGE,
  DOMAIN,
  ITUNES,
  PLAYSTORE,
} from '@lib/constants/const';

function useOpenApp() {
  const history = useHistory();

  return useCallback(
    (deepLink: string, goAppStore = true) => {
      if (isDesktop) return;

      // TODO 메인웹이 완전히 개발 완료 될때까지 임시로, 'goAppStore'인자를 사용하여, 앱 미설치시 앱 스토어로 이동하는 로직을 optional로 변경
      if (isIOS && isSafari) {
        if (window.location.href === deepLink) window.location.replace(ITUNES);
        else history.push(deepLink.replace(DOMAIN || '', ''));
      } else if (isAndroid && isChrome) {
        const intentLinkArr = [
          `intent${deepLink.replace(APP_SCHEMA || '', '')}#Intent`,
          `scheme=${APP_SCHEMA}`,
          `package=${APP_PACKAGE}`,
          `S.browser_fallback_url=${PLAYSTORE}`,
          'end',
        ];

        // 앱스토어에 가도록 해야될때만, 앱 미설치시 앱스토어로 유도
        if (!goAppStore) intentLinkArr.splice(3, 1);
        const indentLink = intentLinkArr.join(';');
        window.location.href = indentLink;
      } else {
        // 기타 환경; 트위터/카카오톡 인앱 브라우저 등등
        let timer: ReturnType<typeof setTimeout>;
        let goAppInterval: ReturnType<typeof setInterval>;

        window.location.href = `${APP_SCHEMA}${deepLink}`;

        const clearTimer = () => {
          clearInterval(goAppInterval);
          clearTimeout(timer);
        };

        const isActiveBrowser = () => {
          // 앱 설치 되어있음
          if (document.hidden) {
            clearTimer();
          }
        };

        // 앱스토어에 가도록 해야될때만, 앱 미설치시 앱스토어로 유도
        if (goAppStore) {
          goAppInterval = setInterval(isActiveBrowser, 500);

          timer = setTimeout(() => {
            // 앱 미설치로 봄
            clearInterval(goAppInterval);
            if (window.confirm('앱스토어로 이동하시겠습니까?')) {
              window.location.href = isAndroid ? PLAYSTORE : ITUNES;
            }
          }, 5000);
        }
      }
    },
    [history],
  );
}

export default useOpenApp;
