import { AnyObject } from 'immer/dist/internal';

export const DEFAULT_SEARCH_QUANTITY = 20;

export enum HttpState {
  INVALID_EVENT = 400401, // 400401 : 올바르지 않거나 현재 닫혀있는 이벤트
  INVALID_USER = 400402, // 400402 : 올바르지 않는 회원이 신청
  ALREADY = 400403, // 400403 : 이미 참여한 이벤트
  NOT_TARGET = 400407, // 400407 : 기결제 이벤트 대상자가 아님
  NOT_NEW_REGIST = 400408, // 400408: 신규 가입 회원 대상
  SUCCESS = 0,
}

// 0:댓글참여형, 1:응모형, 2:바로당첨형, 3:선착순형, 4: 경매)
export enum EventType {
  COMMENT = 0,
  APPLY,
  WINNING,
  FCFS,
  AUCTION,
}

// 이벤트 상태 (0:예약중, 1:진행중, 2:이벤트 종료, 3:추출완료, 4:발표완료)
export enum EventStatusType {
  RESERVED,
  ING,
  END,
  PICKED,
  ANNOUNCEMENT,
}

export enum SearchType {
  ING = 'ING',
  END = 'END',
  WINNERS = 'WINNERS',
}

export enum OSType {
  AOS,
  IOS,
  WEB,
}

export enum DeviceType {
  APP = 1,
  WEB,
}

export interface EventListQueries {
  osType?: OSType;
  deviceType?: DeviceType;
  searchType: SearchType;
  eventType: SearchType;
  startIndex?: number;
  searchQuantity?: number;
}

export interface EventDetailQueries {
  startIndex?: number;
  searchQuantity?: number;
  eventSeq: number;
}

export interface EventListDataType {
  eventSeq: number;
  eventTitle: string;
  exposeStartDate: string;
  exposeEndDate: string;
  eventType: EventType;
  eventStatus: EventStatusType;
  bannerImgUrl: string;
  commentCount: number;
  commentYn: number | boolean;
  remainDay: number;
}

export interface EventDetailType {
  eventSeq: number;
  eventTitle: string;
  exposeStartDate: string;
  exposeEndDate: string;
  eventType: EventType;
  eventStatus: EventStatusType;
  bannerImgUrl: string;
  buttonImgUrl: string;
  eventContent: string; // html
  metaTag: string;
  commentYn: number | boolean;
  eventJoinType: number;
  eventExceptDuplicateYn: number | boolean;
  eventWinCompleteYn: boolean;
  eventWinCompleteMessage: string;
  eventWinImgUrl: string;
  eventWinMessage: string;
  announceDate: string | null;
  eventUseYn: true | boolean;
  auction: null | AnyObject;
  auctionEvent: false;
}

/** 이벤트 응모 */

// 0: 당첨, 1: 응모완료, 2: 당첨안됨
export enum GiftResultType {
  WIN = 0,
  APPLY,
  LOSE,
}

export interface EventGiftV0Type {
  eventgiftSeq: number;
  eventSeq: number;
  eventGiftSort: number;
  eventGiftType: number; // 0 경품, 1 포인트
  eventGiftName: string;
  eventGiftPoint: number;
  remain: number;
  eventWinnerNo: number;
  winnerMessage: string;
  winnerImage: string;
}

// 응모 이벤트
export interface EventJoinType {
  giftResult: GiftResultType;
  eventGiftVO: EventGiftV0Type | null;
}
