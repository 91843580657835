import { AdsResponseConstType } from '@type/api/edge/ads';
import { CommentTabArr } from '@type/webView/wikiComment';
import { WikiIconType } from '@type/webView/wikiMain';

import { palette } from '@lib/styles/palette';

/** 앱 패키지 이름 */
export const APP_PACKAGE = process.env.REACT_APP_APP_PACKAGE;

/** 앱 스키마 이름 */
export const APP_SCHEMA = process.env.REACT_APP_APP_SCHEMA;

/** 개발환경 로그인 허용 */
export const APP_USE_LOGIN_TEST = process.env.REACT_APP_USE_LOGIN_TEST;

/** 도메인 정보 */
export const DOMAIN = process.env.REACT_APP_DEFAULT_URL;

/** API DOMAIN URL */
export const {
  REACT_APP_WIKI_SEARCH_API_URL: WIKI_SEARCH_API_URL,
  REACT_APP_BOOT_API_URL: BOOT_API_URL,
  REACT_APP_EDGE_API_URL: EDGE_API_URL,
  REACT_APP_API_BASE_URL: API_BASE_URL,
  REACT_APP_MAIN_API_BASE_URL: MAIN_API_BASE_URL,
  REACT_APP_AUTO_API_URL: AUTO_API_URL,
  REACT_APP_POINT_API_URL: POINT_API_URL,
  REACT_APP_SEARCH_API_URL: SEARCH_API_URL,
  REACT_APP_STANDARD_PRICE_API_URL: STANDARD_PRICE_API_URL,
  REACT_APP_MARKETING_API_URL: MARKETING_API_URL,
  REACT_APP_TICKETS_API_URL: TICKETS_API_URL,
  REACT_APP_ORDER_API_URL: ORDER_API_URL,
  REACT_APP_SHARE_URL: SHARE_URL,
  REACT_APP_SELLER_URL: SELLER_URL,
  REACT_APP_SELLER_ADMIN_URL: SELLER_ADMIN_URL,
  REACT_APP_IMG_URL: IMG_URL,
  REACT_APP_DELIVERY_API_URL: DELIVERY_API_URL,
  REACT_APP_IMG_WIKI_URL: WIKI_IMG_URL,
  REACT_APP_IMG_JCOUPON_URL: JCOUPON_IMG_URL,
  REACT_APP_DEFAULT_URL: DEFAULT_URL,
  REACT_APP_DEFAULT_MWEB_URL: MWEB_URL,
  REACT_APP_PRODUCT_API_URL: PRODUCT_URL,
  REACT_APP_CSA_API_URL: CSA_API_URL,
  REACT_APP_TERMS_URL: TERMS_URL,
} = process.env;

/** API KEYS */
export const {
  REACT_APP_KAKAO_API: KAKAO_API_KEY,
  REACT_APP_FRAUD_API_KEY: FRAUD_API_KEY,
} = process.env;

/** ENV */
export const { REACT_APP_ENV: ENV } = process.env;

/** 메인 메뉴 목록 */
export const mainMenuList = [
  {
    url: '/mobile',
    text: '중고폰',
  },
  {
    url: '/auto',
    text: '중고차',
  },
  {
    url: '/bike',
    text: '바이크',
  },
  {
    url: '/search-price',
    text: '시세조회',
  },
  {
    url: '/event-list',
    text: '이벤트/콘텐츠',
  },
];

export const deepLinks = [
  {
    deepLink: 'jnapps3://?openweb=',
    webLink: '',
  },
  {
    deepLink: 'jnapps3://?openbrowser=',
    webLink: '',
  },
  {
    deepLink: 'jnapps3://?storeseq=',
    webLink: '/store/',
  },
];

/** 광고 api response key */
export const adsResponseConst: AdsResponseConstType[] = [
  {
    type: 'coupang',
    responseKey: 'coupangAdsResult',
  },
  {
    type: 'naverPowerShopping',
    responseKey: 'naverShoppingAdsResult',
  },
  {
    type: 'naverPowerLink',
    responseKey: 'naverPowerLinkResult',
  },
];

export const OS_TYPE = { APP: 1, WEB: 2 };
export const PLATFORM_TYPE = { APP: 1, AUTO: 2, PEACE_MARKET: 3, CAFE: 4 };

export const ITUNES = 'https://itunes.apple.com/app/id896515652';
export const PLAYSTORE =
  'https://play.google.com/store/apps/details?id=com.elz.secondhandstore&hl=ko';

export const MEDIA_TYPE = {
  IMAGE: 0,
  VIDEO: 1,
} as const;

/** 상품 상태, 0:판매중 / 1:예약중 / 2:판매보류 / 3:판매완료 / 4:상품삭제 / 5:관리자숨김 / 6:신고숨김 */
export const PRODUCT_STATUS = {
  ON_SALE: 0,
  RESERVATION: 1,
  HOLDING: 2,
  SOLD_OUT: 3,
  DELETE: 4,
  HIDE_ADMIN: 5,
  HIDE_REPORT: 6,
};

export const PRODUCT_STATUS_KOR = [
  '판매중', // 0: ON_SALE
  '예약중', // 1: RESERVATION
  '판매보류', // 2: HOLDING
  '판매완료', // 3: SOLD_OUT
  '상품삭제', // 4: DELETE
  '관리자숨김', // 5: HIDE_ADMIN
  '신고숨김', // 6: HIDE_REPORT
];

const MAX_PRICE = 200 * 1000 * 10000;

interface PriceRange {
  key: number;
  text: string;
  value: [number, number];
}

export const priceRange: PriceRange[] = [
  {
    key: 0,
    text: '전체가격',
    value: [0, MAX_PRICE],
  },
  {
    key: 1,
    text: '10만원 이하',
    value: [0, 10 * 10000],
  },
  {
    key: 2,
    text: '10만원 - 30만원 이하',
    value: [10 * 10000, 30 * 10000],
  },
  {
    key: 3,
    text: '30만원 - 50만원 이하',
    value: [30 * 10000, 50 * 10000],
  },
  {
    key: 4,
    text: '50만원 이상',
    value: [50 * 10000, MAX_PRICE],
  },
  {
    key: 5,
    text: '직접입력',
    value: [0, 0],
  },
];

// Wiki
/* Wiki 문서상세 - 글자수 줄임 기준 */
export const WIKI_STRING_LIMIT = {
  STRING_DESC_CNT: 150,
  STRING_TITLE_CNT: 20,
  STRING_DESC_SUMMARY_CNT: 47,
  STRING_COMMENT_CNT: 120,
};

export const WIKI_MAIN_HEADER_HEIGHT = 50;
export const WIKI_COMMENT_SINGLE_LINE_HEIGHT = 22;
export const WIKI_COMMENT_SINGLE_LINE_RADIUS = '100px';
export const WIKI_COMMENT_MULTIPLE_ROW_RADIUS = '11px';
export const WIKI_COMMENT_STRING_LIMIT = 1000;
export const WIKI_COMMENT_IMG_LIMIT = 1024 * 1024 * 5; // 5MB

export const MAX_RECENT_SEARCHES = 10;

/* Wiki 문서정보 - 댓글 type & type별 색상 */
export const WIKI_COMMENT_TYPE_COLOR = {
  NORMAL: palette.wikiGray3,
  TIP: palette.red900,
  ASK: palette.yellow8,
  REPLY: '',
};

export const WIKI_COMMENT_TYPE = {
  NORMAL: '일반',
  TIP: '팁',
  ASK: '문의',
  REPLY: '',
};

export const WIKI_COMMENT_TYPECODE = {
  일반: 'NORMAL',
  팁: 'TIP',
  문의: 'ASK',
};

export const WIKI_COMMENT_TYPE_TXT = {
  NORMAL: '일반',
  TIP: '팁',
  ASK: '문의',
  ALL: '전체',
};

export const wikiCommentTabArr = [
  { tabType: 'ALL', title: '전체' },
  { tabType: 'NORMAL', title: '일반' },
  { tabType: 'TIP', title: '팁' },
  { tabType: 'ASK', title: '문의' },
] as CommentTabArr[];

export const WIKI_COMMENT_HIERARCHICAL = {
  INFO: '정보',
  COMMENT: '댓글',
};

export const WIKI_CATEGORY: WikiIconType = {
  3: 'wikiMenuLuxury',
  4: 'wikiMenuClothes',
  5: 'wikiMenuFashionItem',
  6: 'wikiMenuBeauty',
  7: 'wikiMenuInfant',
  8: 'wikiMenuCellular',
  9: 'wikiMenuHomeAppliance',
  10: 'wikiMenuDesktop',
  11: 'wikiMenuCamera',
  12: 'wikiMenuInterior',
  13: 'wikiMenuLiving',
  14: 'wikiMenuGame',
  15: 'wikiMenuPetHobby',
  16: 'wikiMenuStationary',
  17: 'wikiMenuTicket',
  18: 'wikiMenuSport',
  19: 'wikiMenuTravel',
  20: 'wikiMenuUsedCar',
  21: 'wikiMenuBike',
  22: 'wikiMenuTool',
  23: 'wikiMenuNFT',
} as const;

export const DEFAULT_SHARE_IMAGE =
  'https://img2.joongna.com/mystore/share/img_jn_url_share%401x.png';

export const WIKI_SEARCH_RESULT_TYPE = {
  EMPTY_RESULT: 0,
  RESTRICT_DEAL: 1,
};

// 배송 관련
export const ONE_DAY = 1000 * 60 * 60 * 24;
export const ONE_HOUR = 1000 * 60 * 60;

export const USER_STATUS_TYPE = {
  NORMAL: 0, // 정상
  WITHDRAW: 1, // 회원탈퇴
  BLACK: 2, // 블랙
  STOP: 3, // 정지
  ADMIN_DELETE: 4, // 관리자삭제
  PHONE_EXPIRATION: 5, // 전화번호만료
  INACTIVITY: 6, // 휴면
  WITHDRAWAL_REQUEST: 7, // 회원탈퇴신청
  FORCE_DELETE: 10, // 중복삭제
} as const;

// 시세조회 api 분기위한 타입
export const actionDetailType = {
  MARKET_PRICE_PRODUCT_DETAIL: {
    text: 'MARKET_PRICE_PRODUCT_DETAIL',
    api: 'PRODUCT',
  },
  MARKET_PRICE_PRODUCT_REGISTER: {
    text: 'MARKET_PRICE_PRODUCT_REGISTER',
    api: 'PRODUCT',
  },
  MARKET_PRICE_MAIN: { text: 'MARKET_PRICE_MAIN', api: 'MAIN' },
  MARKET_PRICE_CAFE: { text: 'MARKET_PRICE_CAFE', api: 'MAIN' },
  MARKET_PRICE_DETAIL_VIEW: { text: 'MARKET_PRICE_DETAIL_VIEW', api: 'MAIN' },
  MARKET_PRICE_SEARCH: { text: 'MARKET_PRICE_SEARCH', api: 'MAIN' },
};

export const searchPriceKeywordSource = {
  LATEST_KEYWORD: 'LATEST_KEYWORD', // 최근검색어
  SUGGESTED_KEYWORD: 'SUGGESTED_KEYWORD', // 자동완성 키워드
  INPUT_KEYWORD: 'INPUT_KEYWORD', // 검색어 직접 입력
  RELATED_KEYWORD: 'RELATED_KEYWORD', // 연관 키워드
  POPULAR_KEYWORD: 'POPULAR_KEYWORD', // 인기 키워드
} as const;
