import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { RecoilRoot } from 'recoil';

import worker from '@mocks/browser';

import GlobalContainer from './GlobalContainer';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

if (process.env.REACT_APP_ENV === 'localhost') {
  worker.start();
}

ReactDOM.render(
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <GlobalContainer />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </RecoilRoot>,
  document.getElementById('root'),
);
