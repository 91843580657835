import { useEffect } from 'react';

import { useSetRecoilState } from 'recoil';

import { keyboardVisibleSelector } from '@recoils/keyboradVisible/atom';

import { interfaceGetVirtualKeyboardStatus } from '@lib/utils/interface';

interface VirtualKeyboardEvent {
  visible: 'true' | 'false';
}

/**
 * 키보드 활성화/비활성화 event init hook.
 */
export default function useKeyboardVisibleEventInit(): void {
  const setInit = useSetRecoilState(keyboardVisibleSelector('init'));
  const setVisible = useSetRecoilState(keyboardVisibleSelector('visible'));

  useEffect(() => {
    const onVisibleKeyboard = () => {
      function getVirtualKeyboardStatus(data: VirtualKeyboardEvent) {
        setVisible(JSON.parse(data.visible));
        window.virtualKeyboardEventListner = undefined;
      }
      window.virtualKeyboardEventListner = getVirtualKeyboardStatus;

      interfaceGetVirtualKeyboardStatus();
    };

    setInit((prev) => {
      if (!prev) {
        window.addEventListener('onVirtualKeyboardEvent', onVisibleKeyboard);
      }
      return true;
    });

    return () => {
      window.removeEventListener('onVirtualKeyboardEvent', onVisibleKeyboard);
    };
  }, []);
}
