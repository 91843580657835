import { ReactElement } from 'react';

import { css } from '@emotion/react';

import { palette } from '@lib/styles/palette';

interface BorderLinePropsType {
  isSolid?: boolean;
}

function BorderLine({ isSolid }: BorderLinePropsType): ReactElement {
  if (isSolid) {
    return <div css={solidBorderLine} />;
  }
  return <div css={borderLine} />;
}

const borderLine = css`
  width: 100%;
  height: 6px;
  background-color: ${palette.gray200};
`;

const solidBorderLine = css`
  width: calc(100% - 40px);
  margin: 0 auto;
  height: 1px;
  background-color: ${palette.gray300};
`;

export default BorderLine;
