import { DOMAIN } from '@lib/constants/const';

import getLinkInfo from './getLinkInfo';

type ParamsType = { [key: string]: string };

// linkinfos에 기반하여, params를 추출하기때문에, 내부 링크만 가능함
const getParams = (url: string): ParamsType => {
  const params: ParamsType = {};
  // 외부링크인 경우 return {}
  if (DOMAIN && !url.startsWith(DOMAIN)) return params;

  const paramsPart = url?.split('?')?.[0];

  const paramNames: string[] = [];
  const routerPath = paramsPart.replace(DOMAIN || '', '');
  const routerInfo = getLinkInfo({ web: routerPath })?.web;
  let pathWithoutParams = '';

  if (routerInfo) {
    const pathArr = routerInfo.split('/').slice(1);
    pathArr.forEach((v) => {
      if (v?.startsWith(':')) {
        const paramName = v?.replace(':', '');
        paramNames.push(paramName);
      } else pathWithoutParams += `/${v}`;
    });
    const paramValues = routerPath
      .replace(pathWithoutParams, '')
      ?.split('/')
      .slice(1);

    paramValues.forEach((v, idx) => {
      const paramName = paramNames?.[idx];
      if (paramName) {
        params[paramName] = v;
      }
    });
  }

  return Object.freeze(params);
};

export default getParams;
