import React from 'react';

interface LazyPreload<Props>
  extends React.LazyExoticComponent<React.ComponentType<Props>> {
  preload: () => { [key: string]: any };
}

export default function ReactLazyPreload<Props>(
  importStatement: () => Promise<{ default: React.ComponentType<Props> }>,
): LazyPreload<Props> {
  // use Object.assign to set preload
  // otherwise it will complain that Component doesn't have preload
  const Component: LazyPreload<Props> = Object.assign(
    React.lazy(importStatement),
    {
      preload: importStatement,
    },
  );

  return Component;
}
