import React, { ReactElement, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { css, SerializedStyles } from '@emotion/react';

import { palette } from '@lib/styles/palette';
import { getValidResourceUrl, checkIsValidResourceUrl } from '@lib/utils/lib';

import fallbackImg from '@asset/img/fall_back_img.png';

interface ImageProps {
  src: string;
  alt: string;
  style?: SerializedStyles;
  isSquare?: boolean;
  borderRadius?: number;
  className?: string;
  imgType?: 'image' | 'wikiImage';
  sizeType?: 'normal' | 'thumbnail';
  dimOpacity?: number;
  fallBackImg?: string;
  lazy?: boolean;
  onLoad?: (e: React.SyntheticEvent<HTMLImageElement>) => void;
}

function Image({
  src,
  alt,
  style,
  isSquare = true,
  borderRadius = 8,
  className,
  imgType = 'image',
  sizeType = 'normal',
  dimOpacity = 0,
  fallBackImg = fallbackImg,
  lazy = false,
  onLoad: _onLoad,
}: ImageProps): ReactElement {
  const [ref, inView] = useInView();
  const [imgSrc, setImgSrc] = useState(() => (lazy ? fallBackImg : src));
  const [isLoading, setIsLoading] = useState(true);
  const [isHeightLonger, setIsHeightLonger] = useState(false);
  const handleImgError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = fallBackImg;
  };

  // 원본 이미지 width,hegiht 비교
  const onLoad = (e: React.ChangeEvent<HTMLImageElement>) => {
    _onLoad?.(e);
    e.persist();
    const img = e.target;
    if (img.clientWidth < img.clientHeight) setIsHeightLonger(true);
    setIsLoading(false);
  };

  useEffect(() => {
    setImgSrc(lazy ? fallBackImg : src);
  }, [src, lazy, fallBackImg]);

  useEffect(() => {
    if (inView && src !== imgSrc) {
      setImgSrc(src);
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      css={imgCard(isSquare, dimOpacity, borderRadius)}
      className={className}
    >
      {isSquare && isLoading && (
        <div className={className} css={[defaultSkeletonStyle]}>
          <div className="animationBar" />
        </div>
      )}
      {isSquare ? (
        <img
          css={[imgSquare(isHeightLonger), style]}
          src={
            imgSrc.startsWith('data:image')
              ? imgSrc
              : getValidResourceUrl(imgSrc, imgType, sizeType)
          }
          alt={alt}
          width={1000}
          height={1000}
          onLoad={onLoad}
          referrerPolicy={checkIsValidResourceUrl(src) ? 'no-referrer' : ''}
          onError={handleImgError}
        />
      ) : (
        <img
          alt={alt}
          css={[imgRegtangle, style]}
          onError={handleImgError}
          onLoad={(e) => {
            _onLoad?.(e);
            setIsLoading(false);
          }}
          referrerPolicy={checkIsValidResourceUrl(src) ? 'no-referrer' : ''}
          src={
            imgSrc.startsWith('data:image')
              ? imgSrc
              : getValidResourceUrl(imgSrc, imgType, sizeType)
          }
        />
      )}
    </div>
  );
}

const defaultSkeletonStyle = css`
  background-color: ${palette.gray0};
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  @keyframes loading {
    0% {
      transform: translateX(-30%);
    }
    50%,
    100% {
      transform: translateX(100%);
    }
  }

  .animationBar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      ${palette.gray0} 0%,
      #ffffffae 30%,
      ${palette.gray0} 60%
    );
    animation: loading 1.5s infinite linear;
  }
`;

const imgCard = (
  isSquare: boolean,
  dimOpacity: number,
  borderRadius: number,
) => css`
  position: relative;
  width: 100%;
  padding-top: ${isSquare ? '100% ' : ''};
  overflow: hidden;
  border-radius: ${`${borderRadius}px`};

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, ${dimOpacity});
    top: 0;
    left: 0;
  }
`;

const imgSquare = (isHeightLonger: boolean) => css`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${isHeightLonger ? '100% ' : 'auto'};
  height: ${isHeightLonger ? 'auto' : '100%'};
  border-radius: 8px;
  object-fit: cover;
  transform: translate(-50%, -50%);
`;

const imgRegtangle = css`
  width: 100%;
  height: 100%;
`;

export default Image;
