import { isAndroid, isIOS } from 'react-device-detect';

import iconv from 'iconv-lite';

import { OsTypeKey, OsTypeKeys } from '@type/web/api';
import { PaymentDetailType } from '@type/webView/order';

import { SELLER_ADMIN_URL, SELLER_URL, SHARE_URL } from '@lib/constants/const';
import { PAYMENT_DETAIL } from '@lib/constants/orderPaymentDelivery';
import { closeWebView } from '@lib/utils/interface';

const aosInterface = window.qdillion;
const iosInterface = window.webkit && window.webkit.messageHandlers;

/** userAgent의 앱 정보 체크 */
export const getOsTypeByUserAgent = () => {
  const { userAgent } = navigator;
  const index = userAgent.indexOf('JNAPP');

  if (index > -1) {
    const jnAppData = userAgent.slice(index).split(')')[0];
    const osTypeStr = jnAppData.split(';')[1].trim();

    return { os: osTypeStr };
  }

  return null;
};

export const getOsTypeBySessionAppInfo = () => {
  const appInfo = getAppDataByStorage(true);

  return appInfo['Os-Type'];
};

export const getFlutterOsTypeByUserAgent = () => {
  const { userAgent } = navigator;
  const index = userAgent.indexOf('JNAPP');

  if (index > -1) {
    const jnAppData = userAgent.slice(index).split(')')[0];
    const isFlutter = jnAppData.split(';')[2].trim() === 'FLUTTER';

    return isFlutter;
  }

  return false;
};

/** sessionStorage나 userAgent에서 저장된 앱 정보 가져옴
 *  @param isFindSession session에서 app Info 가져올 건지 여부
 */
export const getAppDataByStorage = (isFindSession = false) => {
  const { userAgent } = navigator;
  const userAgents = userAgent.split('|');

  if (userAgents[1] && !isFindSession) {
    try {
      const data = JSON.parse(userAgents[1]);

      return data;
    } catch (e) {
      return null;
    }
  }

  if (sessionStorage.getItem('jnAppInfo')) {
    const data = JSON.parse(sessionStorage.getItem('jnAppInfo') || '');
    return { ...data, uuid: null };
  }

  return null;
};

/** 중고나라 AOS앱 여부 체크 */
export const isAndroidApp = (): boolean => {
  return isAppDevice() && isAndroid;
};

/** 중고나라 Flutter앱 여부 체크 */
export const isFlutterApp = (): boolean => {
  const { userAgent } = navigator;
  const index = userAgent.indexOf('JNAPP');

  if (index > -1) {
    const jnAppData = userAgent.slice(index).split(')')[0];
    const isFlutter = jnAppData.split(';')[2].trim() === 'FLUTTER';

    return isFlutter;
  }
  return false;
};

/** 중고나라 IOS앱 여부 체크 */
export const isIOSApp = (): boolean => {
  return isAppDevice() && isIOS;
};

/** 중고나라 APP 체크 */
export const isAppDevice = (): boolean => {
  const { userAgent } = navigator;

  return (
    (isAndroid && aosInterface) ||
    (isIOS && iosInterface?.getAppInfo && userAgent.indexOf('CriOS') === -1) ||
    isFlutterApp()
  );
};

/** 중고나라 MainWeb 여부 체크 */
export const isMainWeb = () => {
  // 임시로 localhost로 설정
  return (
    !isAppDevice() &&
    (window.document.referrer === 'http://localhost:3000/' ||
      window.document.referrer === 'http://localhost:3005/' ||
      window.document.referrer.includes(`${SHARE_URL}`))
  );
};

export const openMainWebPage = (url: string) => {
  if (!url || !isMainWeb() || url.startsWith('jnapps3://')) return;

  closeWebView();
  window.parent.location.href = `${SHARE_URL}${url}`;
};

/** 셀러지원센터 여부 체크 */
export const isSellerCenterWeb = () => {
  return (
    !isAppDevice() &&
    (window.document.referrer.includes(`${SELLER_URL}`) ||
      window.document.referrer.includes(`${SELLER_ADMIN_URL}`))
  );
};

/** OS TYPE */
export const getOsType = (): OsTypeKeys => {
  if (isAndroidApp()) return OsTypeKey.ANDROID;
  if (isIOSApp()) return OsTypeKey.IOS;
  return OsTypeKey.WEB;
};

/** App Version 가져오기 */
export const getAppVerstion = (): string | undefined => {
  const appInfo = getAppDataByStorage(true);
  return appInfo ? appInfo['App-Version'] : undefined;
};

export const saveSessionStorage = (name: string, data: any) => {
  window.sessionStorage.setItem(name, JSON.stringify(data));
};
export const removeSessionStorage = (name: string) => {
  window.sessionStorage.removeItem(name);
};
export const getSessionStorage = (name: string) => {
  const item = window.sessionStorage.getItem(name) || '';
  const data = JSON.parse(item);
  return data;
};

export const isEmptyObject = (obj: Record<string, unknown>): boolean => {
  return Object.getOwnPropertyNames(obj).length === 0;
};

// 파라미터 파싱
export function getQueryStringObjSafe(query = window.location.search): any {
  try {
    const returnParam: any = {};
    if (query) {
      const obj = query.substring(1).split('&');
      obj.forEach((item) => {
        const keyValue = item.split('=');
        const key = keyValue[0].toLowerCase();
        let value = keyValue[1];
        value = value.replace(/%(?![0-9a-fA-F][0-9a-fA-F]+)/g, '%25');
        returnParam[`${key}`] = decodeURIComponent(toUTF8(value));
      });
    }
    return returnParam;
  } catch (e) {
    console.log(e);
    return '';
  }
}

// 파라미터 파싱
export function getQueryStringObj(query = window.location.search): {
  [key: string]: string;
} {
  try {
    const returnParam: any = {};
    if (query) {
      const obj = query.substring(1).split('&');
      obj.forEach((item) => {
        const keyValue = item.split('=');
        const key = keyValue[0].toLowerCase();
        const value = keyValue[1];
        returnParam[`${key}`] = decodeURIComponent(toUTF8(value));
      });
    }
    return returnParam;
  } catch (e) {
    console.log(e);
    return getQueryStringObjSafe(query);
  }
}

export function toUTF8(string: string): string {
  const check = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

  if (!check.test(string)) {
    const buf = iconv.encode(string, 'iso-8859-1');
    const str = iconv.decode(buf, 'utf8');
    return str;
  }
  return string;
}

// appsFlyer 전용 함수. 결제수단에 대한 텍스트 생성.
export const makePaymentOption = (paymentDetail: PaymentDetailType): string => {
  switch (paymentDetail) {
    case PAYMENT_DETAIL.settle.card:
      return '중고나라페이 / 카드결제';
    case PAYMENT_DETAIL.settle.depositless:
      return '중고나라페이 / 무통장입금';
    case PAYMENT_DETAIL.settle.bankTransfer:
      return '중고나라페이 / 계좌이체';
    case PAYMENT_DETAIL.kb.appCard:
      return 'KB국민카드';
    case PAYMENT_DETAIL.kakao.pay:
      return '카카오페이';
    default:
      return '';
  }
  // if (paymentDetail === 'UNICRO_CARD') {
  //   return '유니크로 / 카드결제';
  // }
  // if (paymentDetail === 'UNICRO_DEPOSITLESS') {
  //   return '유니크로 / 무통장입금';
  // }
  // return '유니크로 / 계좌이체';
};
