import { GetPcccRes, PostPcccParam, PutPcccParam } from '@type/api/base/pccc';
import {
  UserStateInfoType,
  UserAccountInfoResponseType,
  UserAccountInfoPostType,
  UserSimpleInfoType,
} from '@type/api/base/user';
import { ApiResponse } from '@type/web/api';
import { LoginData, EmailLoginData } from '@type/web/login';

import { axiosRequest, AxiosDomain } from '@api/config';

const axiosBase = AxiosDomain.mainBase;
const axiosMainBase = AxiosDomain.mainBase;

/** 간편 회원정보 호출  => 사용 안함 */
// export const getUserSimpleInfo = (): Promise<
//   ApiResponse<UserSimpleInfoType>
// > => {
//   const path = `/user/state`;
//   return axiosRequest<UserSimpleInfoType>(axiosMainBase, 'get', path);
// };

/** 회원정보 호출 */
export const getUserStatusInfo = (): Promise<
  ApiResponse<UserStateInfoType>
> => {
  const path = `/user/state/info`;
  return axiosRequest<UserStateInfoType>(axiosMainBase, 'get', path);
};

/** 회원 이름 호출 */
export const getUserNameInfo = (): Promise<ApiResponse<string>> => {
  const path = '/user/info/name';

  return axiosRequest(axiosMainBase, 'get', path);
};

/** 이메일 로그인 시, 인증번호 요청 */
export const postUserRequestSms = ({
  userId,
  password,
}: LoginData): Promise<ApiResponse<any>> => {
  const path = `/v2/user/request/sms`;

  const params = {
    userId,
    password,
  };
  return axiosRequest<any>(axiosBase, 'post', path, params);
};

/** 이메일 로그인 요청 */
export const getUserEmailLogin = ({
  userId,
  password,
  verificationCode,
}: EmailLoginData): Promise<ApiResponse<any>> => {
  const path = `/v2/user/email/login`;

  const params = {
    accessToken: '',
    deviceOs: 2,
    deviceUuid: '',
    gcmId: '',
    joinType: 0,
    mobileAdId: '',
    refreshToken: '',
    uniqueId: '',
    userId,
    password,
    verificationCode,
  };
  return axiosRequest<any>(axiosBase, 'post', path, params);
};

/**
 * 사용자 계좌 리스트 조회
 */
export const getUserAccountList = (): Promise<
  ApiResponse<UserAccountInfoResponseType[]>
> => {
  const path = `/user/info/account`;

  return axiosRequest<UserAccountInfoResponseType[]>(axiosBase, 'get', path);
};

/**
 * 사용자 계좌 정보 조회 (유니크로)
 * @param {*} accountSeq
 */
export const getUserAccountInfo = (accountSeq: number) => {
  const path = `/user/info/account/${accountSeq}`;
  return axiosRequest<any>(axiosBase, 'get', path);
};

// /** 사용자 대표 계좌 */
// export const getUserMainAccount = (): Promise<ApiResponse<UserAccountType>> => {
//   const path = `/user/info/account/main`;
//   return axiosRequest<UserAccountType>(axiosBase, 'get', path);

/** 사용자 대표 계좌 조회 (그 외) */
export const getUserMainAccount = () => {
  const path = `/user/info/account/main`;
  return axiosRequest<UserAccountInfoResponseType>(axiosBase, 'get', path);
};

/**
 * 사용자 계좌 등록
 * @param {*} param
 * "accountHolder": "string", 예금주
   "accountNo": "string", 계좌 번호
   "accountSeq": 0, 계좌 seq ( 등록시에는 사용하지 않음 )
   "bankCode": "string", 은행코드
   "bankName": "string", 은행명
   "mainAccountYn": 0 대표계좌 여부 0: 대표아님, 1: 대표계좌
 */
export const postUserAccount = (
  params: UserAccountInfoPostType,
): Promise<ApiResponse<string>> => {
  const data = {
    ...params,
    accountSeq: 0,
  };
  const path = '/user/info/account';

  return axiosRequest(axiosMainBase, 'post', path, data);
};

/**
 * 사용자 대표 계좌 등록
 * @param {*} accountSeq - 계좌 seq.
 * @param {*} mainAccountYn - 대표계좌 여부. 0: 대표아님, 1: 대표계좌
 */
export const putUserMainAccount = (
  accountSeq: number,
  mainAccountYn: 0 | 1,
): Promise<ApiResponse<number>> => {
  const path = `/user/info/account/main/${accountSeq}/${mainAccountYn}`;

  return axiosRequest<number>(axiosBase, 'put', path);
};

/**
 * 사용자 계좌 삭제
 * @param {*} accountSeq - 계좌 seq.
 */
export const delUserAccount = (
  accountSeq: number,
): Promise<ApiResponse<number>> => {
  const path = `/user/info/account/${accountSeq}`;

  return axiosRequest<number>(axiosBase, 'delete', path);
};

/**
 * 개인통관고유부호 조회
 */
export const getPccc = (): Promise<ApiResponse<GetPcccRes>> => {
  const path = '/pccc';
  return axiosRequest(axiosBase, 'get', path);
};

/**
 * 개인통관고유부호 등록
 * @param {PostPcccParam['pccc']} pccc 개인통관고유부호 (P로 시작하는 13자리)
 */
export const postPccc = (
  pccc: PostPcccParam['pccc'],
): Promise<ApiResponse<Record<string, never>>> => {
  const path = '/pccc';
  return axiosRequest(axiosBase, 'post', path, { pccc });
};

/**
 * 개인통관고유부호 수정
 * @param {PutPcccParam['pkSeq']} pkSeq 개인통관고유부호 seq
 * @param {PutPcccParam['pccc']} pccc 개인통관고유부호 (P로 시작하는 13자리)
 */
export const putPccc = (
  pkSeq: PutPcccParam['pkSeq'],
  pccc: PutPcccParam['pccc'],
): Promise<ApiResponse<Record<string, never>>> => {
  const path = '/pccc';
  return axiosRequest(axiosBase, 'put', path, { pkSeq, pccc });
};
