import { isIOS, isSafari } from 'react-device-detect';

import { DOMAIN } from '@lib/constants/const';
import getLinkInfo from '@lib/utils/path/getLinkInfo';
import getParams from '@lib/utils/path/getParams';
import getQueries from '@lib/utils/path/getQueries';

const getDeepLink = (webUrl: string) => {
  // TODO 브릿지 웹에서 딥링크에 ga 정보 넣어주는 로직 추가 필요
  const params = getParams(webUrl);
  const queries = getQueries(webUrl);
  let deepLink = null;
  const useUniversalLink = isIOS && isSafari;

  const linkInfo = getLinkInfo({ web: webUrl });

  // 딥 링크가 있을 경우
  if (linkInfo && useUniversalLink) {
    deepLink = webUrl;
  } else if (linkInfo && !useUniversalLink) {
    // universal link를 사용하는 환경인 경우, 웹 링크 자체가 딥링크이므로, 웹 링크를 리던
    const webRoutePath = linkInfo?.web;

    // 까페 상품 상세
    if (webRoutePath?.includes('/product/detail/naver/')) {
      const articleSeq = params?.articleSeq;
      deepLink = linkInfo.app?.replace(`{articleSeq}`, articleSeq || '');
    }
    // 일반 상품 상세
    else if (webRoutePath?.includes('/product/detail/')) {
      const productSeq = params?.productSeq;
      deepLink = linkInfo.app?.replace(`{productSeq}`, productSeq || '');
    }
    // 키워드로 검색한 상품 목록
    else if (webRoutePath?.includes('/search/product')) {
      const searchword = queries?.keyword;
      deepLink = linkInfo.app?.replace(`{searchword}`, searchword || '');
    } else if (webRoutePath?.includes('/wiki')) {
      const routePath = webUrl.replace(
        // `http://192.168.11.67:3000` || `${DOMAIN}`,
        `${DOMAIN}`,
        '',
      );
      deepLink = linkInfo.app?.replace('/wiki', routePath);
    }
    // 이벤트 상세
    else if (webRoutePath?.includes('/event/detail/')) {
      const eventSeq = params?.eventSeq;
      deepLink = linkInfo.app?.replace(`{eventSeq}`, eventSeq || '');
    }
    // 컨텐츠 상세
    else if (webRoutePath?.includes('/content/detail/')) {
      const contentSeq = params?.contentSeq;
      deepLink = linkInfo.app?.replace(`{contentSeq}`, contentSeq || '');
    }
    // 출석 이벤트
    else if (webRoutePath?.includes('/point-event/')) {
      const date = params?.date;
      deepLink = linkInfo.app?.replace(`{date}`, date || '');
    }
    // 1:1 문의하기
    else if (webRoutePath?.includes('/cs/qna')) {
      const qnaCategorySeq = queries?.qnaCategorySeq;
      if (qnaCategorySeq)
        deepLink = linkInfo.app?.replace(`{qnaCategorySeq}`, qnaCategorySeq);
      else
        deepLink = linkInfo.app?.replace(
          `&qnaCategorySeq={qnaCategorySeq}`,
          '',
        );
    } else if (webRoutePath === '/') deepLink = linkInfo.app;
  }

  return deepLink;
};

export default getDeepLink;
