import { useEffect } from 'react';

export default function useKeyDown(
  key: string,
  onKeyDown: (...args: any[]) => any,
  params?: any[],
): void {
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === key) {
        event.preventDefault();
        onKeyDown(...(params || []));
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  });
}
