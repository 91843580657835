import { css } from '@emotion/react';

import useHistoryHook from '@hook/useHistory';

import { ProductReviewContentType } from '@type/api/main/orderHistory';

import BoxButton from '@components/common/Button/BoxButton';
import Icon from '@components/common/Icon/Icon';

import { palette } from '@lib/styles/palette';

import DetailReviewItem from './DetailReviewItem';

interface DetailReviewListProps {
  title: string;
  reviewData: ProductReviewContentType[] | undefined | [];
  reviewSize: number | undefined;
}

function DetailReviewList({
  title,
  reviewData,
  reviewSize,
}: DetailReviewListProps): JSX.Element {
  const { push, params } = useHistoryHook();
  const { storeSeq } = params as { storeSeq: string };

  return (
    <section css={reviewListStyle}>
      <header>
        {title}
        {reviewSize !== undefined && reviewSize > 0 && (
          <span>{reviewSize}</span>
        )}
      </header>
      {reviewData && reviewData?.length > 0 ? (
        <ul>
          {reviewData?.map((review) => {
            return (
              <DetailReviewItem key={review?.orderNo} reviewData={review} />
            );
          })}
        </ul>
      ) : (
        <div css={isEmptyDataStyle}>
          <Icon icon="chatFilled" />
          <p>작성된 후기가 없습니다.</p>
        </div>
      )}
      {reviewSize !== undefined && reviewSize > 5 && (
        <BoxButton
          level={1}
          type="outline"
          onClick={() => push(`/review/store/all/${storeSeq}`)}
        >
          후기 전체 보기
        </BoxButton>
      )}
    </section>
  );
}

export default DetailReviewList;

const reviewListStyle = css`
  padding: 24px 20px;

  header {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;

    span {
      color: ${palette.jnGreen};
      margin-left: 6px;
    }
  }

  ul {
    padding: 20px 0;
  }
`;

const isEmptyDataStyle = css`
  padding: 28px 0 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: ${palette.gray700};
    margin-top: 16px;
  }
`;
