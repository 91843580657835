import { useEffect, useCallback, useState } from 'react';

import { interfaceAosBackHold } from '@lib/utils/interface';

/**
 * AOS 물리백버튼 event hook
 *
 * @param callback 물리백버튼 클릭 시 호출할 handler
 * @param enabled handler 실행여부 (true일 경우, 기본 백버튼 동작을 막습니다.)
 */
export default function useBackKeyEvent(
  // eslint-disable-next-line @typescript-eslint/ban-types
  callback: Function,
  enabled = true,
): void {
  // 등록한 callback이 반복적으로 실행될 수 있도록 update를 하기 위한 state.
  const [trigger, setTrigger] = useState(false);

  const onBackKey = useCallback(() => {
    callback();
    interfaceAosBackHold(false);

    setTrigger((prev) => !prev);
  }, [callback]);

  useEffect(() => {
    if (enabled) {
      interfaceAosBackHold(true);
      window.addEventListener('onBackKeyEvent', onBackKey);
    } else interfaceAosBackHold(false);

    return () => {
      if (enabled) window.removeEventListener('onBackKeyEvent', onBackKey);
      else interfaceAosBackHold(false);
    };
  }, [trigger, enabled, onBackKey]);
}
