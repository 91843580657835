import { ReactElement } from 'react';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import './asset/pretendardvariable-dynamic-subset.css';
import GlobalStyles from './lib/styles/GlobalStyles';

import 'antd/dist/antd.less';

/*
 * Joongna.tsx
 - 전역적인 라이브러리 및 스타일 적용
 - interceptors 처리 로직 추가 예정
 */

const Joongna = (): ReactElement => {
  return (
    <>
      <GlobalStyles />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </>
  );
};

export default Joongna;
