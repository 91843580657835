import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ko';
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.locale('ko');
dayjs.extend(weekOfYear);

export type DayjsParamType = string | number | Dayjs | Date | null | undefined;

export const day = (date?: DayjsParamType): Dayjs => {
  return dayjs(date);
};

export const dayString = (
  format = 'YYYY.MM.DD HH:mm:ss',
  date: DayjsParamType,
): string => {
  return day(date).format(format);
};

export const isValidDay = (date: DayjsParamType): boolean => {
  return day(date).isValid();
};

export const isBeforeTime = (start: Dayjs, compare = day()): boolean => {
  return start.isBefore(compare);
};

export const isAfterTime = (start: Dayjs, compare = day()): boolean => {
  return start.isAfter(compare);
};

export function isSameTime(start: Dayjs, compare = day()): boolean {
  return start.isSame(compare);
}

export const isSameDate = (
  start: DayjsParamType,
  compare: DayjsParamType = day(),
): boolean => {
  return day(start).isSame(day(compare), 'date');
};

export const addDay = (d: number): Dayjs => {
  return day().add(d, 'day').startOf('day');
};

export const getRegDate = (time: DayjsParamType): string => {
  const diffSec = dayjs().diff(dayjs(time, 'YYYY.MM.DD HH:mm:ss'), 'second');
  if (diffSec < 10) {
    // 10초전
    return '방금전';
  }
  if (diffSec < 60) {
    // 10초~60초미만
    return `${diffSec}초 전`;
  }
  if (diffSec < 60 * 60) {
    // 1분~60분 미만
    return `${Number(diffSec / 60).toFixed(0)}분 전`;
  }
  if (diffSec < 60 * 60 * 24) {
    // 1시간~24시간 미만
    return `${Number(diffSec / 60 / 60).toFixed(0)}시간 전`;
  }
  if (diffSec < 60 * 60 * 24 * 30) {
    // 1일 30일 미만
    return `${Number(diffSec / 60 / 60 / 24).toFixed(0)}일 전`;
  }
  return dayjs(time, 'YYYY.MM.DD HH:mm:ss').format('YYYY.MM.DD');
};

export const getMonth = (date: DayjsParamType): number => {
  return dayjs(date).month();
};

export function getDay(dayObj = dayjs()): number {
  return dayObj.day();
}

export const dayOfWeek = (idx: number): { short: string; long: string } => {
  const dayOfTheWeek = [
    {
      short: '일',
      long: '일요일',
    },
    {
      short: '월',
      long: '월요일',
    },
    {
      short: '화',
      long: '화요일',
    },
    {
      short: '수',
      long: '수요일',
    },
    {
      short: '목',
      long: '목요일',
    },
    {
      short: '금',
      long: '금요일',
    },
    {
      short: '토',
      long: '토요일',
    },
  ];

  return dayOfTheWeek[idx];
};

export const isCurrentDate = (
  startDate?: string,
  endDate?: string,
  currentDate?: string,
): boolean => {
  const current = currentDate ?? dayString('YYYY-MM-DD HH:mm:ss', new Date());
  return (
    startDate !== undefined &&
    endDate !== undefined &&
    (startDate === '' || startDate <= current) &&
    (endDate === '' || endDate >= current)
  );
};
