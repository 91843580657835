// export {};
import {
  forwardRef,
  PropsWithChildren,
  useMemo,
  useRef,
  useImperativeHandle,
  useState,
  useLayoutEffect,
} from 'react';

import { css } from '@emotion/react';

import useGoBack from '@hook/useGoBack';

import { StylePropType } from '@type/webView/style';

import { palette } from '@lib/styles/palette';
import typography from '@lib/styles/typography';

import HeaderButton, { HeaderButtonPropsType } from './HeaderButton';

export interface HeaderPropsType {
  isFixed?: boolean;
  isShowBackground?: boolean;
  title?: JSX.Element | string;
  className?: string;
  isHiddenLine?: boolean;
  leftBtnOption?: HeaderButtonPropsType | false | null;
  rightBtnOption?: HeaderButtonPropsType | false | null;
  style?: StylePropType;
}

/**
 * `<BasicLayout />` 안에서 `headerOptions`로 옵션을 전달할 때 노출되는 Header Component.
 *
 * @param title 제목 텍스트.
 * @param isFixed 상단 고정처리 여부. sticky 지원하지 않을 경우, 가상 Element로 헤더 높이만큼 띄우도록 구현.
 * @param isShowBackground 배경 반투명처리 여부.
 * @param isHiddenLine 헤더 하단 테두리 노출 여부.
 * @param leftBtnOption 좌측 헤더 버튼 옵션. false일 경우 미적용.
 * @param rightBtnOption 우측 헤더 버튼 옵션.false일 경우 미적용.
 */
const Header = forwardRef<HTMLDivElement, PropsWithChildren<HeaderPropsType>>(
  (
    {
      title,
      isFixed = true,
      isShowBackground = true,
      isHiddenLine = false,
      className,
      leftBtnOption,
      rightBtnOption,
      children,
      style,
    },
    ref,
  ) => {
    const goBackHook = useGoBack();

    const defaultBtnOption = useMemo(
      () => ({
        left: {
          name: '뒤로가기',
          icon: 'arrowLeft',
          onClick: goBackHook,
          isDisabled: false,
          isTextBtn: false,
          className: '',
        } as HeaderButtonPropsType,
        right: {
          name: '닫기',
          icon: 'close',
          onClick: goBackHook,
          isDisabled: false,
          isTextBtn: false,
          className: '',
        } as HeaderButtonPropsType,
      }),
      [],
    );

    const fixedEleRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => fixedEleRef.current as HTMLDivElement);

    const [headerHeight, setHeaderHeight] = useState('');
    useLayoutEffect(() => {
      setHeaderHeight(
        fixedEleRef.current
          ? getComputedStyle(fixedEleRef.current).height
          : 'auto',
      );
    }, []);

    return (
      <header css={headerStyle(isFixed)}>
        <div
          css={[
            fixedHeaderStyle(isFixed, isShowBackground, isHiddenLine),
            style,
          ]}
          className={className}
          ref={fixedEleRef}
        >
          <div css={headerInnerWrapStyle}>
            <h1 css={titleStyle}>{title}</h1>
            <div css={leftBtnWrapStyle}>
              {leftBtnOption !== false && leftBtnOption !== null && (
                <HeaderButton {...defaultBtnOption.left} {...leftBtnOption} />
              )}
            </div>
            <div css={rightBtnWrapStyle}>
              {rightBtnOption !== false && rightBtnOption !== null && (
                <HeaderButton {...defaultBtnOption.right} {...rightBtnOption} />
              )}
            </div>
          </div>
          {children}
        </div>
        <div css={fixedSpaceStyle(isHiddenLine, headerHeight)} />
      </header>
    );
  },
);

Header.displayName = 'Header';

const headerStyle = (isFixed: boolean) => css`
  @supports (position: sticky) or (position: -webkit-sticky) {
    ${isFixed &&
    css`
      position: sticky;
      top: 0;
      z-index: 99;
    `}
  }
`;

const fixedHeaderStyle = (
  isFixed: boolean,
  isShowBackground: boolean,
  isHiddenLine: boolean,
) => css`
  ${typography.h5}
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99;
  background-color: rgba(255, 255, 255, ${isShowBackground ? 1 : 0});
  color: ${palette.gray900};

  ${!isHiddenLine &&
  css`
    border-bottom: 1px solid ${palette.gray15};
  `};

  ${isFixed &&
  css`
    position: fixed;
  `}

  @supports (position: sticky) or (position: -webkit-sticky) {
    ${isFixed &&
    css`
      position: static;
    `}
  }
`;

const headerInnerWrapStyle = css`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px;
  padding: 11px 20px;
  z-index: 9;
`;

const btnWrapStyle = css`
  width: 28px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const leftBtnWrapStyle = css`
  ${btnWrapStyle}
  text-align: left;
  z-index: 1;
`;

const rightBtnWrapStyle = css`
  ${btnWrapStyle}
  text-align: right;
  z-index: 1;
`;

const titleStyle = css`
  ${typography.h3}

  line-height: 25px;
  width: 100%;
  position: absolute;
  top: 22px;
  left: 0;
  transform: translateY(-50%);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
`;

const fixedSpaceStyle = (isHiddenLine: boolean, height: string) => css`
  width: 100%;
  height: ${height};
  opacity: 0;

  @supports (position: sticky) or (position: -webkit-sticky) {
    display: none;
  }
`;

export default Header;
