import { ReactElement, useState } from 'react';

import { css } from '@emotion/react';

import usePortal from '@hook/usePortal';

import { CommentStatusType, PageType } from '@type/webView/comment';

import { deleteComment } from '@api/comment/comment';

import ConfirmModal from '@components/common/ConfirmModal/ConfirmModal';
import Icon from '@components/common/Icon';

import useOpenAppWithWebUrl from '@lib/hook/deepLink/useOpenAppWithWebUrl';
import useInfiniteQueryWithScroll from '@lib/hook/infiniteQuery/useCommentInfiniteQueryWithScroll';
import { palette } from '@lib/styles/palette';
import { alertError } from '@lib/utils/modal';

import AppDownloadModal from '../AppDownloadModal';
import Loading from '../Loading/Loading';
import CommentCreateRouteButton from './CommentCreateRouteButton';
import CommentItem from './CommentItem';

interface CommentListPropsType {
  type: PageType;
  typeSeq: number;
  eventStatus?: number | undefined;
  popUpDeleteComment: () => void;
}

const isPossibleCommentCreate = (eventStatus: number | undefined) => {
  /** 댓글 쓰기가 가능한 경우
   * - 이벤트가 아닌 컨텐츠일 때
   * - 이벤트일경우 상태가 2 이하일 때 (예약중, 진행중)
   */
  return !eventStatus || (eventStatus && eventStatus < 2);
};

export default function CommentList({
  type,
  typeSeq,
  eventStatus,
  popUpDeleteComment,
}: CommentListPropsType): ReactElement {
  const { data, error, ObservationComponent, refetchCommentList } =
    useInfiniteQueryWithScroll({
      type,
      typeSeq,
    });

  const [deleteCommentSeq, setDeleteCommentSeq] = useState(0);
  const [dimImageSrc, setDimImageSrc] = useState('');

  const {
    PopUp: PreviewImgModal,
    onShowPopUp: onShowPreviewImg,
    onHidePopUp: onHidePreviewImg,
  } = usePortal('dim');
  const {
    PopUp: CommentDeleteModal,
    onShowPopUp: onShowCommentDelete,
    onHidePopUp: onHideCommentDelete,
  } = usePortal('dim');

  /** 앱 다운로드 유도 모달 */
  const {
    PopUp: AppDownloadModalContainer,
    onHidePopUp: onHideAppDownloadModalContainer,
    onShowPopUp: onShowAppDownloadModal,
  } = usePortal();
  const openAppWithWebUrl = useOpenAppWithWebUrl();

  return (
    <div css={CommentListStyle}>
      {error && '댓글을 불러올 수 없습니다.'}
      {data && (
        <>
          <div className="reply-header">
            <h2>
              댓글 <span>{data?.commentCount}</span>
            </h2>
          </div>

          {isPossibleCommentCreate(eventStatus) && (
            <CommentCreateRouteButton type={type} typeSeq={typeSeq} />
          )}

          <ul>
            {data?.commentList?.map((commentData) => {
              const { parentYn, childCount, commentStatus } = commentData;
              /** 삭제되었을 때
               * 부모 댓글인데 자식 답글이 0인 경우
               * 부모 댓글이 아닌 경우
               * 노출하지 않음
               */
              const isDisplayNone =
                commentStatus !== CommentStatusType.Show &&
                (!parentYn || childCount === 0);

              if (isDisplayNone) return null;

              return (
                <li key={commentData.commentSeq}>
                  <CommentItem
                    commentData={commentData}
                    setDimImageSrc={setDimImageSrc}
                    onShowPreviewImg={onShowPreviewImg}
                    setDeleteCommentSeq={setDeleteCommentSeq}
                    onShowCommentDelete={onShowCommentDelete}
                    type={type}
                    typeSeq={typeSeq}
                    onShowAppDownloadModal={onShowAppDownloadModal}
                  />
                </li>
              );
            }) ?? <Loading size="partial" />}
          </ul>
          <ObservationComponent />
        </>
      )}

      <AppDownloadModalContainer>
        <AppDownloadModal
          onClick={() => {
            openAppWithWebUrl(window.location.href);
          }}
          onHide={onHideAppDownloadModalContainer}
        />
      </AppDownloadModalContainer>

      <PreviewImgModal>
        <div css={dimStyle} onClick={onHidePreviewImg}>
          <div className="preview-img-wrap" onClick={onShowPreviewImg}>
            <img src={dimImageSrc} alt="preview" className="preview-img" />
          </div>
          <Icon
            icon="xCircleFill"
            size={50}
            onClick={onHidePreviewImg}
            className="dim-close-icon"
          />
        </div>
      </PreviewImgModal>

      <CommentDeleteModal>
        <ConfirmModal
          textLineList={['작성한 댓글 또는 답글을', '삭제 하시겠습니까?']}
          onHide={onHideCommentDelete}
          onClick={async () => {
            try {
              await deleteComment({
                type,
                commentSeq: deleteCommentSeq,
              });
              popUpDeleteComment();
              refetchCommentList();
            } catch (e) {
              alertError({
                onOk: () => {},
              });
            }
          }}
        />
      </CommentDeleteModal>
    </div>
  );
}

const dimStyle = css`
  .preview-img-wrap {
    width: 100vw;
    height: 100vh;
    .preview-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .dim-close-icon {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    cursor: pointer;
  }
`;

const CommentListStyle = css`
  padding: 1rem 1.5rem;
  border-top: 1px solid ${palette.gray0};
  margin-bottom: 3rem;

  .reply-header {
    margin: 1.5rem 0;

    h2 {
      font-size: 1.1rem;
      font-weight: 700;
    }
    span {
      font-weight: normal;
      color: ${palette.jnGreen_text};
    }
  }

  .child-reply {
    margin-left: 2.5rem;
  }

  & ::selection {
    background-color: none;
  }
`;
