import { useEffect } from 'react';

import { useSetRecoilState } from 'recoil';

import useAxios from '@hook/useAxios';

import { MainPageResponse } from '@type/api/base/mainpage';
import { CategoryListData } from '@type/web/category';
import { InitData } from '@type/web/init';

import * as CategoryAPI from '@api/base/category';
import * as BaseAPI from '@api/base/mainpage';
import * as InitAPI from '@api/boot/init';

import { categorys as atomCategorys } from '@recoils/category/atom';
import { initData as atomInitData } from '@recoils/init/atom';
import atomMaintenanceData from '@recoils/init/maintenance';
import { mainData as atomMainData } from '@recoils/main/atom';

const useInit = (): void => {
  const setInitData = useSetRecoilState(atomInitData);
  const setMaintenanceData = useSetRecoilState(atomMaintenanceData);

  const { data: initData, meta: initMetaData } = useAxios<InitData>({
    request: InitAPI.getInitInfo,
  });

  useEffect(() => {
    if (initData && initMetaData?.code === 777) {
      setMaintenanceData(initData);
      return;
    }

    if (initData !== null) setInitData(initData);
  }, [initData, initMetaData]);
};
export default useInit;
