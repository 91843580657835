export const OsTypeKey = {
  ANDROID: 0, // 'AOS',
  IOS: 1, // 'IOS',
  WEB: 2, // 'WEB',
} as const;

export type OsTypes = keyof typeof OsTypeKey;
export type OsTypeKeys = typeof OsTypeKey[OsTypes];

const ContentTypeKey = {
  JSON_TYPE: 'JSON_TYPE',
  FORM_TYPE: 'FORM_TYPE',
  MULTIPART_TYPE: 'MULTIPART_TYPE',
} as const;

export type ContentTypeKeys =
  typeof ContentTypeKey[keyof typeof ContentTypeKey];

export interface ApiHeaders {
  Authorization?: string;
  'Content-Type': string;
  'Os-Type': number;
  'App-Version': null;
}

export interface ApiMeta {
  code: number;
  message: string;
  status?: string;
}

export interface ApiResponse<T = unknown> {
  data: T;
  meta: ApiMeta | null;
  error?: {
    code: number;
    detail: string;
    title: string;
    status: number;
  };
}

export interface ApiInfo<R> {
  base: string;
  get: () => Promise<ApiResponse<R>>;
}
export interface ApiPostInfo<R> {
  base: string;
  post: () => Promise<ApiResponse<R>>;
}
