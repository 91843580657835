type VersionStringType = `${string}.${string}.${string}`;

const isUpper = (
  nowVersion: VersionStringType,
  comparedVersion: VersionStringType,
) => {
  const [nowMajor, nowMinor, nowPatch] = nowVersion.split('.').map(Number);
  const [comparedMajor, comparedMinor, comparedPatch] = comparedVersion
    .split('.')
    .map(Number);

  return (
    nowMajor > comparedMajor ||
    (nowMajor === comparedMajor && nowMinor > comparedMinor) ||
    (nowMajor === comparedMajor &&
      nowMinor === comparedMinor &&
      nowPatch >= comparedPatch)
  );
};

/**
 *
 * @param iosVersion 비교하려는 iosVersion
 * @param aosVersion 비교하려는 aosVersion
 * @returns {boolean} 현재 앱 버전이 비교하려는 버전 이상인지 여부
 */
const isLatestVersionMoreThan = ({
  iosVersion,
  aosVersion,
}: {
  iosVersion: VersionStringType;
  aosVersion: VersionStringType;
}): boolean => {
  const jnAppInfo = JSON.parse(sessionStorage.getItem('jnAppInfo') ?? '{}');
  const osType = Number(jnAppInfo['Os-Type']);
  const AppVersion = jnAppInfo['App-Version'];

  return (
    (osType === 0 &&
      AppVersion !== undefined &&
      isUpper(AppVersion, aosVersion)) ||
    (osType === 1 &&
      AppVersion !== undefined &&
      isUpper(AppVersion, iosVersion))
  );
};

export default isLatestVersionMoreThan;
