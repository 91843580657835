/* eslint-disable import/prefer-default-export */
import { IMG_URL, WIKI_IMG_URL, JCOUPON_IMG_URL } from '@lib/constants/const';

export const checkIsValidResourceUrl = (url: string) =>
  url?.startsWith('http') || url?.startsWith('/static/');

export const getValidResourceUrl = (
  src: string,
  imgType: 'image' | 'wikiImage' | 'jCouponImage' = 'image',
  sizeType: 'normal' | 'thumbnail' = 'normal',
): string => {
  const urlPrefix = imgType === 'wikiImage' ? WIKI_IMG_URL : IMG_URL;
  const urlSuffix = sizeType === 'thumbnail' ? '?impolicy=thumb' : '';

  if (src?.startsWith('/jcoupon') || imgType === 'jCouponImage') {
    return `${JCOUPON_IMG_URL}${src}${urlSuffix}`;
  }

  return checkIsValidResourceUrl(src)
    ? `${src}${urlSuffix}`
    : `${urlPrefix}${src}${urlSuffix}`;
};

// media 상대경로
export const getRelativePathOfResourceUrl = (
  absolutePath: string,
  imgType: 'image' | 'wikiImage' | 'jCouponImage' = 'image',
): string => {
  const urlPrefix = imgType === 'wikiImage' ? WIKI_IMG_URL : IMG_URL;
  const urlSuffix = '?impolicy=thumb';

  if (imgType === 'jCouponImage') {
    return absolutePath
      .replace(String(JCOUPON_IMG_URL), '')
      .replace(urlSuffix, '');
  }

  return absolutePath.replace(String(urlPrefix), '').replace(urlSuffix, '');
};

export const handleImgError = (defaultImg: string) => {
  return (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = defaultImg;
  };
};

export const checkCategoryDepth = (categorySeq: number): number => {
  if (categorySeq > 23 && categorySeq < 185) return 2;
  if (categorySeq > 185) return 3;

  return 1;
};

export const ellipsisWord = (keyword: string): string => {
  if (keyword.length <= 7) return keyword;
  const sliceString = keyword.slice(0, 7);
  const result = sliceString.concat('...');

  return result;
};

export const removeEmojis = (value: string): string => {
  const convertRegex =
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF]|\uD83E[\uDD0C\uDD0F\uDEC0-\uDEC2\uDEE0\uDEE2-\uDEE6\uDEF0-\uDEF5]|\uD83E[\uDD0D-\uDD0E\uDE78-\uDE7C\uDE80\uDE83-\uDE86\uDE90-\uDE95\uDE98-\uDE99\uDE9B-\uDE9C\uDE9E-\uDEA0\uDEA3-\uDEA5\uDEA9-\uDEAc\uDEB6\uDED0-\uDED3\uDED9\uDEE1\uDEE7]|[\u2B55]|\uD83E[\uDE9A\uDEA6-\uDEA7\uDEB2]|\uD83E[\uDEA8\uDEB3-\uDEB5\uDEB7-\uDEBA]|\u2B05|\u2B06|\u2B07|\u2B1B|\u2B1C|\u2B50|\u2B55|\u231A|\u231B|\u2328|\u23CF|[\u23E9-\u23F3]|[\u23F8-\u23FA]|\uD83C\uDCCF|\u2934|\u2935|[\u2190-\u21FF]|\ud83e[\udea1\udeb0])/g;

  const convertValue = value.replace(convertRegex, '');
  return convertValue;
};

export const validateKeyword = (value: string): boolean => {
  const searchWordRegex = /^[0-9가-힣ㄱ-ㅎa-zA-Z\s]+$/.test(value);
  return searchWordRegex;
};

export const removeExtraSpaces = (value: string): string => {
  const firstSpaceIndex = value.indexOf(' ');

  if (firstSpaceIndex !== -1) {
    const [firstPart, ...remainingPart] = value.split(' ');
    return `${firstPart} ${remainingPart.join('')}`;
  }
  return value;
};
