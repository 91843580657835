import { useQuery } from 'react-query';

import axios, { AxiosError } from 'axios';
import { useSetRecoilState } from 'recoil';

import { css } from '@emotion/react';

import useHistoryHook from '@hook/useHistory';

import { getUserStoreReview } from '@api/base/order';

import { alertOpenSelector } from '@recoils/orderPaymentDelivery/alert';

import BasicLayout from '@components/common/BasicLayout';
import DetailReviewFeedback from '@components/webView/Order/Review/DetailReviewFeedback';
import DetailReviewList from '@components/webView/Order/Review/DetailReviewList';

import { palette } from '@lib/styles/palette';

const OrderReviewListPage = (): JSX.Element => {
  const { params } = useHistoryHook();
  const { storeSeq } = params as { storeSeq: string };

  const openAlert = useSetRecoilState(alertOpenSelector);

  const { data: storeReviewData } = useQuery(
    ['store-review-info', storeSeq],
    () => getUserStoreReview(storeSeq),
    {
      enabled: !!storeSeq,
      onError: (error) => {
        if (axios.isAxiosError(error)) {
          const err = error as AxiosError;
          const { message } = err.response?.data?.meta;

          openAlert({
            text: `${message}` || '오류입니다.\n잠시 후 다시 시도해주세요.',
          });
        }
      },
    },
  );

  const storeReviewInfo = storeReviewData?.data;
  const reviewFeedback = storeReviewInfo?.productReviewCount;

  return (
    <BasicLayout
      headerOptions={{
        title: '후기',
        rightBtnOption: false,
        isHiddenLine: true,
      }}
    >
      <main css={storeReviewContainerStyle}>
        <DetailReviewFeedback
          title="이런점이 좋았어요"
          feedbackData={reviewFeedback?.goodReviewFeedbackCount}
        />
        <DetailReviewFeedback
          title="이런점이 아쉬워요"
          feedbackData={reviewFeedback?.badReviewFeedbackCount}
        />

        <DetailReviewList
          title="상세한 후기도 있어요"
          reviewData={storeReviewInfo?.productReviewContents}
          reviewSize={storeReviewInfo?.reviewSize}
        />
      </main>
    </BasicLayout>
  );
};

export default OrderReviewListPage;

const storeReviewContainerStyle = css`
  .summaryWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 0;
    background-color: ${palette.gray900};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: ${palette.white};

    h3 {
      color: ${palette.white};
      font-size: 24px;
      font-weight: 600;
      line-height: 34px;
      margin-bottom: 10px;
    }

    .reviewCount {
      display: flex;
      align-items: center;
      p:first-of-type {
        padding-right: 16px;
        border-right: 1px solid ${palette.gray0};
      }
      p:last-of-type {
        padding-left: 16px;
      }
    }
  }
`;
