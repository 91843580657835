import { ReactElement, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useSetRecoilState } from 'recoil';

import styled from '@emotion/styled';

import useGA from '@hook/useGA';

import { PageType } from '@type/webView/comment';

import { axiosRequest, AxiosDomain } from '@api/config';

import {
  alertCloseSelector,
  alertOpenSelector,
} from '@recoils/orderPaymentDelivery/alert';
import { toastMessageSelector } from '@recoils/popup/toast';

import CommentList from '@components/common/Comment/CommentList';
import ImageComponent from '@components/common/Image';
import Layout from '@components/webView/ImportantDay/Layout';
import PrizeList from '@components/webView/ImportantDay/PrizeList';
import { PrizeWrapper, Section } from '@components/webView/ImportantDay/Ui';

import { prize } from '@lib/data/ImportantDay/Prize';
import { getLoginStatus } from '@lib/utils/auth';
import { closeWebView, showLoginPopup } from '@lib/utils/interface';
import { isAfterTime, day, isBeforeTime } from '@lib/utils/time';

import { a11yHidden } from '../Wiki/WikiInfoRequest/WikiInfoRequest.style';

const { pointEvent } = AxiosDomain;
const importantDayImageSrc = 'https://common.joongna.com/image/important_day';

const PageComponent = (): ReactElement => {
  const [diabled, setDisabled] = useState(false);

  const eventSeq = process.env.REACT_APP_ENV === 'stage' ? 677 : 1193;

  const isLogin = getLoginStatus();
  const setToastMessage = useSetRecoilState(toastMessageSelector);
  const openAlert = useSetRecoilState(alertOpenSelector);
  const closeAlert = useSetRecoilState(alertCloseSelector);
  const { sendGA4Data } = useGA();

  const onClickOpenAppBtn = (path: string) => {
    const scheme = 'jnapps3://?';

    window.open(`${scheme}${path}`);
  };

  const handleClick = async () => {
    if (!isLogin) {
      showLoginPopup();
      return;
    }

    setDisabled(true);

    try {
      const {
        data: { mileage },
      } = await axiosRequest<{ mileage: number }>(
        pointEvent,
        'post',
        '/point/use/award/random-mileage/events/3',
      );
      openAlert({
        text: `마일리지 ${mileage}M 바로 지급`,
        subText: '매일 참여하고 마일리지 받으세요!',
        bottomText: '마일리지 100배 받으러 가기 >',
        onClick: closeAlert,
      });
    } catch (e) {
      openAlert({
        text: '이미 마일리지 지급이 완료되었습니다.',
        bottomText: '확인',
        onClick: closeAlert,
      });
    }

    setDisabled(false);
  };

  const saleRate =
    (isAfterTime(day(), day('2024-03-06 12:00:00')) &&
      isBeforeTime(day(), day('2024-03-06 18:00:00')) &&
      '1.5') ||
    (isAfterTime(day(), day('2024-03-07 12:00:00')) &&
      isBeforeTime(day(), day('2024-03-07 18:00:00')) &&
      '1.9') ||
    (isAfterTime(day(), day('2024-03-08 12:00:00')) &&
      isBeforeTime(day(), day('2024-03-08 18:00:00')) &&
      '1.75') ||
    'default';

  useEffect(() => {
    sendGA4Data({
      eventType: 'screen_view',
      param: {
        screen_name: `중요일 이벤트_${3}월`,
      },
    });
  }, []);

  return (
    <Layout>
      <Section>
        <H2>미리 찾아온 봄처럼 따뜻한 3월 중요일</H2>
        <Image
          src={`${importantDayImageSrc}/banner/202403/banner_0_1_1709023474.webp`}
          alt="미리 찾아온 봄처럼 따뜻한 3월 중요일"
          isSquare={false}
          borderRadius={0}
          className="100vh"
          onLoad={(e) => {
            const $image = e.target as HTMLImageElement;
            $image.parentElement?.classList.remove('100vh');
          }}
        />
        <Image
          src={`${importantDayImageSrc}/banner/202403/banner_0_2_1709023474.webp`}
          alt="미리 찾아온 봄처럼 따뜻한 3월 중요일"
          isSquare={false}
          borderRadius={0}
          aria-hidden="true"
          className="absolute"
        />
        <Image
          src={`${importantDayImageSrc}/banner/202403/banner_0_month.gif`}
          alt="미리 찾아온 봄처럼 따뜻한 3월 중요일"
          isSquare={false}
          borderRadius={0}
          aria-hidden="true"
          className="month"
        />
        <Image
          src={`${importantDayImageSrc}/banner/202403/banner_0_blossom.gif`}
          alt="미리 찾아온 봄처럼 따뜻한 3월 중요일"
          isSquare={false}
          borderRadius={0}
          aria-hidden="true"
          className="blossom"
        />
      </Section>

      <Section>
        <H2>매일 달라지는 수수료 파격할인</H2>
        <Image
          src={`${importantDayImageSrc}/banner/202403/banner_1_${saleRate}_1709023474.webp`}
          alt="매일 달라지는 수수료 파격할인"
          isSquare={false}
          borderRadius={0}
        />
      </Section>

      <Section>
        <H2>클릭만 해도 최대 3만 마일리지 지급</H2>
        <Image
          src={`${importantDayImageSrc}/banner/202403/banner_2_1709518648.webp`}
          alt="클릭만 해도 최대 3만 마일리지 지급"
          isSquare={false}
          borderRadius={0}
        />

        <button disabled={diabled} onClick={handleClick}>
          <Image
            src={`${importantDayImageSrc}/banner/202403/banner_2_button.gif`}
            alt=""
            className={classNames('button', { disabled: diabled })}
            isSquare={false}
            borderRadius={0}
          />
        </button>
      </Section>

      <Section background="#AFECC8">
        <H2>매일 12시 릴레이 특가</H2>
        <Image
          src={`${importantDayImageSrc}/banner/202403/banner_3_1709023474.webp`}
          alt="매일 12시 릴레이 특가"
          isSquare={false}
          borderRadius={0}
        />

        <PrizeWrapper>
          {prize.map((item) => (
            <PrizeList key={item.date} date={item.date} prizes={item.prizes} />
          ))}
        </PrizeWrapper>
      </Section>

      <Section>
        <H2>누구나 갖고 싶은 이것 무엇일까요?</H2>
        <Image
          src={`${importantDayImageSrc}/banner/202403/banner_4_1_1709023474.webp`}
          alt="누구나 갖고 싶은 이것, 이것은 무엇일까요?"
          isSquare={false}
          borderRadius={0}
        />
        <button onClick={() => onClickOpenAppBtn('productseq=157470470')}>
          <Image
            src={`${importantDayImageSrc}/banner/202403/banner_4_2_1709023474.webp`}
            alt="힌트보고 100원 받기"
            isSquare={false}
            borderRadius={0}
          />
        </button>
        <button onClick={() => onClickOpenAppBtn('eventseq=1186')}>
          <Image
            src={`${importantDayImageSrc}/banner/202403/banner_4_3_1709023474.webp`}
            alt="상품 무료 체험하러 가기"
            isSquare={false}
            borderRadius={0}
          />
        </button>
        <Image
          src={`${importantDayImageSrc}/banner/202403/banner_4_4_1709023474.webp`}
          alt="추첨을 통해 네이버페이 포인트 1만원권 10명, 컴포즈 커피 아메리카노 1잔 50명 증정"
          isSquare={false}
          borderRadius={0}
        />
      </Section>

      <Section>
        <H2>유의사항</H2>
        <Image
          src={`${importantDayImageSrc}/banner/202403/notice_1709518648.webp`}
          alt="유의시항"
          isSquare={false}
          borderRadius={0}
          lazy={true}
        />
      </Section>

      {isLogin && (
        <CommentList
          type={PageType.Event}
          typeSeq={eventSeq}
          popUpDeleteComment={() => {
            setToastMessage('삭제되었습니다.');
          }}
        />
      )}
    </Layout>
  );
};

const H2 = styled.h2(a11yHidden);

const Image = styled(ImageComponent)`
  display: flex;
  position: relative;
  background-color: white;

  &.100vh {
    height: 100vh;
  }

  &.absolute {
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  &.blossom {
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
  }

  &.month {
    z-index: 20;
    position: absolute;
    background-color: transparent;
    top: calc(100vw / 4.2);
    left: calc(100vw / 4);
    width: calc(100vw / 3);
  }

  &.button {
    position: absolute;
    background-color: transparent;
    top: 26%;
    left: 0;
    aspect-ratio: 800 / 386;
    height: auto;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export default PageComponent;
