import { forwardRef, ReactElement, ReactNode, useMemo } from 'react';

import { css, SerializedStyles } from '@emotion/react';

import { HeaderOptions as LayoutHeaderOptions } from '@type/webView/header';
import { WikiHeaderOptions } from '@type/webView/wikiHeader';

import WikiFooter from '@components/common/Footer/WikiFooter';
import Header, {
  HeaderPropsType as HeaderOptions,
} from '@components/common/Header/Header';
import LayoutHeader from '@components/common/Header/LayoutHeader';
import WikiHeader from '@components/common/Header/WikiHeader';

import { palette } from '@lib/styles/palette';

interface BasicLayoutProps {
  wrapperStyle?: SerializedStyles;
  contentStyle?: SerializedStyles;
  headerOptions?: HeaderOptions & {
    children?: string | ReactElement;
  };
  layoutHeaderOptions?: LayoutHeaderOptions;
  wikiHeaderOptions?: WikiHeaderOptions;
  children: ReactNode;
  footerCustom?: ReactNode;
  hasFooter?: boolean;
}

const BasicLayout = forwardRef<HTMLDivElement, BasicLayoutProps>(
  (
    {
      wrapperStyle,
      contentStyle,
      headerOptions,
      wikiHeaderOptions,
      layoutHeaderOptions,
      children,
      hasFooter,
    },
    ref,
  ): ReactElement => {
    const layoutHeaderProps = useMemo(
      () => ({
        isFixed: false,
        isShowBackground: true,
        ...layoutHeaderOptions,
      }),
      [layoutHeaderOptions],
    );

    return (
      <div
        className="basic-layout"
        css={[
          wrapper(
            layoutHeaderOptions?.isFixed || wikiHeaderOptions?.isFixed || false,
          ),
          wrapperStyle,
        ]}
      >
        {layoutHeaderOptions && (
          <div
            css={header(
              layoutHeaderProps.isFixed,
              layoutHeaderProps.isShowBackground,
            )}
          >
            <LayoutHeader {...layoutHeaderProps} />
          </div>
        )}
        {wikiHeaderOptions && (
          <div
            css={wikiHeader(
              wikiHeaderOptions.isFixed,
              wikiHeaderOptions.backgroundColor,
            )}
          >
            <WikiHeader {...wikiHeaderOptions} />
          </div>
        )}
        {headerOptions && <Header {...headerOptions} />}
        <div id="container" className="container" css={contentStyle} ref={ref}>
          {children}
          {hasFooter && (
            <div className="footerWrap">
              <WikiFooter />
            </div>
          )}
        </div>
      </div>
    );
  },
);

const wrapper = (isFixed: boolean) => css`
  display: flex;
  flex-direction: column;
  height: 100vh;
  -webkit-touch-callout: none;

  .container {
    flex: 1;
    width: 100%;
    background: ${palette.white};
    padding-top: ${isFixed ? '44px' : '0'};
    height: 100%;
    overflow-y: scroll;
  }
  .footerWrap {
    width: 100%;
    align-self: flex-end;
    padding: 32px 15px 77px;
    color: ${palette.gray5};
    background-color: ${palette.gray0};
    font-size: 12px;
  }
`;

const header = (
  isFixed: boolean | undefined,
  isShowBackground: boolean | undefined,
) => css`
  width: 100%;
  // 255, 255, 255, 1 바탕 화이트 / 바탕 투명
  background: rgba(255, 255, 255, ${isShowBackground ? '1' : ''});
  position: ${isFixed ? 'fixed' : 'sticky'};
  top: 0;
  z-index: 99;
`;

const wikiHeader = (
  isFixed: boolean | undefined,
  backgroundColor: string,
) => css`
  width: 100%;
  background-color: ${backgroundColor};
  position: ${isFixed ? 'fixed' : 'sticky'};
  top: 0;
  z-index: 99;
`;

BasicLayout.displayName = 'Layout';
export default BasicLayout;
