import { ApiResponse } from '@type/web/api';
import { InitData } from '@type/web/init';

import { axiosRequest, AxiosDomain } from '@api/config';

const axiosBoot = AxiosDomain.boot;

/** 앱 초기 데이터 조회 */
export const getInitInfo = (version = 'v2'): Promise<ApiResponse<InitData>> => {
  const path = `/api/init/${version}/initFrontInfo`;
  return axiosRequest<InitData>(axiosBoot, 'get', path);
};

export default { getInitInfo };
