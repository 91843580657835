import { ReactNode } from 'react';

import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal/Modal';

interface AlertType extends ModalProps {
  title: string;
  content: ReactNode;
}

export const modalWarning = ({ title, content, onOk }: AlertType): void => {
  Modal.warning({
    title,
    content,
    onOk,
  });
};

export const alertReadyLink = (e: React.MouseEvent<HTMLElement>): void => {
  e.preventDefault();
  modalWarning({
    title: '준비중',
    content: '해당 기능은 준비중입니다.',
  });
};

export const alertEmptyUser = ({ onOk }: ModalProps): void => {
  modalWarning({
    title: '안내',
    content: '탈퇴한 회원의 상품입니다.',
    onOk,
  });
};

export const alertError = ({ onOk }: ModalProps): void => {
  modalWarning({
    title: '에러',
    content: '문제가 발생했습니다. 잠시후 다시 시도해주세요.',
    onOk,
  });
};

export const alertKeywordLimit = (): void => {
  modalWarning({
    title: '검색어 제한',
    content: '검색어는 2글자 이상 입력해주세요.',
  });
};

export const alertAppLink = (): void => {
  modalWarning({
    title: '안내',
    content: '해당 기능은 앱에서 가능합니다.',
  });
};
