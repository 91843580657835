const CLOSE_TODAY_APP_DOWNLOAD_POPUP_LS = 'closeTodayAppDownloadPopUp';

export function setCloseTodayAppDownloadPopUp(): void {
  localStorage.setItem(
    CLOSE_TODAY_APP_DOWNLOAD_POPUP_LS,
    new Date().toDateString(),
  );
}

export function isCloseTodayAppDownloadPopUp(): boolean {
  const closeDate = localStorage.getItem(CLOSE_TODAY_APP_DOWNLOAD_POPUP_LS);

  return Boolean(closeDate) && closeDate === new Date().toDateString();
}
