import { ReactElement } from 'react';

import { palette } from '@lib/styles/palette';

interface LogoProps {
  size?: number;
  color?: string;
  logoType?: 'ONLY_ICON' | 'ONLY_TEXT' | 'VERTICAL' | 'ONE_LINE';
}

function Logo({
  size,
  color = palette.white,
  logoType = 'ONE_LINE',
}: LogoProps): ReactElement {
  switch (logoType) {
    case 'ONLY_TEXT':
      return (
        <svg
          width="130"
          height="21"
          viewBox="0 0 735 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_43_4)">
            <path
              d="M156.089 29.1153C138.066 29.1153 124.388 43.5282 124.388 61.5442C124.388 79.5603 138.066 94.3593 156.089 94.3593C174.111 94.3593 188.722 79.7534 188.722 61.7373C188.722 43.7212 174.111 29.1153 156.089 29.1153ZM156.089 79.3351C146.369 79.3351 138.484 71.4531 138.484 61.7373C138.484 52.0215 146.369 44.1394 156.089 44.1394C165.808 44.1394 173.693 52.0215 173.693 61.7373C173.693 71.4531 165.808 79.3351 156.089 79.3351Z"
              fill="black"
            />
            <path
              d="M650.036 37.7051V29.9839H635.007V93.5228H650.069V61.126C650.069 55.3673 651.485 42.4343 666.096 43.303V29.1153C659.112 29.63 653.931 32.5577 650.036 37.7373V37.7051Z"
              fill="black"
            />
            <path
              d="M446.51 29.1153C428.487 29.1153 413.876 43.7212 413.876 61.7373C413.876 79.7534 428.487 94.3593 446.51 94.3593C464.532 94.3593 479.144 79.7534 479.144 61.7373C479.144 43.7212 464.532 29.1153 446.51 29.1153ZM446.51 79.2386C436.855 79.2386 429.002 71.3888 429.002 61.7373C429.002 52.0858 436.855 44.2359 446.51 44.2359C456.165 44.2359 464.017 52.0858 464.017 61.7373C464.017 71.3888 456.165 79.2386 446.51 79.2386Z"
              fill="black"
            />
            <path
              d="M622.874 29.9839H607.78V35.7105C602.727 31.4638 596.838 29.1475 589.049 29.1475C571.027 29.1475 556.094 43.7534 556.094 61.7694C556.094 79.7855 570.898 94.3914 588.92 94.3914C598.543 94.3914 604.787 90.1126 607.812 86.1233V93.555H622.874V30.0161V29.9839ZM589.5 79.9142C579.394 79.9142 571.187 71.7105 571.187 61.6086C571.187 51.5067 579.394 43.303 589.5 43.303C599.605 43.303 607.812 51.5067 607.812 61.6086C607.812 71.7105 599.605 79.9142 589.5 79.9142Z"
              fill="black"
            />
            <path
              d="M735 29.9839H719.906V35.7105C714.853 31.4638 708.964 29.1475 701.175 29.1475C683.153 29.1475 668.22 43.7534 668.22 61.7694C668.22 79.7855 683.024 94.3914 701.047 94.3914C710.67 94.3914 716.913 90.1126 719.938 86.1233V93.555H735V30.0161V29.9839ZM701.626 79.9142C691.52 79.9142 683.314 71.7105 683.314 61.6086C683.314 51.5067 691.52 43.303 701.626 43.303C711.732 43.303 719.938 51.5067 719.938 61.6086C719.938 71.7105 711.732 79.9142 701.626 79.9142Z"
              fill="black"
            />
            <path
              d="M85.1246 29.1153C67.102 29.1153 52.4908 43.7212 52.4908 61.7373C52.4908 79.7534 67.102 94.3593 85.1246 94.3593C103.147 94.3593 117.758 79.7534 117.758 61.7373C117.758 43.7212 103.147 29.1153 85.1246 29.1153ZM85.1246 79.3351C75.4053 79.3351 67.5204 71.4531 67.5204 61.7373C67.5204 52.0215 75.4053 44.1394 85.1246 44.1394C94.8439 44.1394 102.729 52.0215 102.729 61.7373C102.729 71.4531 94.8439 79.3351 85.1246 79.3351Z"
              fill="black"
            />
            <path
              d="M241.31 93.5228V57.5872C241.31 51.9571 238.671 43.9786 228.951 43.3995C214.404 42.563 212.988 55.4317 212.988 61.1582V93.5228H197.927V29.9839H212.956V37.8338C217.237 32.1394 223.062 29.1475 231.14 29.1475C231.14 29.1475 256.371 28.4397 256.371 55.4638V93.4906H241.31V93.5228Z"
              fill="black"
            />
            <path
              d="M531.731 93.5228V57.5872C531.731 51.9571 529.092 43.9786 519.373 43.3995C504.826 42.563 503.41 55.4317 503.41 61.1582V93.5228H488.348V29.9839H503.378V37.8338C507.658 32.1394 513.483 29.1475 521.561 29.1475C521.561 29.1475 546.793 28.4397 546.793 55.4638V93.4906H531.731V93.5228Z"
              fill="black"
            />
            <path
              d="M315.492 60.4504C315.492 70.1019 307.671 77.9196 298.016 77.9196C288.362 77.9196 280.541 70.1019 280.541 60.4504C280.541 50.7989 288.362 42.9812 298.016 42.9812C307.671 42.9812 315.492 50.7989 315.492 60.4504ZM285.336 97.7373H268.762C268.762 97.7373 273.525 120.032 299.4 120.032C319.708 120.032 330.489 108.097 330.489 88.6005V29.9839H315.46V35.4531C310.986 31.689 305.837 29.5013 299.175 29.1796C284.049 28.4075 270.21 37.9303 266.541 52.6327C261.36 73.4477 277.226 92.0751 297.212 92.0751C306.513 92.0751 312.531 87.9249 315.46 84.0643C315.46 84.0643 319 102.981 304.872 106.81C297.18 108.901 288.458 106.231 285.304 97.7694L285.336 97.7373Z"
              fill="black"
            />
            <path
              d="M389.61 60.4504C389.61 70.1019 381.789 77.9196 372.134 77.9196C362.479 77.9196 354.659 70.1019 354.659 60.4504C354.659 50.7989 362.479 42.9812 372.134 42.9812C381.789 42.9812 389.61 50.7989 389.61 60.4504ZM359.454 97.7373H342.88C342.88 97.7373 347.643 120.032 373.518 120.032C393.826 120.032 404.607 108.097 404.607 88.6005V29.9839H389.578V35.4531C385.104 31.689 379.955 29.5013 373.293 29.1796C358.167 28.4075 344.328 37.9303 340.659 52.6327C335.478 73.4477 351.344 92.0751 371.33 92.0751C380.631 92.0751 386.649 87.9249 389.578 84.0643C389.578 84.0643 393.118 102.981 378.989 106.81C371.298 108.901 362.576 106.231 359.422 97.7694L359.454 97.7373Z"
              fill="black"
            />
            <path
              d="M28.2247 0V58.2306C28.2247 69.3941 19.1812 78.4343 8.01362 78.4343H0V93.4906H8.432C27.6776 93.4906 43.2864 77.8874 43.2864 58.6488V0H28.2247Z"
              fill="black"
            />
            <path
              d="M28.2247 0V58.2306C28.2247 69.3941 19.1812 78.4343 8.01362 78.4343H0V93.4906H8.432C27.6776 93.4906 43.2864 77.8874 43.2864 58.6488V0H28.2247Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_43_4">
              <rect width="735" height="120" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 'ONLY_ICON':
      return (
        <svg
          width={size || 116}
          height="21"
          viewBox="0 0 116 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2912_74886)">
            <g clipPath="url(#clip1_2912_74886)">
              <path
                d="M25.1127 5.20125C22.3091 5.20125 20.1814 7.44413 20.1814 10.2477C20.1814 13.0513 22.3091 15.3543 25.1127 15.3543C27.9163 15.3543 30.1893 13.0814 30.1893 10.2778C30.1893 7.47417 27.9163 5.20125 25.1127 5.20125ZM25.1127 13.0163C23.6008 13.0163 22.3742 11.7897 22.3742 10.2778C22.3742 8.76583 23.6008 7.53925 25.1127 7.53925C26.6247 7.53925 27.8513 8.76583 27.8513 10.2778C27.8513 11.7897 26.6247 13.0163 25.1127 13.0163Z"
                fill={color}
              />
              <path
                d="M101.952 6.53797V5.33642H99.6135V15.2241H101.957V10.1826C101.957 9.2865 102.177 7.27391 104.45 7.40909V5.20125C103.363 5.28135 102.557 5.73694 101.952 6.54297V6.53797Z"
                fill={color}
              />
              <path
                d="M70.2909 5.20125C67.4873 5.20125 65.2144 7.47417 65.2144 10.2778C65.2144 13.0814 67.4873 15.3543 70.2909 15.3543C73.0945 15.3543 75.3674 13.0814 75.3674 10.2778C75.3674 7.47417 73.0945 5.20125 70.2909 5.20125ZM70.2909 13.0013C68.7889 13.0013 67.5674 11.7797 67.5674 10.2778C67.5674 8.77584 68.7889 7.55427 70.2909 7.55427C71.7928 7.55427 73.0144 8.77584 73.0144 10.2778C73.0144 11.7797 71.7928 13.0013 70.2909 13.0013Z"
                fill={color}
              />
              <path
                d="M97.726 5.3363H95.378V6.22744C94.592 5.56659 93.6758 5.20613 92.4642 5.20613C89.6606 5.20613 87.3376 7.47905 87.3376 10.2827C87.3376 13.0863 89.6406 15.3592 92.4442 15.3592C93.9411 15.3592 94.9124 14.6933 95.383 14.0725V15.229H97.726V5.3413V5.3363ZM92.5343 13.1063C90.9623 13.1063 89.6857 11.8296 89.6857 10.2576C89.6857 8.6856 90.9623 7.40896 92.5343 7.40896C94.1063 7.40896 95.383 8.6856 95.383 10.2576C95.383 11.8296 94.1063 13.1063 92.5343 13.1063Z"
                fill={color}
              />
              <path
                d="M115.168 5.3363H112.82V6.22744C112.034 5.56659 111.118 5.20613 109.907 5.20613C107.103 5.20613 104.78 7.47905 104.78 10.2827C104.78 13.0863 107.083 15.3592 109.887 15.3592C111.384 15.3592 112.355 14.6933 112.825 14.0725V15.229H115.168V5.3413V5.3363ZM109.977 13.1063C108.405 13.1063 107.128 11.8296 107.128 10.2576C107.128 8.6856 108.405 7.40896 109.977 7.40896C111.549 7.40896 112.825 8.6856 112.825 10.2576C112.825 11.8296 111.549 13.1063 109.977 13.1063Z"
                fill={color}
              />
              <path
                d="M14.0736 5.20125C11.27 5.20125 8.99707 7.47417 8.99707 10.2778C8.99707 13.0814 11.27 15.3543 14.0736 15.3543C16.8772 15.3543 19.1501 13.0814 19.1501 10.2778C19.1501 7.47417 16.8772 5.20125 14.0736 5.20125ZM14.0736 13.0163C12.5617 13.0163 11.3351 11.7897 11.3351 10.2778C11.3351 8.76583 12.5617 7.53925 14.0736 7.53925C15.5855 7.53925 16.8121 8.76583 16.8121 10.2778C16.8121 11.7897 15.5855 13.0163 14.0736 13.0163Z"
                fill={color}
              />
              <path
                d="M38.3698 15.2233V9.63111C38.3698 8.75499 37.9592 7.51339 36.4473 7.42328C34.1844 7.29311 33.9641 9.29568 33.9641 10.1868V15.2233H31.6211V5.33559H33.9591V6.55716C34.625 5.67103 35.5311 5.20543 36.7877 5.20543C36.7877 5.20543 40.7128 5.09529 40.7128 9.30069V15.2183H38.3698V15.2233Z"
                fill={color}
              />
              <path
                d="M83.5477 15.2233V9.63111C83.5477 8.75499 83.1372 7.51339 81.6253 7.42328C79.3624 7.29311 79.1421 9.29568 79.1421 10.1868V15.2233H76.7991V5.33559H79.1371V6.55716C79.8029 5.67103 80.7091 5.20543 81.9657 5.20543C81.9657 5.20543 85.8907 5.09529 85.8907 9.30069V15.2183H83.5477V15.2233Z"
                fill={color}
              />
              <path
                d="M49.9095 10.0771C49.9095 11.579 48.693 12.7956 47.1911 12.7956C45.6891 12.7956 44.4726 11.579 44.4726 10.0771C44.4726 8.57515 45.6891 7.35859 47.1911 7.35859C48.693 7.35859 49.9095 8.57515 49.9095 10.0771ZM45.2185 15.8795H42.6402C42.6402 15.8795 43.3812 19.349 47.4063 19.349C50.5654 19.349 52.2425 17.4916 52.2425 14.4577V5.33599H49.9045V6.18708C49.2086 5.60133 48.4076 5.26089 47.3713 5.21083C45.0183 5.09067 42.8655 6.57258 42.2948 8.86052C41.4887 12.0997 43.9569 14.9984 47.0659 14.9984C48.5128 14.9984 49.449 14.3526 49.9045 13.7518C49.9045 13.7518 50.4553 16.6956 48.2574 17.2913C47.0609 17.6168 45.7041 17.2012 45.2135 15.8845L45.2185 15.8795Z"
                fill={color}
              />
              <path
                d="M61.4395 10.0771C61.4395 11.579 60.2229 12.7956 58.721 12.7956C57.219 12.7956 56.0025 11.579 56.0025 10.0771C56.0025 8.57515 57.219 7.35859 58.721 7.35859C60.2229 7.35859 61.4395 8.57515 61.4395 10.0771ZM56.7484 15.8795H54.1701C54.1701 15.8795 54.9111 19.349 58.9362 19.349C62.0953 19.349 63.7725 17.4916 63.7725 14.4577V5.33599H61.4345V6.18708C60.7386 5.60133 59.9375 5.26089 58.9012 5.21083C56.5482 5.09067 54.3954 6.57258 53.8247 8.86052C53.0186 12.0997 55.4868 14.9984 58.5958 14.9984C60.0427 14.9984 60.9789 14.3526 61.4345 13.7518C61.4345 13.7518 61.9852 16.6956 59.7873 17.2913C58.5908 17.6168 57.2341 17.2012 56.7434 15.8845L56.7484 15.8795Z"
                fill={color}
              />
              <path
                d="M5.22219 0.669998V9.73164C5.22219 11.4689 3.81538 12.8757 2.07814 12.8757H0.831543V15.2187H2.14323C5.13708 15.2187 7.5652 12.7906 7.5652 9.79673V0.669998H5.22219Z"
                fill={color}
              />
              <path
                d="M5.22219 0.669998V9.73164C5.22219 11.4689 3.81538 12.8757 2.07814 12.8757H0.831543V15.2187H2.14323C5.13708 15.2187 7.5652 12.7906 7.5652 9.79673V0.669998H5.22219Z"
                fill={color}
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_2912_74886">
              <rect width={size || 116} height="21" fill="white" />
            </clipPath>
            <clipPath id="clip1_2912_74886">
              <rect
                width={size || 116}
                height="19.3333"
                fill="white"
                transform="translate(0 0.669998)"
              />
            </clipPath>
          </defs>
        </svg>
      );

    default:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 103 23"
          width={size || 103}
        >
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill={color}
              d="M6.37,5.22c-1.64,0-2.97,1.33-2.97,2.97c0,1.64,1.33,2.97,2.97,2.97c1.64,0,2.97-1.33,2.97-2.97
          C9.35,6.55,8.01,5.22,6.37,5.22 M6.37,9.68c-0.82,0-1.49-0.67-1.49-1.49c0-0.82,0.67-1.49,1.49-1.49c0.82,0,1.49,0.67,1.49,1.49
          C7.86,9.01,7.19,9.68,6.37,9.68"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill={color}
              d="M18.27,1.14H3.4C1.52,1.14,0,2.67,0,4.54v7.3c0,1.87,1.52,3.4,3.4,3.4h5.48c0.02,0,0.04,0.01,0.05,0.02
          l2.81,2.81c0.18,0.18,0.41,0.27,0.65,0.27c0.12,0,0.24-0.02,0.35-0.07c0.34-0.14,0.57-0.48,0.57-0.85v-2.18h4.97
          c1.87,0,3.4-1.52,3.4-3.4v-7.3C21.67,2.67,20.15,1.14,18.27,1.14z M19.83,11.84c0,0.86-0.7,1.56-1.56,1.56h-5.89
          c-0.51,0-0.92,0.41-0.92,0.92v0.88l-1.24-1.24C9.87,13.6,9.39,13.4,8.88,13.4H3.4c-0.86,0-1.56-0.7-1.56-1.56v-7.3
          c0-0.86,0.7-1.56,1.56-1.56h14.88c0.86,0,1.56,0.7,1.56,1.56V11.84z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill={color}
              d="M17.53,7.45h-1.49V5.96c0-0.41-0.33-0.74-0.74-0.74c-0.41,0-0.74,0.33-0.74,0.74v1.49h-1.49
          c-0.41,0-0.74,0.33-0.74,0.74c0,0.41,0.33,0.74,0.74,0.74h1.49v1.49c0,0.41,0.33,0.74,0.74,0.74c0.41,0,0.74-0.33,0.74-0.74V8.94
          h1.49c0.41,0,0.74-0.33,0.74-0.74C18.27,7.78,17.94,7.45,17.53,7.45"
            />
          </g>

          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill={color}
              d="M44.98,10.57v-2.7H29.61c-1.62,0-2.29-0.48-3.18-1.56l-2.09,1.72c1.26,1.53,2.64,2.54,5.27,2.54v0h5.12v0.91
          c-2.15,0.74-3.7,2.79-3.7,5.19c0,3.03,2.46,5.49,5.49,5.49c3.03,0,5.49-2.46,5.49-5.49c0-2.43-1.59-4.5-3.79-5.22v-0.88H44.98z
          M36.52,19.06c-1.32,0-2.4-1.07-2.4-2.4s1.07-2.39,2.4-2.39s2.4,1.07,2.4,2.39S37.84,19.06,36.52,19.06z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill={color}
              d="M33.13,4.08h-3.68v2.7h3.68c1.51,0,2.6-0.48,3.34-1.15c0.75,0.68,1.83,1.15,3.34,1.15h3.74v-2.7h-3.74
          c-1.6,0-1.94-0.83-1.98-1.38h6.28V0H28.89v2.7h6.23C35.1,3.05,34.89,4.08,33.13,4.08"
            />
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill={color}
              points="57.41,3.85 57.41,10.3 60.9,10.3 60.9,1.14 57.41,1.14 57.41,1.15 46.81,1.15 46.81,3.85 	"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill={color}
              d="M54.92,9.58h-3.49v3.66h-2.5c-1.62,0-2.29-0.48-3.18-1.56l-2.09,1.72c1.26,1.53,2.63,2.54,5.26,2.54v0h13.38
          v-2.71h-7.39V9.58z"
            />
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill={color}
              points="67.43,1.14 63.93,1.14 63.93,12.53 63.93,15.23 65.24,15.23 73.09,15.23 73.8,15.23 73.8,12.53 
          67.43,12.53"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill={color}
              d="M78.7,1.14H75.2v12.4c0,1.37-0.28,1.81-0.7,2.36l3.15,1.22c0.85-1.17,1.05-1.91,1.05-3.58V8.9h2.82V6.2H78.7
          V1.14z"
            />
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill={color}
              points="89.03,1.15 82.66,1.15 82.66,3.85 89.03,3.85 89.03,6.2 82.66,6.2 82.66,8.9 82.66,8.9 82.66,12.53 
          82.66,13.13 82.66,15.23 92.52,15.23 92.52,12.53 86.16,12.53 86.16,8.9 89.03,8.9 89.86,8.9 91.82,8.9 92.52,8.9 92.52,3.85 
          92.52,1.15 	"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill={color}
              d="M100.22,4.64c-0.81,0.99-1.45,1.47-2.79,1.55V1.14h-3.49v12.4c0,1.37-0.28,1.81-0.7,2.36l3.15,1.22
          c0.85-1.17,1.05-1.91,1.05-3.58V8.89c2.38-0.1,3.68-1.08,4.87-2.53L100.22,4.64z"
            />
          </g>
        </svg>
      );
  }
}

export default Logo;
