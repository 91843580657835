import { useRecoilValue } from 'recoil';

import { css } from '@emotion/react';

import atomMaintenanceData from '@recoils/init/maintenance';

import Loading from '@components/common/Loading/Loading';

import { dayString, isSameDate } from '@lib/utils/time';

const Maintenance = (): JSX.Element => {
  const maintenanceData = useRecoilValue(atomMaintenanceData);

  if (!maintenanceData) return <Loading />;

  const endDateTime = dayString(
    isSameDate(maintenanceData.startDateTime, maintenanceData.endDateTime)
      ? 'HH:mm'
      : 'YYYY.MM.DD(ddd) HH:mm',
    maintenanceData.endDateTime,
  );

  const startDateTime = dayString(
    'YYYY.MM.DD(ddd) HH:mm',
    maintenanceData.startDateTime,
  );

  return (
    <div css={maintenance}>
      <header className="header">
        <img src={maintenanceData.ImgUrl} alt="중고나라 점검 안내" />
        <h1>시스템 점검 안내</h1>
        <p>
          현재보다 안정적인 서비스 제공을 위하여 시스템 점검을 진행하고
          있습니다.
        </p>
      </header>
      <main className="main">
        <section>
          <h2 hidden id="a11y-markup-headline">
            점검 일시
          </h2>
          <div className="sectionName">점검 일시</div>
          <div className="text">
            <span>{startDateTime}</span>
            <span> ~ {endDateTime}</span>
          </div>
          <p className="etc">
            * 작업 일정은 시스템 작업 상황에 따라 변동될 수 있습니다.
          </p>
        </section>
        <section>
          <h2 hidden id="a11y-markup-headline">
            점검 내용
          </h2>
          <div className="sectionName">점검 내용</div>
          <p className="text">{maintenanceData.message}</p>
        </section>
      </main>
    </div>
  );
};

const maintenance = css`
  background-color: #fff;
  width: 100vw;
  min-height: 100vh;

  .header {
    min-height: 314px;
    background-color: hsl(18, 100%, 59%);
    padding: 36px 30px 50px;
    text-align: center;

    img {
      width: 163px;
      height: 124px;
    }

    h1 {
      font-size: 22px;
      line-height: 20px;
      color: #ffffff;
      font-weight: 500;
      margin-top: 30px;
      margin-bottom: 14px;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      color: #ffffff;
      word-break: keep-all;
    }
  }

  .main {
    text-align: center;
    padding: 20px 0 50px;

    section {
      padding: 30px 20px 0;
    }

    .sectionName {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      line-height: 20px;
      color: #333333;
      font-weight: 600;
    }

    .text {
      font-size: 15px;
      line-height: 20px;
      color: #333333;
      margin: 11px 0 6px;
      word-break: keep-all;
    }

    .etc {
      font-size: 13px;
      line-height: 18px;
      color: #9b9b9b;
      word-break: keep-all;
    }
  }
`;

export default Maintenance;
