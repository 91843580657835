const getQueries = (url: string) => {
  const params: { [key: string]: string } = {};
  let searchStr;

  try {
    searchStr = new URL(url).search;
  } catch (e) {
    return params;
  }

  if (searchStr) {
    const urlSearchParams = new URLSearchParams(searchStr);
    urlSearchParams.forEach((value, key) => {
      params[key] = value;
    });
  }

  return Object.freeze(params);
};

export default getQueries;
