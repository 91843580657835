import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import { DEFAULT_SEARCH_QUANTITY } from '@type/web/event';
import { CommentItemType, PageType } from '@type/webView/comment';

import { getCommentList } from '@api/comment/comment';

import Button from '@components/common/Button';
import Icon from '@components/common/Icon';

import { palette } from '@lib/styles/palette';

export interface UseInfiniteQueryWithScrollParamsTypes {
  type: PageType;
  typeSeq: number;
}

interface UseInfiniteQueryWithScrollReturnTypes {
  data: {
    commentList: CommentItemType[] | undefined;
    commentCount: number | undefined;
  };
  error: string | undefined | unknown;
  isFetching: boolean;
  ObservationComponent: () => ReactElement;
  refetchCommentList: () => void;
}

/**
 * 사용 기술
 * Recat query: useInfiniteQuery (https://react-query.tanstack.com/guides/infinite-queries)
 * react-intersection-observer: useInView
 */
export default function useCommentInfiniteQueryWithScroll({
  type,
  typeSeq,
}: UseInfiniteQueryWithScrollParamsTypes): UseInfiniteQueryWithScrollReturnTypes {
  const [commentList, setCommentList] = useState<CommentItemType[] | undefined>(
    undefined,
  );
  const [commentCount, setCommentCount] = useState<number | undefined>(0);
  const moreRef = useRef<HTMLButtonElement>(null);
  const getCommentListWithPageInfo = async ({ pageParam = 0 }) => {
    const { data } = await getCommentList({
      type,
      typeSeq,
      startIndex: pageParam,
    });

    const nextPage =
      data?.commentList.length >= DEFAULT_SEARCH_QUANTITY
        ? pageParam + 1
        : undefined;

    return {
      result: data,
      nextPage,
      isLast: !nextPage,
    };
  };

  const { data, error, isFetching, fetchNextPage, refetch } = useInfiniteQuery(
    ['commentList', type, typeSeq],
    getCommentListWithPageInfo,
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.isLast) {
          const moreButton = moreRef.current;
          if (moreButton?.style) moreButton.style.display = 'none';
        }

        return lastPage.nextPage;
      },
    },
  );

  useEffect(() => {
    const init: CommentItemType[] = [];
    const dataList = data?.pages?.reduce((acc, { result }) => {
      return [...acc, ...result.commentList];
    }, init);
    setCommentList(dataList);
    const result = data?.pages[0]?.result;
    setCommentCount(result?.commentCount);
  }, [data]);

  const handleOnclickMoreBtn = useCallback(() => {
    fetchNextPage();
  }, []);

  const ObservationComponent = (): ReactElement => {
    return (
      <Button
        type="light"
        onClick={handleOnclickMoreBtn}
        fullWidth={true}
        ref={moreRef}
        color={palette.gray6}
      >
        더보기
        <Icon icon="arrowDown" size={13} stroke={palette.gray6} />
      </Button>
    );
  };

  const refetchCommentList = () => {
    refetch({ refetchPage: () => true });
  };

  return {
    data: {
      commentList,
      commentCount,
    },
    error,
    isFetching,
    ObservationComponent,
    refetchCommentList,
  };
}
