import {
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Link } from 'react-router-dom';

import { css } from '@emotion/react';

import useUserInfo from '@hook/useUserInfo';

import {
  CommentItemType,
  CommentStatusType,
  PageType,
} from '@type/webView/comment';

import Image from '@components/common/Image';

import a11yHidden from '@lib/styles/a11yHidden';
import { palette } from '@lib/styles/palette';
import { getElapsedTime } from '@lib/utils/parser';
import { isAppDevice } from '@lib/utils/webview';

import ReplyCreateRouteButton from './ReplyCreateRouteButton';

interface CommentItemPropsType {
  commentData: CommentItemType;
  setDimImageSrc: (imgUrl: string) => void;
  onShowPreviewImg: () => void;
  onShowCommentDelete: () => void;
  setDeleteCommentSeq: (commentSeq: number) => void;
  type: PageType;
  typeSeq: number;
  onShowAppDownloadModal: () => void;
}

export default function CommentItem({
  commentData,
  setDimImageSrc,
  onShowPreviewImg,
  onShowCommentDelete,
  setDeleteCommentSeq,
  type,
  typeSeq,
  onShowAppDownloadModal,
}: CommentItemPropsType): ReactElement {
  const {
    commentContent,
    commentStatus,
    commentSeq,
    groupSeq,
    imgUrl,
    nickName,
    parentNickName,
    parentYn,
    profileImg,
    storeSeq,
    updateDate,
  } = commentData;

  const handleDeleteComment = useCallback(() => {
    setDeleteCommentSeq(commentSeq);
    onShowCommentDelete();
  }, [commentSeq]);

  const { storeSeq: userStoreSeq } = useUserInfo();
  const [commentArray, setCommentArray] = useState(['']);

  useEffect(() => {
    setCommentArray(commentContent.split('\n'));
  }, [commentContent]);

  /** 부모 댓글인데 자식이 답글이 있는 경우 삭제되었습니다 노출 */
  if (commentStatus !== CommentStatusType.Show) return <DeletedComment />;

  return (
    <article
      css={commentContainerStyle}
      className={parentYn ? '' : 'child-reply'}
    >
      <CommentProfileLink
        storeSeq={storeSeq}
        nickName={nickName}
        profileImg={profileImg}
        onShowAppDownloadModal={onShowAppDownloadModal}
      />

      <div css={textContainerStyle}>
        <p className="user-name">{nickName}</p>

        {commentArray.map((comment, idx) => (
          <p key={comment}>
            {parentNickName && idx === 0 && (
              <span className="parent-nickname">{`@${parentNickName} `}</span>
            )}
            {comment}
          </p>
        ))}

        {imgUrl && (
          <ImageDimButton
            onClick={() => {
              setDimImageSrc(imgUrl);
              onShowPreviewImg();
            }}
            imgUrl={imgUrl}
          />
        )}

        <div className="time-and-reply">
          <time dateTime={updateDate}>{getElapsedTime(updateDate)}</time>
          <ReplyCreateRouteButton
            to={`/${type}/detail/${typeSeq}/reply/${commentSeq}/${groupSeq}/${storeSeq}?grandParentNickname=${parentNickName}`}
          />
          {userStoreSeq === storeSeq && (
            <>
              <span />
              <Link to={`/${type}/detail/${typeSeq}/comment/${commentSeq}`}>
                수정
              </Link>
              <span />
              <button onClick={handleDeleteComment}>삭제</button>
            </>
          )}
        </div>
      </div>
    </article>
  );
}

interface CommentProfileLinkPropsType {
  nickName: string;
  storeSeq: number;
  profileImg: string;
  onShowAppDownloadModal: () => void;
}

function CommentProfileLink({
  nickName,
  storeSeq,
  profileImg,
  onShowAppDownloadModal,
}: CommentProfileLinkPropsType): ReactElement {
  return (
    <div css={userProfileStyle}>
      <a
        href={`jnapps3://?storeseq=${storeSeq}`}
        onClick={(e: MouseEvent<HTMLAnchorElement>) => {
          if (isAppDevice()) return;
          e.preventDefault();
          onShowAppDownloadModal();
        }}
      >
        <p css={a11yHidden}>{nickName} 상점으로 이동</p>
        <Image
          alt={`${nickName} profile`}
          src={profileImg}
          aria-hidden={true}
        />
      </a>
    </div>
  );
}

function DeletedComment(): ReactElement {
  return (
    <div
      css={css`
        width: 100%;
        height: 3.5rem;
        background-color: ${palette.gray10};
        margin-bottom: 1.5rem;
        color: ${palette.gray5};
        padding: 1.5rem;
        border-radius: 5%;
      `}
    >
      댓글이 삭제되었습니다.
    </div>
  );
}

interface ImageDimButtonPropsType {
  onClick: () => void;
  imgUrl: string;
}

function ImageDimButton({
  onClick,
  imgUrl,
}: ImageDimButtonPropsType): ReactElement {
  return (
    <button className="uploadedImg" onClick={onClick}>
      <p css={a11yHidden}>이미지 자세히 보기</p>
      <Image src={imgUrl} alt="미리보기" />
    </button>
  );
}

const commentContainerStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
`;

const userProfileStyle = css`
  width: 2.5rem;
  border-radius: 20%;
  overflow: hidden;
  margin-right: 0.6rem;
`;

const textContainerStyle = css`
  width: 100%;

  .user-name {
    font-weight: 700;
  }
  p {
    margin-bottom: 0.4rem;
    word-break: break-all;
  }
  .uploadedImg {
    width: 50%;
    background-color: transparent;
    margin: 0.4rem 0;
  }
  .time-and-reply {
    font-size: 0.9rem;
    time {
      color: ${palette.gray12};
      margin-right: 0.8rem;
    }
    button {
      color: ${palette.jnGreen_text};
      background-color: transparent;
    }
    a {
      color: ${palette.gray700};
    }
    span::after {
      content: '';
      display: inline-block;
      position: relative;
      width: 2px;
      height: 2px;
      background-color: ${palette.jnGreen_text};
      border-radius: 50%;
      bottom: 0.2rem;
      margin: 0 0.3rem;
    }
  }
  .parent-nickname {
    color: ${palette.blue6};
  }
`;
