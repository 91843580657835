/* eslint-disable import/prefer-default-export */
import {
  GetDeliveryInfoResponse,
  OrderUnicroParamType,
  UnicroSellerDeliveryResType,
} from '@type/api/base/order';
import { AddressDataType } from '@type/api/main/address';
import {
  OrderReviewDetailType,
  OrderBidirectionReviewType,
  StoreOrderReviewInfoType,
  OrderReviewListType,
} from '@type/api/main/orderHistory';
import { ApiResponse } from '@type/web/api';
import { ReviewerType } from '@type/webView/order';

import { axiosRequest, AxiosDomain } from '@api/config';

import { deliveryCorpCode as deliveryCorpCodeList } from '@lib/constants/orderPaymentDelivery';
import swapKeyValueOfObj from '@lib/utils/swapKeyValueOfObj';

const { mainBase: axiosMainBase, delivery: axiosDelivery } = AxiosDomain;

/**
 * 거래내역 버튼 : 반품확인
 * @param {string | number} orderNo - 주문번호
 */
export const checkValidDeliveryInfo = (
  param: OrderUnicroParamType,
  type: 'sale' | 'buy' | undefined,
  code: string,
): Promise<ApiResponse<string>> => {
  const path = `/order/unicro/seller/delivery/valid`;
  const newParam = { ...param };

  const corpCodeList = swapKeyValueOfObj(deliveryCorpCodeList);
  const corpCodeName = param.deliveryCorpName;
  const deliveryCorpCode = (corpCodeName && corpCodeList[corpCodeName]) ?? code;
  const deliveryType = param.deliveryType === 'prepaid' ? '1' : '2';

  if (type === 'sale') {
    delete newParam.sellerAccountSeq;
  }

  return axiosRequest<string>(axiosMainBase, 'post', path, {
    ...newParam,
    deliveryCorpCode,
    code,
    deliveryType,
  });
};

/** TODO. 이관 후 삭제 예정  */

// 판매자 배송등록
export function postSellerDelivery(
  param: OrderUnicroParamType,
): Promise<ApiResponse<string>> {
  const path = '/order/unicro/seller/delivery';

  const corpCodeList = swapKeyValueOfObj(deliveryCorpCodeList);
  const corpCodeName = param.deliveryCorpName;
  const deliveryCorpCode = corpCodeName && corpCodeList[corpCodeName];
  const deliveryType = param.deliveryType === 'prepaid' ? 1 : 2;

  return axiosRequest(axiosMainBase, 'post', path, {
    ...param,
    deliveryCorpCode,
    deliveryType,
  });
}

// 판매자 배송수정
export function putSellerDelivery(
  param: OrderUnicroParamType,
): Promise<ApiResponse<string>> {
  const path = '/order/unicro/seller/delivery';

  const corpCodeList = swapKeyValueOfObj(deliveryCorpCodeList);
  const corpCodeName = param.deliveryCorpName;
  const deliveryCorpCode = corpCodeName && corpCodeList[corpCodeName];
  const deliveryType = param.deliveryType === 'prepaid' ? 1 : 2;

  return axiosRequest(axiosMainBase, 'put', path, {
    ...param,
    deliveryCorpCode,
    deliveryType,
  });
}

// 구매자 반품 배송 등록
export function postBuyerReturnDelivery(
  param: OrderUnicroParamType,
): Promise<ApiResponse<string>> {
  const path = '/order/unicro/buyer/return/delivery';

  const corpCodeList = swapKeyValueOfObj(deliveryCorpCodeList);
  const corpCodeName = param.deliveryCorpName;
  const deliveryCorpCode = corpCodeName && corpCodeList[corpCodeName];
  const deliveryType = param.deliveryType === 'prepaid' ? 1 : 2;

  return axiosRequest(axiosMainBase, 'post', path, {
    ...param,
    deliveryCorpCode,
    deliveryType,
  });
}

// 구매자 반품 배송 수정
export function putBuyerReturnDelivery(
  param: OrderUnicroParamType,
): Promise<ApiResponse<string>> {
  const path = '/order/unicro/buyer/return/delivery';

  const corpCodeList = swapKeyValueOfObj(deliveryCorpCodeList);
  const corpCodeName = param.deliveryCorpName;
  const deliveryCorpCode = corpCodeName && corpCodeList[corpCodeName];
  const deliveryType = param.deliveryType === 'prepaid' ? 1 : 2;

  return axiosRequest(axiosMainBase, 'put', path, {
    ...param,
    deliveryCorpCode,
    deliveryType,
  });
}

// 안전거래 판매자 배송정보수정 페이지 정보
export function getSellerDelivery(
  orderNo: string,
): Promise<ApiResponse<UnicroSellerDeliveryResType>> {
  const path = `/order/unicro/seller/delivery/${orderNo}`;

  return axiosRequest<UnicroSellerDeliveryResType>(axiosMainBase, 'get', path);
}

// 안전거래 구매자 배송정보수정 페이지 정보
export function getBuyerDelivery(
  orderNo: string,
): Promise<ApiResponse<UnicroSellerDeliveryResType>> {
  const path = `/order/unicro/buyer/return/delivery/${orderNo}`;

  return axiosRequest(axiosMainBase, 'get', path);
}

/** ------------------------------------------------------------------------- */

export const getDeliveryInfo = (
  deliverySeq: string,
): Promise<ApiResponse<GetDeliveryInfoResponse>> => {
  const path = `/delivery/${deliverySeq}`;

  return axiosRequest(axiosDelivery, 'get', path);
};

// 판매자 반품 수락
/** orderNo, userDeliverySeq payload 프로퍼티 이름 명시 */
export const postReturnAccept = (
  orderNo: string,
  deliverySeq: number,
): Promise<ApiResponse<UnicroSellerDeliveryResType>> => {
  // const path = `/order/unicro/seller/return/accept`;
  const path = `/delivery/return/accept`;
  const body = { orderNo, deliverySeq };

  return axiosRequest(axiosDelivery, 'post', path, body);
};
/**
 * 상품 후기 작성
 * @param {string} orderNo
 * @param {object} params
 */
export function postOrderReview(
  orderNo: string,
  params: {
    sellerFeedback: number;
    replySpeedFeedback: number;
    descriptionMatchFeedback: number;
    satisfactionFeedback: number;
    productReviewContent: string;
  },
): Promise<ApiResponse> {
  const path = `/review/${orderNo}`;

  return axiosRequest(axiosMainBase, 'post', path, params);
}

/**
 * 상품 후기 셀러 닉네임
 * @param {string} orderNo
 */
export function getReviewNickName(
  orderNo: string,
): Promise<ApiResponse<string>> {
  const path = `/review/nickname/${orderNo}`;

  return axiosRequest(axiosMainBase, 'get', path);
}

/**
 * 상품 후기 페이지 정보
 * @param {string} orderNo
 */
export function getReviewInfo(
  orderNo: string,
): Promise<ApiResponse<OrderBidirectionReviewType>> {
  const path = `/review/info/${orderNo}`;

  return axiosRequest(axiosMainBase, 'get', path);
}
// sellerFeedback - 매너평가
// replySpeedFeedback - 채팅평가
// descriptionMatchFeedback - 상품평가
// satisfactionFeedback - 거래평가(무료나눔, 택배거래, 직거래)
// isNoNextDealFeedback - 상대방 차단 항목
export function postTargetOrderReview(
  orderNo: string,
  params: {
    cvsReviewRequest?: {
      feedback: number;
      orderNo: string;
      reviewContent: string;
      shopCode: string;
      target: ReviewerType;
    };
    reviewInputVO: {
      descriptionMatchFeedback: number;
      isNoNextDealFeedback: boolean | number;
      productReviewContent: string;
      replySpeedFeedback: number;
      satisfactionFeedback: number;
      sellerFeedback: number;
      target: ReviewerType;
    };
  },
): Promise<ApiResponse> {
  const path = `/review/target/${orderNo}`;

  return axiosRequest(axiosMainBase, 'post', path, params);
}

/**
 * 보낸/받은 후기 조회
 * @param {string} orderNo
 * @param {string} reviewer
 */
export function getReviewDetailInfo(
  orderNo: string,
  reviewer: string,
): Promise<ApiResponse<OrderReviewDetailType>> {
  const path = `/review/${orderNo}/${reviewer}/detail`;

  return axiosRequest(axiosMainBase, 'get', path);
}

/**
 * 후기 작성 여부 체크
 * @param {string} orderNo
 * @param {string} reviewer
 */
export function getReviewCompleteStatus(
  orderNo: string,
  reviewer: string,
): Promise<ApiResponse<any>> {
  const path = `/review/check/${orderNo}/${reviewer}`;

  return axiosRequest(axiosMainBase, 'get', path);
}

/**
 * 상점 후기 조회
 * @param {string} storeSeq
 */
export function getUserStoreReview(
  storeSeq: string,
): Promise<ApiResponse<StoreOrderReviewInfoType>> {
  const path = `/review/${storeSeq}/deal`;

  return axiosRequest(axiosMainBase, 'get', path);
}

/**
 * 상점 모든 텍스트 후기 조회
 * @param {string} storeSeq
 * @param {string} reviewer
 */
export function getTotalStoreReview(
  storeSeq: string,
  reviewer: string,
  page = 0,
): Promise<ApiResponse<OrderReviewListType>> {
  const path = `/review/${storeSeq}/deal/review-contents/${reviewer}?page=${page}`;

  return axiosRequest(axiosMainBase, 'get', path);
}
