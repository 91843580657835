import { ReactElement } from 'react';

import { SerializedStyles } from '@emotion/react';

import Button from '@components/common/Button';

import { isFlutterApp, isMainWeb } from '@lib/utils/webview';

import {
  containerStyle,
  buttonGroupStyle,
  buttonStyle,
  containerFlutterStyle,
  flutterButtonGroupStyle,
  flutterButtonStyle,
  flutterCancelBtnStyle,
  mainWebButtonGroupStyle,
} from './ConfirmModal.style';

interface ComfirmModalPropsType {
  textLineList: string[] | undefined;
  children?: ReactElement;
  onHide: () => void;
  onClick?: () => void;
  isShowCancelButton?: boolean;
  isShowConfirmButton?: boolean;
  style?: SerializedStyles;
  cancelButtonText?: string;
  confirmButtonText?: string;
  hasFlutterStyle?: boolean;
}

function ConfirmModal({
  textLineList,
  children,
  onHide,
  onClick,
  isShowCancelButton = true,
  isShowConfirmButton = true,
  style,
  cancelButtonText = '취소',
  confirmButtonText = '확인',
  hasFlutterStyle = false,
}: ComfirmModalPropsType): ReactElement {
  const isFlutter = isFlutterApp();

  return (
    <div
      role="dialog"
      aria-labelledby="dialogTitle"
      aria-describedby="dialogContent"
      css={[containerFlutterStyle, style]}
    >
      {textLineList?.map((text) => (
        <span key={text} className="lineChange">
          {text}
        </span>
      ))}

      {children}

      <div
        css={[
          isMainWeb() && window.location.href.includes('order')
            ? mainWebButtonGroupStyle
            : flutterButtonGroupStyle,
        ]}
      >
        {isShowCancelButton && (
          <Button
            css={
              isFlutter || hasFlutterStyle ? flutterCancelBtnStyle : buttonStyle
            }
            type="link"
            onClick={onHide}
          >
            {cancelButtonText}
          </Button>
        )}

        {isShowConfirmButton && (
          <Button
            css={
              isFlutter || hasFlutterStyle ? flutterButtonStyle : buttonStyle
            }
            type="link"
            replace={true}
            onClick={() => {
              if (onClick) onClick();
              onHide();
            }}
          >
            {confirmButtonText}
          </Button>
        )}
      </div>
    </div>
  );
}

export default ConfirmModal;
