import { ApiResponse } from '@type/web/api';

import { axiosRequest, AxiosDomain } from '@api/config';

const axiosBase = AxiosDomain.mainBase;

/** 토큰 유효성 체크 */
export const tokenValid = (
  token: string,
  ignore = false,
): Promise<ApiResponse<boolean>> => {
  const path = `/common/token/valid`;
  const query = `?token=${token}${ignore ? `&ignore=Y` : ''}`;
  return axiosRequest<boolean>(axiosBase, 'get', path + query);
};

export default { tokenValid };
