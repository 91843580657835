import { ResponseComposition, RestRequest, RestContext } from 'msw';

type HandlerType = {
  (req: RestRequest, res: ResponseComposition, ctx: RestContext): any;
};

const voucherListHandler: HandlerType = (req, res, ctx) => {
  return res(
    ctx.status(200),
    ctx.json({
      data: {
        count: 2,
        tooltip: '출고 D-1',
        list: [
          {
            orderNo: '221115183320672s',
            shopCode: '50810',
            pickupStatus: 'FORWARDING',
            sellerStoreSeq: 3441263,
            buyerStoreSeq: 5797863,
            shopForwardPinNo: 'PICK2234534535417402',
            shopForwardExpireDate: '2022.11.18',
            returnFlag: false,
            shop: {
              shopCode: '10634',
              shopName: '서초17호점',
              lon: 127.0112761,
              lat: 37.4921628,
              operationStartTime: '00:00',
              operationEndTime: '23:59',
              holidayType: {
                code: '00',
                desc: '휴무일없음',
              },
              address: '서울 서초구 반포대로30길 48',
              addressDetail: '(서초동) 세븐일레븐 서초17호점',
              disabled: true,
            },
            product: {
              productSeq: 46951716,
              title: '픽업1',
              // mediaUrl: '/media/original/2022/11/15/1668504455825ITZ_YQyuV.jpg',
              mediaUrl: '/media/original/2023/06/19/1687158253875QOj_rurJH.mp4',
              perPrice: 10000,
            },
            pinNoDescriptions: [
              '지정된 편의점에서만 픽업이 가능합니다.',
              '픽업기한 내 상품을 픽업하지 않을 경우 자동으로 회수 진행중 상태로 변경됩니다.',
            ],
          },
          {
            orderNo: '221115183406447e',
            shopCode: '50810',
            pickupStatus: 'FORWARDING',
            sellerStoreSeq: 3441263,
            buyerStoreSeq: 5797863,
            shopForwardPinNo: 'PICK2234534535417402',
            shopForwardExpireDate: '2022.11.18',
            returnFlag: false,
            shop: {
              shopCode: '10634',
              shopName: '서초17호점',
              lon: 127.0112761,
              lat: 37.4921628,
              operationStartTime: '00:00',
              operationEndTime: '23:59',
              holidayType: {
                code: '00',
                desc: '휴무일없음',
              },
              address: '서울 서초구 반포대로30길 48',
              addressDetail: '(서초동) 세븐일레븐 서초17호점',
              disabled: true,
            },
            product: {
              productSeq: 46951717,
              title: '픽업2222',
              mediaUrl: '/media/original/2022/11/15/166850447688635o_RCuhQ.jpg',
              perPrice: 10000,
            },
            pinNoDescriptions: [
              '지정된 편의점에서만 픽업이 가능합니다.',
              '픽업기한 내 상품을 픽업하지 않을 경우 자동으로 회수 진행중 상태로 변경됩니다.',
            ],
          },
        ],
      },
      meta: {
        status: 'OK',
        message: '요청이 성공하였습니다.',
        code: 0,
      },
    }),
  );
};

const voucherHandler: HandlerType = (req, res, ctx) => {
  const { orderNo } = req.params;

  return res(
    ctx.status(200),
    ctx.json({
      data: {
        orderNo: '221115183406447e',
        shopCode: '50810',
        pickupStatus: 'FORWARDING',
        sellerStoreSeq: 3441263,
        buyerStoreSeq: 5797863,
        shopForwardPinNo: 'PICK2234534535417402',
        shopForwardExpireDate: '2022.11.18',
        returnFlag: false,
        shop: {
          shopCode: '10634',
          shopName: '서초17호점',
          lon: 127.0112761,
          lat: 37.4921628,
          operationStartTime: '00:00',
          operationEndTime: '23:59',
          holidayType: {
            code: '00',
            desc: '휴무일없음',
          },
          address: '서울 서초구 반포대로30길 48',
          addressDetail: '(서초동) 세븐일레븐 서초17호점',
          disabled: true,
        },
        product: {
          productSeq: 46951717,
          title: '픽업2222',
          mediaUrl: '/media/original/2022/11/15/166850447688635o_RCuhQ.jpg',
          perPrice: 10000,
        },
        pinNoDescriptions: [
          '지정된 편의점에서만 픽업이 가능합니다.',
          '픽업기한 내 상품을 픽업하지 않을 경우 자동으로 회수 진행중 상태로 변경됩니다.',
        ],
      },
      meta: {
        status: 'OK',
        message: '요청이 성공하였습니다.',
        code: 0,
      },
    }),
  );
};

export { voucherHandler, voucherListHandler };
