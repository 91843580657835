import { rest } from 'msw';

import { DELIVERY_API_URL } from '@lib/constants/const';

import { deliveryHistoryHandler } from './delivery';
import { voucherHandler, voucherListHandler } from './voucher';

const baseUrl = 'https://delivery-api-stg.joongna.com';

const handlers = [
  rest.get(`${baseUrl}/pickup/pin-no`, voucherListHandler),
  rest.get(`${baseUrl}/pickup/pin-no/:orderNo`, voucherHandler),
  rest.get(
    `${DELIVERY_API_URL}/delivery/tracking/ERR/1111111111`,
    deliveryHistoryHandler,
  ),
];

export default handlers;
