import { css } from '@emotion/react';

import a11yHidden from '@lib/styles/a11yHidden';
import { palette } from '@lib/styles/palette';

const formStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  margin: 20px 0;
  max-width: 768px;
  gap: 16px;
  flex: 1 0 auto;
  height: 83%;

  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > *:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

const documentStyle = css`
  justify-content: space-between;
  font-size: 14px;
  border-color: ${palette.gray2};
  color: ${palette.gray6};
  padding: 10px 20px;
  height: 44px;
  cursor: pointer;

  &:hover {
    color: ${palette.gray6};
    border-color: ${palette.gray2};
  }
  &:focus {
    border-color: ${palette.black10};
  }

  & svg,
  &:hover svg {
    stroke: ${palette.gray6};
  }
`;

const warningStyle = css`
  display: block;
  padding: 12px 16px;
  box-sizing: border-box;
  background-color: ${palette.gray10};
  height: auto;
  color: ${palette.gray5};
  font-weight: 400;
  line-height: 18px;
  font-size: 12px;
`;

const textAreaStyle = css`
  resize: none;
  border: 0;
  flex: 1 0 70%;
  font-size: 15px;
  line-height: 23px;
  padding: 2px;
  /* width: 100%; */

  &::placeholder {
    font-size: 15px;
    line-height: 23px;
    color: ${palette.gray5};
  }
`;

const buttonStyle = css`
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 14px;
  background-color: transparent;
  color: ${palette.gray700};
  z-index: 100;

  &:disabled {
    color: ${palette.gray4};
  }
`;

export {
  a11yHidden,
  formStyle,
  documentStyle,
  warningStyle,
  textAreaStyle,
  buttonStyle,
};
