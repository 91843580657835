import { useRecoilValue } from 'recoil';

import { userStateInfo } from '@recoils/user/atom';

import { sendGA4, sendUserActionLog } from '@lib/utils/ga';

export default function useGA() {
  const userLoginInfo = useRecoilValue(userStateInfo);

  // const sendGAData = (data: any) => {
  //   sendGoogleAnalytics(data);
  // };

  const sendUserActionGAData = (data: any) => {
    sendUserActionLog(data, userLoginInfo);
  };

  // TODO: UA 버전 정리되면 함수명 변경. (sendGA4Data -> sendGAData)
  const sendGA4Data = (data: { eventType: string; param?: any }) => {
    sendGA4(data);
  };

  const sendGaEvent = (props: {
    action: string;
    label: string;
    title: string;
    location: string;
    category: string;
  }) => {
    const data = {
      event: 'ga_event',
      ...props,
    };

    sendUserActionLog(data, userLoginInfo);
  };

  return {
    // sendGAData,
    sendUserActionGAData,
    sendGaEvent,
    sendGA4Data,
  };
}
