import { ReactElement, useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { throttle } from 'lodash';

import { css } from '@emotion/react';

import useGoBack from '@hook/useGoBack';

import {
  WikiHeaderOptions,
  WikiHeaderResponse,
} from '@type/webView/wikiHeader';

import Icon from '@components/common/Icon';
import Logo from '@components/common/Logo';

import { palette } from '@lib/styles/palette';

export function WikiHeader({ response }: WikiHeaderOptions): ReactElement {
  const goBack = useGoBack();
  const [isSetDivideLine, setIsSetDivideLine] = useState(false);
  const [isMultipleHeader, setIsMultipleHeader] = useState(false);
  const {
    leftBtnOption,
    rightBtnOptions = [],
    multipleOptions,
    divideLineHeightOption,
  } = response as WikiHeaderResponse;
  const leftBtnOnClick = (leftBtnOption && leftBtnOption.onClick) || goBack;

  const setDivideLine = useMemo(
    () =>
      throttle(() => {
        const isScroll = window.scrollY > (divideLineHeightOption || 0);
        if (isScroll !== isSetDivideLine) setIsSetDivideLine(isScroll);
      }, 300),
    [isSetDivideLine, divideLineHeightOption],
  );

  const setMultiple = useMemo(
    () =>
      throttle(() => {
        const isShowMultipleHeader = window.scrollY > 0;
        if (isShowMultipleHeader !== isMultipleHeader)
          setIsMultipleHeader(isShowMultipleHeader);
      }, 300),
    [isMultipleHeader],
  );

  useEffect(() => {
    window.addEventListener('scroll', setDivideLine);
    window.addEventListener('scroll', setMultiple);
    return () => {
      window.removeEventListener('scroll', setDivideLine);
      window.removeEventListener('scroll', setMultiple);
    };
  }, [isSetDivideLine, isMultipleHeader, setDivideLine, setMultiple]);

  return (
    <div css={headerContainerStyle(isSetDivideLine)}>
      <header>
        {leftBtnOption?.icon ? (
          <Icon
            icon={leftBtnOption.icon}
            stroke={leftBtnOption.stroke}
            onClick={
              leftBtnOption.onClick ? leftBtnOption.onClick : leftBtnOnClick
            }
            css={iconBtn}
            size={leftBtnOption.size ? leftBtnOption.size : '24px'}
          />
        ) : (
          <Link
            className="logo"
            to={leftBtnOption?.to ?? ''}
            onClick={leftBtnOption?.onClick}
          >
            <Logo logoType="ONLY_ICON" color={leftBtnOption?.stroke ?? ''} />
          </Link>
        )}
        {response?.title && (
          <h1 className="center" css={title}>
            {response.title}
          </h1>
        )}
        {rightBtnOptions.length > 0 && (
          <div css={sortedBtn}>
            {rightBtnOptions.map((button) => {
              const { to, icon, stroke, onClick, size } = button;
              return (
                <Link to={to || ''} key={icon} onClick={onClick}>
                  <Icon
                    icon={icon || 'search'}
                    stroke={stroke || palette.white}
                    // onClick={onClick}
                    css={iconBtn}
                    size={size}
                  />
                </Link>
              );
            })}
          </div>
        )}
      </header>
      {multipleOptions && isMultipleHeader && (
        <div css={multipleOptions.css || multipleDefaultStyle}>
          <p>{multipleOptions.title}</p>
        </div>
      )}
    </div>
  );
}

const headerContainerStyle = (isSetDivideLine: boolean) => css`
  padding: 0 20px;
  border-bottom: ${isSetDivideLine ? `1px solid ${palette.gray0}` : 'none'};
  header {
    width: 100%;
    height: 44px;
    box-sizing: content-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    text-align: center;
    .center {
      width: calc(100% - 100px);
      text-align: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      line-height: normal;
    }
  }
`;

const title = css`
  max-width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const iconBtn = css`
  cursor: pointer;
`;

const sortedBtn = css`
  display: flex;
  justify-content: center;
  align-items: center;
  > a:not(:last-of-type) {
    margin-right: 15px;
  }
`;

const multipleDefaultStyle = css`
  display: flex;
  justify-content: center;
  text-align: center;
`;

export default WikiHeader;
