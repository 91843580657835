import { useEffect, useRef } from 'react';

import Lottie from 'lottie-web';

import { css } from '@emotion/react';

import ButtonLoadingLottieFile from './jn_button_loading.json';

function ButtonLoading(): JSX.Element {
  const loadingRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (loadingRef.current) {
      Lottie.loadAnimation({
        container: loadingRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: ButtonLoadingLottieFile,
      });
    }
  }, []);

  return <div css={buttonLoadingStyle} ref={loadingRef} />;
}

const buttonLoadingStyle = css`
  width: 100%;
  height: 100%;
`;

export default ButtonLoading;
