import { ReactElement, useEffect, useRef, Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import webViewRoutes from '@routes/route.webView';

import useAuth from '@hook/useAuth';
import useHistoryHook from '@hook/useHistory';
import useInit from '@hook/useInit';
import useKeyboardVisibleEventInit from '@hook/useKeyboardVisibleEventInit';

import atomMaintenanceData from '@recoils/init/maintenance';
import { userStateInfo } from '@recoils/user/atom';

import Loading from '@components/common/Loading/Loading';
import Alert from '@components/common/PopUp/Alert';
import Toast from '@components/common/PopUp/Toast';

import {
  ENV,
  SELLER_ADMIN_URL,
  SELLER_URL,
  SHARE_URL,
} from '@lib/constants/const';
import { getOsTypeByUserAgent, isAppDevice } from '@lib/utils/webview';

import Routes from '@pages/Routes';
import MaintenancePage from '@pages/webView/Maintenance';

import './appsflyer-onlink-smart-script';

/*
 * App.tsx
 - inti api 호출/데이터 세팅
 - 로그인 도입시 토큰 유효성 및 유저 정보 관련 api 호출/데이터 세팅
 */

function App(): ReactElement {
  const isApp = useRef(true);
  const userInfo = useRecoilValue(userStateInfo);
  const maintenanceData = useRecoilValue(atomMaintenanceData);

  const {
    location: { pathname },
  } = useHistoryHook();

  useInit();
  useKeyboardVisibleEventInit();

  const { isLoading } = useAuth();

  useEffect(() => {
    console.log(
      `%c 
      /$$$$$  /$$$$$$    /$$$$$$   /$$    /$$  /$$$$$$   /$$$$$$   /$$    /$$  /$$$$$$  /$$$$$$$   /$$$$$$ 
     |__  $$ /$$__  $$  /$$__  $$ | $$$  | $$ /$$__  $$ /$$__   $$| $$$  | $$ /$$__  $$| $$__  $$ /$$__  $$
        | $$| $$   \\ $$| $$  \\  $$| $$$$ | $$| $$  \\__/| $$  \\  $$| $$$$ | $$| $$  \\ $$| $$  \\ $$| $$  \\ $$
        | $$| $$   | $$| $$   | $$| $$  $$ $$| $$ /$$$$| $$   | $$| $$ $$  $$| $$$$$$$$| $$$$$$$/| $$$$$$$$
  /$$   | $$| $$   | $$| $$   | $$| $$   $$$$| $$|_  $$| $$   | $$| $$   $$$$| $$__  $$| $$__  $$| $$__  $$
  | $$  | $$| $$   | $$| $$   | $$| $$  \\ $$$| $$  \\ $$| $$   | $$| $$ \\  $$$| $$  | $$| $$  \\ $$| $$  | $$
  |  $$$$$$/|   $$$$$$/| $$$$$$ / | $$   \\ $$|  $$$$$$/|  $$$$$$ /| $$  \\  $$| $$  | $$| $$  | $$| $$  | $$
   \\______/  \\______/   \\______/  |__/    \\__/ \\______/ \\______/  |__/   \\__/|__/  |__/|__/  |__/|__/  |__/
                                                                                                    
                                                                                                    
                                                                                                    
  `,
      'color:orange',
      '평화로운 중고나라 지원하기 >>> https://jn-careers.notion.site/05e979832ee249989f3810af08271744',
    );
  }, []);

  if (
    ENV !== 'localhost' &&
    ((isAppDevice() && getOsTypeByUserAgent() === null) ||
      (!isAppDevice() &&
        window.document.referrer !== 'http://localhost:3000/' &&
        !window.document.referrer.includes(`${SHARE_URL}`) &&
        !window.document.referrer.includes(`${SELLER_URL}`) &&
        !window.document.referrer.includes(`${SELLER_ADMIN_URL}`)))
  ) {
    isApp.current = false;
    window.location.href = SHARE_URL ?? 'https://web.joongna.com';
  }

  if (isLoading) return <Loading />;

  if (maintenanceData)
    return (
      <Suspense fallback={<Loading />}>
        <MaintenancePage />
      </Suspense>
    );

  return (
    <>
      <Suspense fallback={<div />}>
        <Switch>
          {[...webViewRoutes].map((route: any) => {
            return <Routes key={route.path} {...route} />;
          })}
          <Redirect to="/404" />
        </Switch>
      </Suspense>
      <Alert />
      <Toast />
    </>
  );
}

export default App;
