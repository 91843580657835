import { css } from '@emotion/react';

import a11yHidden from '@lib/styles/a11yHidden';
import { palette } from '@lib/styles/palette';

const containerStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 85vw;
  max-width: 315px;
  min-width: 250px;
  height: auto;
  padding: 26px 0 0 0;
  box-sizing: border-box;
  background-color: ${palette.white};
  line-height: 20px;
  border-radius: 5px;
  text-align: center;

  & span.lineChange {
    display: block;
  }
`;

const containerFlutterStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 85vw;
  max-width: 335px;
  min-width: 250px;
  height: auto;
  padding: 28px 20px 20px;
  box-sizing: border-box;
  background-color: ${palette.white};
  line-height: 22px;
  border-radius: 8px;
  text-align: center;

  & span.lineChange {
    display: block;
  }
`;

const buttonGroupStyle = css`
  display: flex;
  width: 100%;
  border-radius: 0 0 5px 5px;
  overflow: hidden;

  & > button:first-of-type {
    border-right: 1px solid ${palette.gray4};
  }

  & > button:hover {
    color: ${palette.white};
  }
`;

const flutterButtonGroupStyle = css`
  display: flex;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;

  & > button:first-of-type {
    margin-right: 7px;
  }
`;

const mainWebButtonGroupStyle = css`
  display: flex;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;

  & > button:first-of-type {
    margin-right: 7px;
  }

  & > button:last-of-type {
    background-color: black;
    color: white;
    border-radius: 4px;
  }
`;

const buttonStyle = css`
  width: 100%;
  margin-top: 26px;
  border: 0;
  border-radius: 0;
  border-top: 1px solid ${palette.gray4};
  padding: 14px 16px 18px;
  background: ${palette.white};
  font-size: min(4vw, 16px);
  color: ${palette.gray9};

  &:hover,
  :active,
  :disabled {
    color: ${palette.white};
  }
`;

const flutterCancelBtnStyle = css`
  width: 100%;
  margin-top: 26px;
  border: 1px solid ${palette.gray400};
  border-radius: 4px;
  padding: 12px 0;
  text-align: center;
  background-color: ${palette.white};
  color: ${palette.black10};
  font-size: min(4vw, 16px);

  &:hover,
  :active,
  :disabled {
    color: ${palette.white};
  }
  & ::selection {
    background-color: none;
    color: ${palette.gray5};
  }
`;

const flutterButtonStyle = css`
  width: 100%;
  margin-top: 26px;
  border: 0;
  border-radius: 4px;
  padding: 12px 0;
  background-color: ${palette.black};
  font-size: min(4vw, 16px);
  color: ${palette.white};

  &:hover,
  :active,
  :disabled {
    color: ${palette.white};
  }

  & ::selection {
    background-color: none;
    color: ${palette.gray5};
  }
`;

export {
  containerStyle,
  a11yHidden,
  buttonGroupStyle,
  flutterButtonGroupStyle,
  mainWebButtonGroupStyle,
  buttonStyle,
  flutterButtonStyle,
  flutterCancelBtnStyle,
  containerFlutterStyle,
};
