import { useEffect, useLayoutEffect } from 'react';

import { useSetRecoilState, useResetRecoilState } from 'recoil';

import { IAppStatus } from '@type/web/app';
import { TokenInfo } from '@type/web/token';

import { tokenValid } from '@api/base/token';
import { getUserStatusInfo } from '@api/base/user';

import { userStateInfo } from '@recoils/user/atom';

import { SELLER_ADMIN_URL, SELLER_URL, SHARE_URL } from '@lib/constants/const';
import { saveToken, removeToken, setLoginStatus } from '@lib/utils/auth';
import * as AppInterface from '@lib/utils/interface';
import {
  isAppDevice,
  isIOSApp,
  isMainWeb,
  saveSessionStorage,
} from '@lib/utils/webview';

import useBoolean from './useBoolean';
import useHistoryHook from './useHistory';

const nonTokenPathList = ['/event/detail', '/wiki', '/cs', '/point-event'];

const useAuth = () => {
  const {
    push,
    location: { pathname },
  } = useHistoryHook();

  const isApp = isAppDevice();
  const isWeb = isMainWeb();

  const { value: isLoading, setFalse: endLoading } = useBoolean(true); // 토큰 체크 완료
  const setUserInfo = useSetRecoilState(userStateInfo);
  const resetUserInfo = useResetRecoilState(userStateInfo);

  // 토큰 정보 삭제
  const clearToken = () => {
    removeToken(); // localstorage의 토큰 제거
    resetUserInfo(); // atom 저장소의 회원정보 초기화
    setLoginStatus(false);
  };

  const initAppInfoInterface = () => {
    let time = 0;
    const interval = setInterval(() => {
      time += 1;
      const isWorking = AppInterface.initGetAppInfo();

      // 앱 인터페이스 호출에 성공할 때까지 최대 3번의 interface 호출 반복
      if (time >= 3 || isWorking) {
        clearInterval(interval);
      }
    }, 500);
  };

  // 앱 인터페이스를 통해 토큰 정보 저장
  const saveTokenWithInterface = () => {
    if (
      isIOSApp() &&
      sessionStorage.getItem('jnAppInfo') &&
      localStorage.getItem('jn_access_token')
    ) {
      endLoading();
      return;
    }

    const flutterAppInfoEventListner = async (data: any) => {
      const appInfo: IAppStatus = data;
      let isValid = false;
      if (appInfo.Authorization) {
        saveToken(appInfo.Authorization);
        if (await validationToken(appInfo.Authorization)) {
          isValid = true;
          await saveFetchUserInfo();
        }
      }
      if (!isValid) {
        if (nonTokenPathList.find((path) => pathname.includes(path))) {
          clearToken();
        } else {
          logoutUserInfo();
        }
      }

      delete appInfo.Authorization;
      saveSessionStorage('jnAppInfo', { ...appInfo });

      endLoading();
    };

    // 앱에서 앱 정보를 전달 할 수 있도록 전역객체 메소드 추가
    window.flutterAppInfoEventListner = flutterAppInfoEventListner;

    initAppInfoInterface();
  };

  // 토큰으로 회원정보 api 호출
  const saveFetchUserInfo = async () => {
    const { data } = await getUserStatusInfo();
    if (!data) {
      const loginInfo: TokenInfo = JSON.parse(
        localStorage.getItem('jn_login_info') ?? '{}',
      );
      setUserInfo({
        userSeq: 0,
        status: {
          userSeq: loginInfo.userSeq,
          userId: '',
          userStatus: loginInfo.userStatus,
          userJoinType: 0,
          emailJoin: 0,
          naverJoin: 0,
          kakaoJoin: 0,
          facebookJoin: 0,
          paycoJoin: 0,
          appleJoin: 0,
          emailStatus: 0,
          naverStatus: 0,
          naverCafeStatus: 0,
          kakaoStatus: 0,
          facebookStatus: 0,
          paycoStatus: 0,
          appleStatus: 0,
        },
        marketing: {
          smsYn: 0,
          emailYn: 0,
          pushYn: 0,
          marketingAcceptDate: [],
          agree: true,
        },
        cafeUseYn: 0,
        safetyNoUseYn: 0,
        phoneNoShowYn: 0,
        userId: '',
        nickName: loginInfo.nickName,
        profileImgUrl: loginInfo.profileImgUrl,
        userPhoneNo: '',
        gaUserInfo: {
          gaUserId: '',
          jnUserId: '',
          partnerUserId: null,
          adid: '',
          gender: '',
          ageRange: 0,
          pointRange: 0,
          membershipDesc: '',
        },
        isThirdPartyConsent: true,
      });
    } else setUserInfo(data);
  };

  // 토큰 유효성 체크
  const validationToken = async (token?: string) => {
    if (!token) return false;

    try {
      await tokenValid(token, true);
      return true;
    } catch (e) {
      return false;
    }
  };

  // 유저 정보 초기화 및 앱 로그인화면 이동
  const logoutUserInfo = () => {
    removeToken();

    // 앱이면 로그인화면 인터페이스, 웹이면 없음
    if (isApp) {
      AppInterface.showLoginPopup();
      AppInterface.closeWebView();
    } else {
      push('/', true);
    }
  };

  useEffect(() => {
    if (isApp) {
      saveTokenWithInterface();
    }
  }, []);

  useLayoutEffect(() => {
    const setWebUserInfo = (e: MessageEvent) => {
      // main-web 띄운 주소 넣으세용!
      if (
        e.origin === 'http://localhost:3000' ||
        e.origin.includes(`${SHARE_URL}`) ||
        e.origin.includes(`${SELLER_URL}`) ||
        e.origin.includes(`${SELLER_ADMIN_URL}`)
      ) {
        const parsedData = JSON.parse(e.data);
        const { loginInfo, token, appScreenSize, appInfo } = parsedData;

        localStorage.setItem('jn_login_info', JSON.stringify(loginInfo));
        localStorage.setItem('jn_access_token', token);
        localStorage.setItem('AppScreenSize', JSON.stringify(appScreenSize));
        sessionStorage.setItem('jnAppInfo', JSON.stringify(appInfo));

        window.removeEventListener('message', setWebUserInfo);
        endLoading();
      }
    };

    window.addEventListener('message', setWebUserInfo);

    return () => {
      window.removeEventListener('message', setWebUserInfo);
    };
  }, []);

  return { isLoading };
};

export default useAuth;
