import { ReactElement } from 'react';

import parse from 'html-react-parser';

import {
  ParseDeepToWebrLinkReturnType,
  ParseLinksReturnType,
} from '@type/webView/deepLink';

import { deepLinks, DOMAIN, APP_SCHEMA, IMG_URL } from '@lib/constants/const';
import { getUserInfo } from '@lib/utils/auth';
import { isNumeric } from '@lib/utils/number';
import getQueryString from '@lib/utils/path/getQueryString';
import removeQueryString from '@lib/utils/path/removeQueryString';
import replaceQueryString from '@lib/utils/path/replaceQueryString';
import { onlyNumber } from '@lib/utils/regExp';
import {
  getAppDataByStorage,
  isAndroidApp,
  isAppDevice,
  isIOSApp,
  getQueryStringObj,
} from '@lib/utils/webview';

/** number 입력 시 원화로 변경 */
export const getWonUnit = (
  price: number,
  isWonMark = true,
  forEventPage = false,
): string => {
  if (price === 0 && !forEventPage) return '무료나눔';

  const commaPrice = price.toLocaleString('en-US');
  return isWonMark ? `${commaPrice}원` : commaPrice;
};

/** 날짜 입력 시 경과시간으로 변경 */
export const getElapsedTime = (date: string): string => {
  const createdTime = new Date(date.replace(/-/g, '/'));
  const createdDay = date.split(' ')[0];
  const current = new Date();

  const gapSec = Math.floor((Number(current) - Number(createdTime)) / 1000);

  if (gapSec < 1) return '방금 전';

  if (gapSec < 60) return `${gapSec}초 전`;

  const gapMin = Math.floor(gapSec / 60);
  if (gapMin < 60) return `${gapMin}분 전`;

  const gapHour = Math.floor(gapMin / 60);
  if (gapHour < 24) return `${gapHour}시간 전`;

  const gapDay = Math.floor(gapHour / 24);
  if (gapDay < 30) return `${gapDay}일 전`;

  return createdDay;
};

/** hex값을 rgba로 변경 */
export const hexToRgba = (hex: string, alpha: number): string => {
  const regex = /^#([A-Fa-f0-9]{3}){1,2}$/;
  if (regex.test(hex)) {
    let hexStr = hex.replace('#', '');
    if (hexStr.length === 3) {
      hexStr = `${hexStr[0]}${hexStr[0]}${hexStr[1]}${hexStr[1]}${hexStr[2]}${hexStr[2]}`;
    }
    const r = parseInt(hexStr.slice(0, 2), 16);
    const g = parseInt(hexStr.slice(2, 4), 16);
    const b = parseInt(hexStr.slice(4, 6), 16);

    return `rgba(${r},${g},${b},${alpha})`;
  }
  throw new Error('Bad Hex');
};

/** string response 개행 처리 */
export const replaceCRLF = (string: string): string => {
  return string
    .replace(/\n\n/gi, '<br><br>')
    .replace(/\r\n/gi, '<br>')
    .replace(/\r/gi, '<br>')
    .replace(/\n/gi, '<br>');
};

export const getUrls = (content: string) => {
  const webUrlRegex =
    /(https?:\/\/|http?:\/\/){1}[a-zA-Z0-9\\-]{1,}\.[a-zA-Z0-9]{2,}(\S*)/g;
  const appLinkRegex = /jnapps3?:\/\/\?[a-zA-Z]{2,}=[a-zA-Z0-9]{2,}(\S*)/g;

  const links = [webUrlRegex, appLinkRegex].map((regex) =>
    Array.from(new Set(content.match(regex))).sort(
      (a, b) => b.length - a.length,
    ),
  );

  let result = content;

  links.forEach((link) => {
    link?.forEach((url: string) => {
      const splitted = result.split(url);

      result = splitted.reduce((res, cur, idx) => {
        if (idx === splitted.length - 1) return res + cur;
        if (
          cur.endsWith('href="') ||
          cur.endsWith('href=') ||
          cur.endsWith("href='") ||
          cur.endsWith('href=“') ||
          cur.endsWith('>')
        ) {
          return res + cur + url;
        }

        return `${
          res + cur
        }<a style="font-weight: bold; color: blue; text-decoration: underline;" href=${url}>${url}</a>`;
      }, '');
    });
  });

  return replaceCRLF(result);
};
/** string을 받아 개행처리 후 ReactElement로 return  */
export const htmlStringToReactElement = (
  string: string,
): string | ReactElement | ReactElement[] => {
  return parse(replaceCRLF(string));
};

export const urlStringToAnchorElement = (
  string: string,
): string | ReactElement | ReactElement[] => {
  return parse(getUrls(string));
};

// 딥링크 -> 웹링크
export const parseLinkDeepToWeb = (link: string, option: any): string => {
  let returnLink = link;

  // 1. 웹뷰 경우
  if (returnLink.startsWith('jnapps3://?openweb=')) {
    returnLink = returnLink.replace('jnapps3://?openweb=', '');
  }
  // 1-1. 웹뷰인데 파트너센터일경우
  if (returnLink.startsWith('jnapps3://?partnerlink=')) {
    returnLink = returnLink.replace('jnapps3://?partnerlink=', '');
  }
  // 2. 새창으로 웹브라우저 경우
  if (returnLink.startsWith('jnapps3://?openbrowser=')) {
    returnLink = returnLink.replace('jnapps3://?openbrowser=', '');
  }
  // 3. 그 외의 경우
  // 딥 링크 정의 문서 순서와 동일하게 정리 중입니다.
  if (returnLink.startsWith('jnapps3://?applink=main')) {
    // 메인
    returnLink = `/`;
  } else if (returnLink.startsWith('jnapps3://?saleproductList=')) {
    // 메인 > 판매상품 전체보기
    returnLink = `/allproduct-list/${returnLink.replace(
      'jnapps3://?saleproductList=',
      '',
    )}`;
  } else if (returnLink.startsWith('jnapps3://?applink=manageprd')) {
    // 메인 > 판매상품 전체보기
    returnLink = `/allproduct-list`;
  } else if (returnLink.startsWith('jnapps3://?searchword=')) {
    // 검색 > 검색어로 검색 뷰 이동
    const urlWord = getQueryString('searchword', returnLink);
    const urlTab = getQueryString('searchtab', returnLink);
    const cafeOrder = urlTab === 'cafe' ? 2 : 1;

    returnLink = `/search-list/product?searchword=${urlWord}&cafeOrder=${cafeOrder}`;
  } else if (returnLink.startsWith('jnapps3://?productseq=')) {
    const data = getQueryStringObj(returnLink.replace('jnapps3://', ''));
    // 상품 상세
    if (returnLink.includes('commentseq')) {
      returnLink = `/product-detail/${data.productseq}?commentseq=${data.commentseq}`;
    } else {
      returnLink = `/product-detail/${data.productseq}`;
    }
  } else if (returnLink.startsWith('jnapps3://?categoryseq=')) {
    // 카테고리
    let categoryseq = getQueryString('categoryseq', returnLink);
    if (categoryseq.indexOf('?') > -1) {
      categoryseq = categoryseq.replace('?', '&');
    }
    returnLink = `/search-category?category=${categoryseq}`;
  } else if (returnLink.startsWith('jnapps3://?applink=salelist')) {
    // 내정보 > 판매내역 리스트
    returnLink = `/order-history/list/sell`;
  } else if (returnLink.startsWith('jnapps3://?saleseq=')) {
    const orderNo = getQueryString('saleseq', returnLink);
    // 내정보 > 판매내역 상세
    returnLink = `/order-history/detail/sell?no=${orderNo}`;
  } else if (returnLink.startsWith('jnapps3://?applink=buylist')) {
    // 내정보 > 구매내역 리스트
    returnLink = `/order-history/list/buy`;
  } else if (returnLink.startsWith('jnapps3://?buyseq=')) {
    const orderNo = getQueryString('buyseq', returnLink);
    // 내정보 > 구매내역 상세
    returnLink = `/order-history/detail/buy?no=${orderNo}`;
  } else if (returnLink.startsWith('jnapps3://?applink=cvs')) {
    // 내정보 > 택배 관리
    returnLink = `/cvs`;
  } else if (returnLink.startsWith('jnapps3://?sendcvs=')) {
    // 내정보 > 편의점 보낸 택배 상세
    returnLink = `/cvs-detail/send/${returnLink.replace(
      'jnapps3://?sendcvs=',
      '',
    )}`;
  } else if (returnLink.startsWith('jnapps3://?receivecvs=')) {
    // 내정보 > 편의점 받는 택배 상세
    returnLink = `/cvs-detail/send/${returnLink.replace(
      'jnapps3://?receivecvs=',
      '',
    )}`;
  } else if (returnLink.startsWith('jnapps3://?storeseq=')) {
    // 스토어 > 스토어 상세
    returnLink = `/store/${returnLink.replace('jnapps3://?storeseq=', '')}`;
  } else if (returnLink.startsWith('jnapps3://?applink=reviewlist')) {
    // 스토어 > 나의 후기 리스트
    returnLink = isNumeric(option.storeSeq)
      ? `/store-reviews/${option.storeSeq}`
      : '/store-reviews';
  } else if (returnLink.startsWith('jnapps3://?orderNo=')) {
    // 스토어 > 후기 작성하기(IOS만 사용)
    returnLink = `/review/${returnLink.replace('jnapps3://?orderNo=', '')}`;
  } else if (returnLink.startsWith('jnapps3://?repairShop=')) {
    // 오토 > 정비소 -> 무료혜택 탭 // store 눌렀을때 이동 url
    // 오토 > 정비소 -> 할인 탭
    // 오토 > 정비소 -> 이용후기
    // 동일 동작
    returnLink = `${returnLink.replace(
      'jnapps3://?repairShop=',
      '/auto/repairShop/',
    )}`;
    returnLink = returnLink.replace('&', '?');
  } else if (returnLink.startsWith('jnapps3://?eventseq=')) {
    // 이벤트 > 이벤트 상세
    // 이벤트 > 이벤트 상세 답글화면
    // 동일 동작
    returnLink = `/event/detail/${returnLink.replace(
      'jnapps3://?eventseq=',
      '',
    )}`;
  } else if (returnLink.startsWith('jnapps3://?pointevent=')) {
    // 이벤트 > 포인트 이벤트(seq 가 1= 룰렛, 2: 츨첵)
    returnLink = `${returnLink.replace(
      'jnapps3://?pointevent=',
      '/point-event/',
    )}`;
    returnLink = returnLink.replace('&', '?');
  } else if (returnLink.startsWith('jnapps3://?applink=notice')) {
    // 공지사항 리스트
    const tabName = getQueryString('tabName', returnLink);
    const tabParam = tabName === 'noticeTab' ? 'service' : 'fraud';
    returnLink = `/cs/notice/${tabParam}`;
  } else if (returnLink.startsWith('jnapps3://?noticeseq=')) {
    // 공지사항 상세
    const tabName = getQueryString('tabName', returnLink);
    const tabParam = tabName === 'noticeTab' ? 'service' : 'fraud';
    const noticeSeqNTabNameQuery = returnLink.replace(
      'jnapps3://?noticeseq=',
      '',
    );
    returnLink = `/cs/notice/${tabParam}/${noticeSeqNTabNameQuery}`;
  } else if (returnLink.startsWith('jnapps3://?applink=faq')) {
    // 고객센터 > 고객센터 FAQ 리스트
    returnLink = '/cs/main/web';
  } else if (returnLink.startsWith('jnapps3://?applink=infouse')) {
    // 고객센터 > 이용안내 리스트
    returnLink = '/cs/info';
  } else if (returnLink.startsWith('jnapps3://?infoseq=')) {
    // 고객센터 > 이용안내 상세
    returnLink = `/cs/info-detail/${returnLink.replace(
      'jnapps3://?infoseq=',
      '',
    )}`;
  } else if (returnLink.startsWith('jnapps3://?applink=safeguide')) {
    // 고객센터 > 안전거래 이용안내 상세
    returnLink = `/cs/info-detail/8`;
  } else if (returnLink.startsWith('jnapps3://?applink=qaseq')) {
    // 고객센터 > 1:1문의 (tab:문의하기)
    returnLink = `/cs/qna?tab=on`;
  } else if (returnLink.startsWith('jnapps3://?qaseq=')) {
    // 고객센터 > 1:1문의 답변상세
    returnLink = `/cs/qna-detail/${returnLink.replace(
      'jnapps3://?qaseq=',
      '',
    )}`;
  } else if (returnLink.startsWith('jnapps3://?applink=opinion')) {
    // 고객센터 > 제안하기
    returnLink = `/cs/qna-reinquiry/suggest`;
  } else if (returnLink.startsWith('jnapps3://?applink=agreeguide')) {
    // 이용약관 > 중고나라 개인정보처리방침
    returnLink = `/privacy-policy`;
  } else if (returnLink.startsWith('jnapps3://?applink=terms')) {
    // 이용약관 > 중고나라 이용약관
    returnLink = `/terms`;
  } else if (returnLink.startsWith('jnapps3://?applink=settlement')) {
    // 정산관리
    returnLink = '/mysettlement';
  } else if (returnLink.startsWith('jnapps3://?searchPriceWord')) {
    // 시세조회
    returnLink = returnLink.replace(
      'jnapps3://?searchPriceWord=',
      '/search/price?searchWord=',
    );
  }

  // -----------------
  // 정리 구분선입니다 위는 정리햇고 밑은 정리 안했고
  // -----------------
  if (returnLink.startsWith('jnapps3://?shareweb=')) {
    // 공유기능포함된웹뷰
    returnLink = returnLink.replace('jnapps3://?shareweb=', '');
    const arrLink = returnLink.split('&title=');
    arrLink.pop();
    returnLink = arrLink.join('&title=');
  } else if (returnLink.startsWith('jnapps3://?plan=')) {
    // 기획전
    returnLink = `${returnLink.replace('jnapps3://?plan=', '/plan/')}`;
    returnLink = returnLink.replace('&', '?');
  } else if (returnLink.startsWith(DOMAIN as string)) {
    returnLink = returnLink.replace(DOMAIN as string, '');
  }
  return `${returnLink}`;
};

// // 웹 링크 -> 딥링크
export function parseDeepLink(
  link: string,
  mustApp = false,
): { deepLink: boolean; link: string } {
  let returnLink = link;
  const links = [
    {
      web: '/store/',
      app: `://?storeseq=`,
    },
    {
      web: '/cs/qna-detail/',
      app: `://?qaseq=`,
    },
    {
      web: '/cs/qna-reinquiry/suggest',
      app: `://?applink=opinion`,
    },
    {
      web: '/cs/qna',
      app: `://?applink=qaseq`,
    },
    {
      web: '/member/dispute',
      app: `://?applink=qaseq`,
    },
    {
      web: '/product-detail',
      app: `://?productseq=`,
    },
    {
      web: '/search-category',
      app: `://?applink=categorysearch`,
    },
    {
      web: '/cs/notice/',
      app: `://?applink=notice`,
    },
    {
      web: '/cs/info/web',
      app: `://?applink=infouse`,
    },
    {
      web: '/mystore',
      app: `://?applink=mystore`,
    },
    {
      web: '/event-detail/',
      app: `://?eventseq=`,
    },
    {
      web: '/privacy-policy',
      app: `://?openweb=${DOMAIN}/privacy-policy`,
    },
    {
      web: '/terms',
      app: `://?applink=terms`,
    },
    {
      web: '/point-event/',
      app: `://?pointevent=`,
    },
    {
      web: '/allproduct-list/',
      app: `://?saleproductList=`,
    },
    {
      web: '/peace',
      app: `://?applink=peaceTab`,
    },
    {
      web: '/location',
      app: `://?applink=ourhometab`,
    },
    {
      web: '/mytag',
      app: `://?applink=mytagtab`,
    },
    {
      web: '/auto/product-regist',
      app: `://?applink=autoregist`,
    },
    {
      web: '/product-regist',
      app: `://?applink=productreg`,
    },
    {
      web: '/auto/product-detail/',
      app: `://?autoproductseq=`,
    },
    {
      web: '/auto/product-edit/',
      app: `://?autoedit=`,
    },
    {
      web: '/auto/garage',
      app: `://?applink=garagelist`,
    },
    {
      web: '/mypage',
      app: `://?applink=sellerrevenue`,
    },
    {
      web: '/auto/repairShop/',
      app: `://?repairShop=`,
    },
    {
      web: '/plan/',
      app: `://?plan=`,
    },
    {
      web: '/order-history/list/sell',
      app: `://?applink=salelist`,
    },
    {
      web: '/order-history/detail/sell?no=',
      app: `://?saleseq=`,
    },
    {
      web: '/order-history/list/buy',
      app: `://?applink=buylist`,
    },
    {
      web: '/order-history/detail/buy?no=',
      app: `://?buyseq=`,
    },
    {
      web: '/notify',
      app: `://?applink=alarm`,
    },
    {
      web: '/maintab/town',
      app: `://?applink=ourhometab`,
    },
    {
      web: '/maintab/ticket',
      app: `://?applink=ticket`,
    },
    {
      web: '/search-category',
      app: `://?applink=categorySearch`,
    },
    {
      web: '/category-seller',
      app: `://?applink=searchmain`,
    },
    {
      web: '/search-list',
      app: `://?`,
    },
    {
      web: '/search/price',
      app: `://?searchPriceWord=`,
    },
    {
      web: '/home',
      app: `://?applink=main`,
    },
  ];

  // 이미 딥 링크면 패스
  if (
    returnLink.startsWith(`://?`) ||
    returnLink.startsWith(APP_SCHEMA as string)
  ) {
    return { deepLink: true, link: returnLink };
  }

  const findIndex = links.findIndex((l) => returnLink.startsWith(l.web));

  // 딥 링크가 있을 경우
  if (findIndex > -1) {
    if (returnLink.includes('/category-seller')) {
      // 검색 : 카테고리 셀러
      returnLink = links[findIndex].app;
    } else if (returnLink.includes('/product-detail')) {
      // 상품상세
      returnLink = returnLink.replace('?', '&');
      returnLink = returnLink.replace(
        links[findIndex].web,
        links[findIndex].app,
      );
    } else if (returnLink.includes('/cs/qna?')) {
      // 1:1 문의하기
      returnLink = returnLink
        .replace('?', '&')
        .replace('/cs/qna', '://?applink=qaseq');
    } else if (links[findIndex].web === '/auto/repairShop/') {
      // 정비소
      const tab = getQueryString('tab');
      if (tab) returnLink = returnLink.replace('?', '&');
      else returnLink = `${returnLink}&tab=free`;
      returnLink = returnLink.replace(
        links[findIndex].web,
        links[findIndex].app,
      );
    } else if (links[findIndex].web === '/search-category') {
      // 카테고리 검색
      const appLinkV1 = returnLink
        .replace('/search-category?', '')
        .replace('category=', 'categoryseq=')
        .replace('?', '&');
      const appLinkV2 = returnLink
        .replace('/search-category?', 'categorysearch&')
        .replace('category=', 'seq=')
        .replace('categoryname=', 'name=')
        .replace('searchword=', 'keyword=')
        .replace('?', '&');
      const userAgent = getAppDataByStorage();

      if (isAndroidApp()) {
        if (userAgent === null) returnLink = `://?${appLinkV1}`;
        else returnLink = `://?applink=${appLinkV2}`;
      } else if (isIOSApp()) {
        const version = userAgent['App-Version'].split('.').join('');
        if (Number(version) < 443) returnLink = `://?${appLinkV1}`;
        else returnLink = `://?applink=${appLinkV2}`;
      } else {
        returnLink = `://?applink=${appLinkV2}`;
      }
    } else if (links[findIndex].web === '/search-list') {
      // 통합 검색
      const appLink = returnLink.replace('/search-list', '').split('?');
      const searchword = getQueryString('searchword', returnLink);
      const keywordSource = getQueryString('keywordSource', returnLink);

      let query = replaceQueryString(
        {
          searchtab: appLink[0].replace('/', '') || 'total_v2',
          searchword,
          keywordSource,
        },
        // appLink,
      );
      if (query.startsWith('?')) query = query.slice(1);

      returnLink = `://?${query}`;
    } else if (links[findIndex].web === '/plan/') {
      // 기획전
      returnLink = returnLink.replace('?', '&');
      returnLink = returnLink.replace(
        links[findIndex].web,
        links[findIndex].app,
      );
    } else if (returnLink.includes('/cs/notice/')) {
      const urlO = new URL(DOMAIN + link);
      const [tabNameParam, noticeSeq] = urlO.pathname
        .replace(links[findIndex].web, '')
        ?.split('/');
      const tabNameQuery =
        tabNameParam === 'service' ? 'noticeTab' : 'fraudTab';
      // notice 리스트 페이지인지 상세페이지인지 구분하여 딥링크 return
      const deepLink = noticeSeq
        ? `://?noticeseq=${noticeSeq}&tabName=${tabNameQuery}`
        : `${links[findIndex].app}&tabName=${tabNameQuery}`;

      return { deepLink: false, link: deepLink };
    } else if (returnLink.includes('/notice/service')) {
      // 거래제한품목 확인하기 MWEB -> MWEB
      return { deepLink: false, link: returnLink };
    } else if (returnLink.includes('/search/price')) {
      // 시세 조회 앱 설치 팝업 WEB -> APP
      const searchWord = getQueryString('searchWord', returnLink);
      const newQuery = removeQueryString('searchWord', returnLink).replace(
        '?',
        '&',
      );
      returnLink = returnLink.replace('?', '&');
      returnLink = `${links[findIndex].app}${searchWord}${newQuery}`;
    } else {
      const commentSeq = getQueryString('commentseq');
      if (commentSeq) {
        returnLink = `${returnLink.replace(
          links[findIndex].web,
          links[findIndex].app,
        )}&commentseq=${commentSeq}`;
      } else {
        returnLink = returnLink.replace(
          links[findIndex].web,
          links[findIndex].app,
        );
      }
    }
    return { deepLink: true, link: returnLink };
  }

  if (mustApp) {
    returnLink = `://?applink=main`;
    return { deepLink: true, link: returnLink };
  }

  return { deepLink: false, link: returnLink };
}

export function parseIternalLink(url: string): string {
  return url.replace(DOMAIN as string, '');
}

/**
 * 외부링크, 내부링크인지 체크하여 url을 수정해 return
 */
export function checkExternalWebLink(url: string): {
  external: boolean;
  url: string;
} {
  const option = getUserInfo();

  // 우선 딥링크라면 웹 url 로 변경함
  const setWebUrl = parseIternalLink(
    url.startsWith('jnapps3://')
      ? parseLinkDeepToWeb(url, option)
      : url.replace(DOMAIN as string, ''),
  );

  if (setWebUrl.startsWith('/')) {
    return {
      external: false,
      url: setWebUrl,
    };
  }

  return {
    external: true,
    url: setWebUrl,
  };
}

export const getImgSrc = (
  src: string | undefined,
  type: 'normal' | 'thumb' = 'normal',
  cdn = IMG_URL as string,
): string => {
  if (typeof src !== 'string') return '';
  if (src.includes('cafeptthumb')) return src; // 카페 이미지 경우 그대로 반환하기

  const replaceSrc = src
    .replace('{image}', cdn)
    .replace(/\?(.*)/g, '')
    .concat(type === 'thumb' ? '?impolicy=thumb' : '?size=150');

  return replaceSrc.startsWith('/') && !replaceSrc.startsWith('/static/media')
    ? `${cdn}${replaceSrc}`
    : replaceSrc;
};

export const getExtenalLink = (url: string): string => {
  return isAppDevice() ? `jnapps3://?openbrowser=${url}` : url;
};

/** 하단에 주석처리된 함수들은 당장 사용되지 않지만, 유용해보여서 남겨뒀습니다. */

// export const underscoreParser = word => {
//   return word
//     .replace(/(?:^|\.?)([A-Z])/g, (x, y) => {
//       return `_${y.toLowerCase()}`;
//     })
//     .replace(/^_/, '');
// };

// export const camelCaseParser = word => {
//   return word
//     .replace(/_([a-z])/g, (x, y) => {
//       return `${y.toUpperCase()}`;
//     })
//     .replace(/^_/, '');
// };

// export const objectToQueryString = data => {
//   return `?${Object.entries(data)
//     .map(d => d.join('='))
//     .join('&')}`;
// };

export const arrayToNewString = (
  arr: string[],
  joinText = '',
  lastText = '',
) => {
  const text = `${arr.filter((val) => Boolean(val)).join(joinText)}`;
  return `${text}${text ? lastText : ''}`;
};

/**
 * 기존 main-web에서만 사용되는 코드입니다.
 */

export const numFormat = (num: number): string => {
  return Number(num)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    .split('.')[0];
};

export const formatNumber = (num: string): string => {
  return numFormat(Number(onlyNumber(num)));
};

/** url 파라미터가 딥링크라면, 웹링크로 파싱해서 반환 */
export const parseDeepToWebLink = (
  url: string,
): ParseDeepToWebrLinkReturnType => {
  // 정의되어있는 딥링크 목록을 탐색하여, url 파라미터가 딥링크인지 체크
  const idx = deepLinks.findIndex((link) => url.indexOf(link.deepLink) === 0);

  const isDeepLink = idx >= 0;
  if (!isDeepLink) {
    return {
      isDeepLink,
      deepLink: '',
      webLink: url,
    };
  }

  const { deepLink, webLink } = deepLinks[idx];

  // 딥링크를 웹링크로 변환
  const parseWebLink = url.replace(deepLink, webLink);

  return {
    isDeepLink, // 딥링크가 맞는지 판별
    deepLink, // 웹뷰 내에서 사용할 딥링크
    webLink: parseWebLink, // PC+Mobile 환경에서 사용할 웹링크
  };
};

/** url 파라미터가 외부사이트인지 판별 */
export const isExternalLink = (url: string): boolean =>
  url.indexOf('/') !== 0 && url.indexOf(DOMAIN || '') !== 0;

/** url 정보 추출 */
export const parseLinks = (url: string): ParseLinksReturnType => {
  const parsedLink = parseDeepToWebLink(url);
  const isExternal = isExternalLink(url);

  return {
    ...parsedLink,
    isExternal, // 새 창으로 열어야할 외부링크인지 판별
  };
};

/** 최대 길이로 Text 자르고 ⋯추가 */
export const changeOmitText = (maxLength: number, text: string): string => {
  return text.length > maxLength ? `${text.slice(0, maxLength)}⋯` : text;
};

export const returnHyphenPhone = (phoneNo: string) => {
  if (!phoneNo || phoneNo?.length !== 11) return phoneNo;

  return phoneNo.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
};
