// import airbridge from 'airbridge-web-sdk-loader';
import { getToken } from './auth';
import { interfaceSendGA4, interfaceSendGAUserActionLog } from './interface';
import { isAppDevice } from './webview';

type Enumerate<
  N extends number,
  Acc extends number[] = [],
> = Acc['length'] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc['length']]>;

type IntRange<F extends number, T extends number> = Exclude<
  Enumerate<T>,
  Enumerate<F>
>;

function getMarketinPushInfo(marketings: (0 | 1)[]) {
  return marketings.map((marketing) => (marketing === 0 ? 'N' : 'Y')).join('_');
}

function getLoginType(loginType: IntRange<0, 6>) {
  const loginTypeList = [
    '이메일',
    '네이버',
    '카카오',
    '페이스북',
    '',
    '페이코',
    'Auto',
  ];

  return loginType < 0 ? 'Auto' : loginTypeList[loginType];
}

function getGAData(data: any, userLoginInfo: any) {
  const isLogin = !!getToken();
  const isApp = isAppDevice();

  const { search } = window.location;
  const queryString = data.query || '';
  const {
    loginType,
    marketing: { pushYn, smsYn, emailYn },
    gaUserInfo,
  } = userLoginInfo;

  return {
    // 태깅 종류. 빈값이면 PV, ga_event 이면 이벤트 태깅
    event: data.event || 'ga_virtual',

    // 아래 3개는 모든 태깅에서 필수값.
    location: data.location
      ? `${process.env.REACT_APP_WEBVIEW_GA_URL}${data.location}${queryString}${
          queryString ? search.replace('?', '&') : search
        }`
      : null,
    title: data.title ? `중나_${data.title}` : '중고나라',
    userid: gaUserInfo && gaUserInfo.gaUserId,

    // 아래 3개는 이벤트 태깅시 필수값
    category: data.category || null,
    action: data.action || null,
    label: data.label || null,
    value: data.value || null,

    layerCategory: data.layerCategory || null,
    layerAction: data.layerAction || null,
    layerLabel: data.layerLabel || null,
    ecommerce: data.ecommerce || null,
    nonInteraction: data.nonInteraction || false,

    // dimension 무료버전
    // 채널_중나파센_채널유형
    dimension2: isApp ? 'APP' : 'MWeb',
    // 고객_중나_USER_ID
    dimension3: (gaUserInfo && gaUserInfo.jnUserId) || null,
    // 검색_중나_검색어
    dimension4: data.dimension4 || null, // dimension24 // 검색결과
    // 검색_중나_검색어유형
    dimension5: data.dimension5 || null, // dimension25 // 검색결과
    // 검색_중나_검색분류
    dimension6: data.dimension6 || null, // dimension26 // 검색결과
    // 고객_중나파센_로그인유형
    dimension7: isLogin ? getLoginType(loginType) : null,
    // 미정의
    dimension8: null,
    // 고객_중나_회원등급
    dimension9: (gaUserInfo && gaUserInfo.membershipDesc) || null,
    // 숏컷_광고주
    dimension10: null,
    // 숏컷_타이틀
    dimension11: null,
    // 고객_중나파센_성별
    dimension12: (gaUserInfo && gaUserInfo.gender) || null,
    // 고객_중나파센_연령대
    dimension13: (gaUserInfo && gaUserInfo.ageRange) || null,
    // 고객_중나_마케팅동의_PUSH_SMS_E-MAIL
    dimension14: isLogin ? getMarketinPushInfo([pushYn, smsYn, emailYn]) : null,
    // 상품등록_중나_판매자정보
    dimension15: data.dimension15 || null, // 상품등록 완료 or 대기추가
    // 상품등록_중나_거래희망위치
    dimension16: data.dimension16 || null, // 상품등록 완료 or 대기추가
    // 고객_중나_포인트
    dimension17: (gaUserInfo && gaUserInfo.pointRange) || null,
    // 구매_중나_판매자정보
    dimension18: data.dimension18 || null, // 안전결제 구매완료
    // 구매_중나_거래희망위치
    dimension19: data.dimension19 || null, // 안전결제 구매완료
    // 미정의
    dimension20: null,
  };
}

// userActionLog 적재용 GA 데이터 전송용
export function sendUserActionLog(data: any, userLoginInfo: any): void {
  const GA_DATA = getGAData(data, userLoginInfo);

  interfaceSendGAUserActionLog(GA_DATA);
}

// 기존 GTM으로 보내던 GA 데이터 전송용
// 기존에 GTM으로 전송하고 있던 GA 데이터를 앱 인터페이스를 이용해 교체하였음(에어브릿지, userActionLog 등과 같이 앱 인터페이스로 일원화 목적)
// export function sendGoogleAnalytics(data: any): void {
//   interfaceSendGALog(data);
// }

// UA 버전 ga 지원종료에 따른 GA4 버전 interface 호출하는 함수.
// TODO: UA 버전 정리되면 함수명 변경. (sendGA4 -> sendGoogleAnalytics);
export function sendGA4(data: any): void {
  interfaceSendGA4(data);
}
