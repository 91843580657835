import { ApiResponse } from '@type/web/api';
import {
  DeleteCommentPropsType,
  GetCommentListPropsType,
  GetCommentListReturnType,
  GetSpecificCommentWhenModifyReturnType,
  GetSpecificCommentWhenReplyReturnType,
  PostCommentPropsType,
  PutCommentPropsType,
} from '@type/webView/comment';

import { AxiosDomain, axiosRequest } from '@api/config';

import { IMG_URL } from '@lib/constants/const';

/**
 * 댓글 삭제 API
 * @param type 조회 타입 (event, product, content)
 * @param commentSeq 댓글 일렬번호
 * @returns 반환값 {data: {}, meta: {}}
 */
export const deleteComment = ({
  type,
  commentSeq,
}: DeleteCommentPropsType): any => {
  const path = `/comment/${type}/${commentSeq}`;
  return axiosRequest(AxiosDomain.mainBase, 'delete', path);
};

/**
 * 댓글 리스트 조회 API
 * @param type 조회 타입 (event, product, content)
 * @param typeSeq 일련번호 (eventSeq, productSeq, contentSeq)
 * @returns GetCommentListReturnType
 */
export const getCommentList = ({
  type,
  typeSeq,
  startIndex,
}: GetCommentListPropsType): Promise<ApiResponse<GetCommentListReturnType>> => {
  const path = `/comment/${type}/${typeSeq}?startIndex=${startIndex}`;
  return axiosRequest(AxiosDomain.mainBase, 'get', path);
};

/**
 * 댓글 등록 API
 * @param input 댓글 내용 (PostCommentInputType)
 * @param type 조회 타입 (event, product, content)
 * @param typeSeq 일련번호 (eventSeq, productSeq, contentSeq)
 * @param imgData 이미지 파일 데이터
 */
export const postComment = async ({
  input,
  type,
  typeSeq,
  imgData,
}: PostCommentPropsType) => {
  const path = `/comment/${type}/${typeSeq}`;
  let newInput = { ...input };
  if (imgData) {
    try {
      const { data } = await fileUpload({ data: imgData });
      const newImgUrl = data[0].mediaUrl;

      newInput = {
        ...input,
        imgUrl: newImgUrl,
      };

      await axiosRequest(AxiosDomain.mainBase, 'post', path, newInput);
    } catch (e) {
      console.error(e);
    }
  } else {
    await axiosRequest(AxiosDomain.mainBase, 'post', path, newInput);
  }
};

/**
 * 댓글 수정 API
 * @param input 댓글 내용 (PutCommentInputType)
 * @param type 조회 타입 (event, product, content)
 * @param typeSeq 일련번호 (eventSeq, productSeq, contentSeq)
 * @param commentSeq 댓글 일련번호
 */
export const putComment = async ({
  input,
  type,
  typeSeq,
  imgData,
  commentSeq,
}: PutCommentPropsType) => {
  const path = `/comment/${type}/${typeSeq}/${commentSeq}`;
  let newInput = { ...input };

  if (input.imgUrl) {
    newInput = {
      ...input,
      imgUrl: input?.imgUrl?.replace(IMG_URL as string, ''),
    };
  }

  if (imgData) {
    try {
      const { data } = await fileUpload({ data: imgData });
      const newImgUrl = data[0].mediaUrl;

      newInput = {
        ...input,
        imgUrl: newImgUrl,
      };
    } catch (e) {
      console.error(e);
    }
  }
  return axiosRequest(AxiosDomain.mainBase, 'put', path, newInput);
};

/** 댓글 수정 시 특정 댓글 조회 API */
export const getSpecificCommentWhenModify = async ({
  type,
  commentSeq,
}: any): Promise<ApiResponse<GetSpecificCommentWhenModifyReturnType>> => {
  const path = `/comment/edit/${type}/${commentSeq}`;
  return axiosRequest(AxiosDomain.mainBase, 'get', path);
};

/** 답글 등록 시, 답글 다는 댓글의 정보 조회 */
export const getSpecificCommentWhenReply = async ({
  type,
  commentSeq,
}: any): Promise<ApiResponse<GetSpecificCommentWhenReplyReturnType>> => {
  const path = `/comment/reply/${type}/${commentSeq}`;
  return axiosRequest(AxiosDomain.mainBase, 'get', path);
};

/** 이미지 업로드시 S3에 파일 저장 */
export const fileUpload = ({ data }: any): any => {
  const path = '/file/upload';
  return axiosRequest(AxiosDomain.mainBase, 'post', path, data);
};
