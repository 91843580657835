import { css } from '@emotion/react';

const font = {
  size28: css`
    font-size: 28px;
    line-height: 39px;
  `,
  size24: css`
    font-size: 24px;
    line-height: 34px;
  `,
  size20: css`
    font-size: 20px;
    line-height: 28px;
  `,
  size18: css`
    font-size: 18px;
    line-height: 25px;
  `,
  size16: css`
    font-size: 16px;
    line-height: 22px;
  `,
  size14: css`
    font-size: 14px;
    line-height: 20px;
  `,
  size12: css`
    font-size: 12px;
    line-height: 17px;
  `,
  semibold: css`
    font-weight: 600;
  `,
  medium: css`
    font-weight: 500;
  `,
  regular: css`
    font-weight: 400;
  `,
};

const typography = {
  h0: css`
    ${font.size28}
    ${font.semibold}
  `,
  h1: css`
    ${font.size24}
    ${font.semibold}
  `,
  h2: css`
    ${font.size20}
    ${font.semibold}
  `,
  h3: css`
    ${font.size18}
    ${font.semibold}
  `,
  h4: css`
    ${font.size16}
    ${font.semibold}
  `,
  h5: css`
    ${font.size16}
    ${font.medium}
  `,
  b1: css`
    ${font.size16}
    ${font.medium}
  `,
  b2: css`
    ${font.size16}
    ${font.regular}
  `,
  b3: css`
    ${font.size14}
    ${font.medium}
  `,
  b4: css`
    ${font.size14}
    ${font.regular}
  `,
  d1: css`
    ${font.size12}
    ${font.medium}
    letter-spacing: 0.2px;
  `,
  d2: css`
    ${font.size12}
    ${font.regular}
    letter-spacing: 0.2px;
  `,
  btn1: css`
    ${font.size16}
    ${font.medium}
  `,
  btn2: css`
    ${font.size14}
    ${font.medium}
  `,
};

export default typography;
