import styled from '@emotion/styled';

import ImageComponent from '@components/common/Image';

const PrizeWrapper = styled.div`
  top: 27%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 73.3%;
  padding-bottom: 15%;
`;

const Section = styled.section`
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ background }: { background?: string }) =>
    background ?? 'white'};
`;

const Date = styled(ImageComponent)`
  max-width: 49%;
  aspect-ratio: 403 / 66;
  margin-inline: auto;
`;

const Item = styled.li`
  width: 33.33%;
`;

const List = styled.ul`
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  margin-top: 20px;
  padding: 0 15px;
  width: 100%;

  ${Item} {
    &:not(:first-of-type) {
      margin-left: 10px;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;

  ${Date} {
    margin: auto;
  }
`;

export { PrizeWrapper, Section, Date, Item, List, Wrapper };
