import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { css } from '@emotion/react';

import useGoBack from '@hook/useGoBack';

import { HeaderOptions } from '@type/webView/header';

import Icon from '@components/common/Icon';
import Logo from '@components/common/Logo';

import a11yHidden from '@lib/styles/a11yHidden';
import { palette } from '@lib/styles/palette';

export function LayoutHeader({
  isFixed,
  isShowBackground,
  response,
  headerStyle,
  iconStyle,
}: HeaderOptions): ReactElement {
  const goBack = useGoBack();
  const { leftBtnOption, sideBtnSetOption, rightBtnOption } = response!;
  const leftBtnOnClick = (leftBtnOption && leftBtnOption.onClick) || goBack;

  return (
    <header css={[header, headerStyle]}>
      {leftBtnOption?.icon ? (
        <button
          css={IconButton}
          onClick={leftBtnOnClick}
          title={leftBtnOption?.name} // tool tip
        >
          <p css={a11yHidden}>{leftBtnOption?.name}</p>
          <Icon
            icon={leftBtnOption?.icon || 'arrowLeft'}
            stroke={isShowBackground ? palette.black10 : palette.white}
            css={[iconBtn, iconStyle]}
            size={leftBtnOption?.size || '16px'}
          />
        </button>
      ) : (
        <Link to="/" className="logo" onClick={leftBtnOnClick}>
          <Logo
            logoType="ONLY_TEXT"
            color={isShowBackground ? palette.orange9 : palette.white}
          />
        </Link>
      )}
      {response?.title && (
        <h1 className="center" css={title}>
          {response.title}
        </h1>
      )}
      {rightBtnOption &&
        (rightBtnOption?.isButton ? (
          <button
            css={IconButton}
            onClick={rightBtnOption?.onClick}
            title={rightBtnOption?.name} // tool tip
          >
            <p css={a11yHidden}>{rightBtnOption?.name}</p>
            <Icon
              icon={rightBtnOption?.icon || 'menu'}
              css={iconStyle}
              size={rightBtnOption?.size || '16px'}
            />
          </button>
        ) : (
          <Link
            to={rightBtnOption?.to || '/menu'}
            title={rightBtnOption?.name || '메뉴'} // tool tip
          >
            <Icon
              icon="menu"
              css={[iconBtn, iconStyle]}
              color={isShowBackground ? '' : palette.white}
              size={rightBtnOption?.size || '16px'}
            />
            <p css={a11yHidden}>{rightBtnOption?.name}</p>
          </Link>
        ))}
    </header>
  );
}

const IconButton = css`
  background-color: transparent;
`;

const header = css`
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  padding: 0 20px;
  .center {
    width: calc(100% - 100px);
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const title = css`
  max-width: 100%;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: ${palette.gray900};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: normal;
`;

const iconBtn = css`
  cursor: pointer;
`;

export default LayoutHeader;
