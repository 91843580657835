export const AF_URL_SCHEME = '(https:\\/\\/)(([^\\.][^\\.]+).)(.*\\/)(.*)';
export const VALID_AF_URL_PARTS_LENGTH = 5;
export const GOOGLE_CLICK_ID = 'gclid';
export const ASSOCIATED_AD_KEYWORD = 'keyword';
export const AF_KEYWORDS = 'af_keywords';
export const AF_CUSTOM_EXCLUDE_PARAMS_KEYS = [
  'pid',
  'c',
  'af_channel',
  'af_ad',
  'af_adset',
  'deep_link_value',
  'af_sub1',
  'af_sub2',
  'af_sub3',
  'af_sub4',
  'af_sub5'
];
export const GCLID_EXCLUDE_PARAMS_KEYS = [
  'pid',
  'c',
  'af_channel',
  'af_ad',
  'af_adset',
  'deep_link_value'
];
