const importantDayImageSrc = 'https://common.joongna.com/image/important_day';

const prize = [
  {
    date: '0306',
    title: '3.6 Wed 12:00 Open',
    prizes: [
      {
        title: '[최저가 보장] 고프로 5일 대여 이용권',
        link: 'productseq=157470470',
        imgSrc: `${importantDayImageSrc}/prize/202403/prize_0306_1.webp`,
        priceBefore: '39,500원',
        pricePercent: '67%',
        priceAfter: '12,900원',
        delivery: true,
      },
      {
        title: '[짱구 모음] 짱구 지비츠, 피규어, 플리퍼즈',
        link: 'eventseq=1197',
        imgSrc: `${importantDayImageSrc}/prize/202403/prize_0306_2.webp`,
        priceBefore: '15,000원',
        pricePercent: '43%',
        priceAfter: '5,900원~',
        delivery: true,
      },
      {
        title: '[금액권] 모두의 대리 모바일 금액권 3천원,5천원권',
        link: 'eventseq=1189',
        imgSrc: `${importantDayImageSrc}/prize/202403/prize_0306_3.webp`,
        priceBefore: '3,000원',
        pricePercent: '37%',
        priceAfter: '1,900원~',
        delivery: false,
      },
    ],
  },
  {
    date: '0307',
    title: '3.7 Thu 12:00 Open',
    prizes: [
      {
        title: '갤럭시S22,23 울트라 30일 대여 이용권',
        link: 'storeseq=6256630',
        imgSrc: `${importantDayImageSrc}/prize/202403/prize_0307_1.webp`,
        priceBefore: '234,000원',
        pricePercent: '75%',
        priceAfter: '59,400원~',
        delivery: true,
      },
      {
        title: '[쇼골프] 쇼골프 타석 이용권 1회~5회권',
        link: 'eventseq=1190',
        imgSrc: `${importantDayImageSrc}/prize/202403/prize_0307_2.webp`,
        priceBefore: '23,000원',
        pricePercent: '27%',
        priceAfter: '16,800원~',
        delivery: false,
      },
      {
        title: '[CGV] CGV 2D 영화관람권 1매',
        link: 'productseq=157417517',
        imgSrc: `${importantDayImageSrc}/prize/202403/prize_0307_3.webp`,
        priceBefore: '15,000원',
        pricePercent: '34%',
        priceAfter: '9,900원',
        delivery: false,
      },
    ],
  },
  {
    date: '0308',
    title: '3.8 Fri 12:00 Open',
    prizes: [
      {
        title: '[컴포즈 커피] 아메리카노 (Ice/Hot)',
        link: 'productseq=157416710',
        imgSrc: `${importantDayImageSrc}/prize/202403/prize_0308_1.webp`,
        priceBefore: '1,500원',
        pricePercent: '11%',
        priceAfter: '1,350원',
        delivery: false,
      },
      {
        title: '[포켓몬스터] 포켓몬 랜덤박스 1박스',
        link: 'productseq=144958004',
        imgSrc: `${importantDayImageSrc}/prize/202403/prize_0308_2.webp`,
        priceBefore: '30,000원',
        pricePercent: '34%',
        priceAfter: '19,900원',
        delivery: true,
      },
      {
        title: '[베이직스] 베이직북14 3세대(윈도우11 포함)',
        link: 'productseq=155345130',
        imgSrc: `${importantDayImageSrc}/prize/202403/prize_0308_3.webp`,
        priceBefore: '425,000원',
        pricePercent: '14%',
        priceAfter: '368,000원',
        delivery: false,
      },
    ],
  },
];

export { prize, importantDayImageSrc };
