import { ReactElement } from 'react';

import { useRecoilValue } from 'recoil';

import { css } from '@emotion/react';

import useBoolean from '@hook/useBoolean';
import useResponsive from '@hook/useResponsive';

import { FooterTermsItemType } from '@type/webView/wikiMain';

import { initData } from '@recoils/init/atom';

import Icon from '@components/common/Icon';

import { MWEB_URL } from '@lib/constants/const';
import { palette } from '@lib/styles/palette';
import sizeType from '@lib/styles/sizeType';

function WikiFooter(): ReactElement {
  const {
    configuration: {
      footer: {
        companyAdditionalTitle,
        companyAdditional,
        terms,
        responsibility,
      },
    },
  } = useRecoilValue(initData);

  const { value: isShow, toggle: onToggle } = useBoolean(false);
  const { isMobile } = useResponsive();

  const onClickToggleMoreFooter = () => {
    onToggle();
  };
  const matchDeepLink = (type: string, url: string) => {
    switch (type) {
      case 'terms':
        return `jnapps3://?applink=terms`;
      case 'privacy-policy':
        return `jnapps3://?applink=agreeguide`;
      case 'member':
        return `jnapps3://?openweb=${MWEB_URL}/member/dispute&nofooter=Y`;
      case 'external':
        return `jnapps3://?openbrowser=${url}`;
      case 'youthpolicy':
        return `jnapps3://?openweb=${MWEB_URL}${url}&nofooter=Y`;
      default:
        return `jnapps3://?applink=main`;
    }
  };

  return (
    <footer css={footer}>
      <div className="title">
        <strong>{companyAdditionalTitle}</strong>
        {isMobile && (
          <button type="button" onClick={onClickToggleMoreFooter}>
            {isShow ? (
              <Icon icon="arrowUp" size={12} stroke={palette.gray6} />
            ) : (
              <Icon icon="arrowDown" size={12} stroke={palette.gray6} />
            )}
          </button>
        )}
      </div>
      {(isShow || !isMobile) && (
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: companyAdditional }}
        />
      )}
      <ul css={term(isMobile)}>
        {terms.map((item: FooterTermsItemType, index: number) => {
          const { name, type, url } = item;
          const checkPath = matchDeepLink(type, url);

          return (
            index > 0 && (
              <li key={type}>
                <button
                  css={termsBtn}
                  onClick={() => {
                    window.open(checkPath);
                  }}
                >
                  {name}
                </button>
              </li>
            )
          );
        })}
      </ul>

      <div css={responsibility}>{responsibility}</div>
    </footer>
  );
}

const footer = css`
  max-width: ${sizeType.sizeLayout.large};
  width: 100%;
  margin: 0 auto;
  line-height: 1.5;
  white-space: pre-line;
  position: relative;

  .title {
    display: flex;
    align-items: center;

    strong {
      font-weight: 700;
      color: ${palette.gray6};
      line-height: 18px;
      margin-right: 6px;
    }

    button {
      display: flex;
      align-items: center;
      background-color: transparent;
      color: ${palette.gray6};
    }
  }

  .content {
    line-height: 2;
    margin: 20px 0 33px 0;
  }

  .responsibility {
    margin-top: 20px;
  }
`;

const term = (isMobile: boolean) => css`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${isMobile ? '12px' : '33px'};

  li {
    margin-right: 10px;
    margin-bottom: 10px;
    border-right: solid 1px ${palette.gray4};
    padding-right: 8px;

    a {
      color: ${palette.gray6};
    }

    &:last-of-type {
      padding-right: 0;
      border: none;
    }

    &:nth-of-type(2) {
      font-weight: 700;
    }
  }
`;

const termsBtn = css`
  background-color: transparent;
  color: ${palette.gray6};
`;
export default WikiFooter;
