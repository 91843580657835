import {
  useState,
  useEffect,
  useRef,
  PropsWithChildren,
  MouseEventHandler,
} from 'react';
import ReactDOM from 'react-dom';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useSetRecoilState } from 'recoil';

import { css } from '@emotion/react';

import { StylePropType } from '@type/webView/style';

import { alertCloseSelector } from '@recoils/orderPaymentDelivery/alert';

import { palette } from '@lib/styles/palette';
import { isAndroid } from '@lib/utils/device';
import { hexToRgba } from '@lib/utils/parser';

interface AlertPotalPropsType {
  type?: 'fadeIn' | 'slideToRight' | 'slideToTop';
  show?: boolean;
  onClickBack?: MouseEventHandler;
  wrapStyle?: StylePropType;
  contentStyle?: StylePropType;
}

const AlertPotal = ({
  type = 'fadeIn',
  show = false,
  onClickBack,
  wrapStyle,
  contentStyle,
  children,
}: PropsWithChildren<AlertPotalPropsType>): JSX.Element | null => {
  // transition을 위한 내부 상태로 추정
  const [isShow, setIsShow] = useState(show);
  const alertContentsRef = useRef<HTMLDivElement>(null);
  const closeAlert = useSetRecoilState(alertCloseSelector);

  const addScrollLock = () => {
    if (alertContentsRef.current) disableBodyScroll(alertContentsRef.current);
  };

  const removeScrollLock = () => {
    const portal = document.getElementById('AlertPortal');

    if (portal?.children.length === 0) {
      clearAllBodyScrollLocks();
    }
  };

  useEffect(() => {
    const alertContentsRefEle = alertContentsRef.current;
    const removeScrollLockF = () => {
      setIsShow(show);
      removeScrollLock();
    };

    if (show) {
      // open popup
      addScrollLock();
      setIsShow(show);
    } else if (alertContentsRefEle) {
      // close popup
      alertContentsRefEle.addEventListener('transitionend', removeScrollLockF);
    }

    return () => {
      if (alertContentsRefEle) {
        alertContentsRefEle.removeEventListener(
          'transitionend',
          removeScrollLockF,
        );
      }
      removeScrollLockF();
    };
  }, [show, isShow]);

  if (show || isShow) {
    const el = document.getElementById('AlertPortal');

    const onClickBackground = () => {
      closeAlert();
    };

    return (
      el &&
      ReactDOM.createPortal(
        <div css={[popUpWrapStyle, isAndroid() && isAndroidStyle, wrapStyle]}>
          <div
            css={backgroundStyle(show && isShow)}
            role="button"
            tabIndex={-1}
            onClick={onClickBack}
          >
            {' '}
          </div>
          <div
            css={[
              popUpContentStyle,
              type === 'slideToRight' && clipStyle,
              type === 'slideToTop' && letDownStyle,
              contentStyle,
            ]}
          >
            <div
              ref={alertContentsRef}
              css={transitionContStyle(show && isShow, type)}
            >
              {children}
            </div>
          </div>
        </div>,
        el,
      )
    );
  }
  return null;
};

const popUpWrapStyle = css`
  display: flex;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  /* z-index: 501; */
  z-index: 600;
  bottom: 0;
`;

const backgroundStyle = (isShow: boolean) => css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${hexToRgba(palette.black, 0.6)};
  opacity: ${isShow ? 1 : 0};
  transition: opacity 300ms ease-out;
`;

const isAndroidStyle = css`
  min-height: 100vh;
`;

const popUpContentStyle = css`
  position: relative;
  display: flex;
  align-items: center;
  width: 90vw;
  max-width: 400px;
`;

const clipStyle = css`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  clip: rect(0, auto, auto, 0);
`;

const letDownStyle = css`
  width: 100vw;
  max-width: 750px;
  max-height: 100vh;
  align-self: flex-end;
`;

const transitionContStyle = (
  isShow: boolean,
  type: AlertPotalPropsType['type'],
) => css`
  width: 100%;

  ${type === 'fadeIn' &&
  css`
    opacity: ${isShow ? 1 : 0};
    transition: all 300ms ease-out;
  `}

  ${type === 'slideToRight' &&
  css`
    position: relative;
    left: ${isShow ? 0 : '-100%'};
    transition: left 300ms ease-out;
  `}
    ${type === 'slideToTop' &&
  css`
    transform: ${isShow ? `translateY(0%)` : `translateY(100%)`};
    transition: transform 300ms ease-out;
  `}
`;

export default AlertPotal;
