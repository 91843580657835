const stringifyParameters = (parameters = {}) => {
  const paramStr = Object.keys(parameters).reduce((curr, key) => {
    if (parameters[key]) {
      curr += `&${key}=${parameters[key]}`;
    }
    return curr;
  }, '');
  console.debug('Generated OneLink parameters', paramStr);
  return paramStr;
};

const getParameterValue = (
  currentURLParams,
  config = { keys: [], overrideValues: {}, defaultValue: '' }
) => {
  //exit when config object structure is not valid
  if (!((config?.keys && Array.isArray(config.keys)) || config?.defaultValue)) {
    console.error('Parameter config structure is wrong', config);
    return null;
  }

  const { keys = [], overrideValues = {}, defaultValue = '' } = config;

  const firstMatchedKey = keys.find(key => {
    //set the first match of key which contains also a value
    return !!currentURLParams[key];
  });

  if (firstMatchedKey) {
    const value = currentURLParams[firstMatchedKey];
    //in case the value exists:
    //check if it exists in the overrideValues object, when exists - replace it
    //otherwise return default value
    return overrideValues[value] || value || defaultValue;
  }
  return defaultValue;
};

const getURLParametersKV = urlSearch => {
  const currentURLParams = decodeURIComponent(urlSearch)
    .replace('?', '')
    .split('&')
    .reduce((curr, param) => {
      const kv = param.split('=');
      if (!!kv[0] && !!kv[1]) {
        curr[[kv[0]]] = kv[1];
      }
      return curr;
    }, {});
  console.debug('Generated current parameters object', currentURLParams);
  return currentURLParams;
};

export { stringifyParameters, getParameterValue, getURLParametersKV };
