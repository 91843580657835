import { ReactElement } from 'react';

import { css } from '@emotion/react';

import Button from '@components/common/Button';
import Image from '@components/common/Image';

import { palette } from '@lib/styles/palette';

import IconBasic from '@asset/icons/joongna_icon.png';

interface AppDownloadModalPropsType {
  onHide: () => void;
  onClick?: () => void;
}

function AppDownloadModal({
  onHide,
  onClick,
}: AppDownloadModalPropsType): ReactElement {
  return (
    <div
      role="dialog"
      aria-labelledby="dialogTitle"
      aria-describedby="dialogContent"
      css={containerStyle}
    >
      <div id="dialogContent">
        <div className="logoContainer">
          <Image src={IconBasic} alt="jn-logo" className="logo" />
        </div>
        <h2>중고나라</h2>
        <p>2,400만 회원 국내 최대 중고장터</p>

        <div className="info">
          <p>해당 기능은 앱에서 이용 가능합니다.</p>
        </div>
      </div>

      <Button
        type="primary"
        fullWidth={true}
        replace={true}
        backgroundColor="#000000"
        onClick={() => {
          if (onClick) onClick();
          onHide();
        }}
      >
        중고나라 앱 사용하기
      </Button>
      <button css={closeButtonStyle} onClick={onHide}>
        괜찮습니다.
      </button>
    </div>
  );
}

const containerStyle = css`
  position: relative;
  width: 90vw;
  max-width: 25rem;
  background-color: white;
  border-radius: 0.5rem;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  #dialogContent {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    h2,
    p {
      margin-bottom: 1rem;
    }

    h2 {
      font-weight: 700;
    }

    .logoContainer {
      width: 4rem;
      border: 1px solid ${palette.gray0};
      padding: 0.2rem;
      border-radius: 35%;
      margin-bottom: 1rem;
    }

    .logo {
      width: 100%;
      height: 100%;
    }

    .info {
      padding: 1rem;
      width: 100%;
      border-top: 1px solid ${palette.gray0};
      text-align: center;
    }
  }
`;

const closeButtonStyle = css`
  position: absolute;
  bottom: -3rem;
  left: 50%;
  transform: translateX(-50%);
  height: 2rem;

  color: white;
  background-color: transparent;
  border-bottom: 1px solid white;
`;

export default AppDownloadModal;
