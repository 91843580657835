const removeQueryString = (name: string, url = window.location.href) => {
  let queryList = [];
  const queryString = url.indexOf('?') > -1 ? url.split('?')[1] : '';

  if (queryString !== '') {
    queryList = queryString.split('&');
    const queryIndex = queryList.findIndex((q) => {
      return q.split('=')[0] === name;
    });
    if (queryIndex > -1) {
      queryList.splice(queryIndex, 1);
    }

    if (queryList.length) return `?${queryList.join('&')}`;
  }
  return '';
};

export default removeQueryString;
