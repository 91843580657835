import * as Device from 'react-device-detect';

export const isDesktop = (): boolean => {
  return !Device.isMobile;
};

export const isMobile = (): boolean => {
  return Device.isMobile;
};

/** android 판별 */
export const isAndroid = (): boolean => {
  return Device.isAndroid;
};

/** ios 판별 */
export const isiPhone = (): boolean => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent);
};

export const isIOS = (): boolean => {
  return Device.isIOS;
};

export const isMobileSafari = (): boolean => {
  return Device.isMobileSafari;
};

export const isSafari = (): boolean => {
  return Device.isSafari;
};

export const isChrome = (): boolean => {
  return Device.isChrome;
};

export const isChromium = (): boolean => {
  return Device.isChromium;
};

export function isFaceBookWebView(
  ua = window.navigator.userAgent || navigator.vendor,
) {
  return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1;
}

export function isInstagramWebView() {
  const ua = window.navigator.userAgent || navigator.vendor;
  return ua.indexOf('Instagram') > -1 || ua.indexOf('instagram') > -1;
}
