import { ResponseComposition, RestContext, RestRequest } from 'msw';

type HandlerType = {
  (req: RestRequest, res: ResponseComposition, ctx: RestContext): any;
};

const deliveryHistoryHandler: HandlerType = (req, res, ctx) => {
  return res(
    ctx.status(422),
    ctx.json({
      meta: {
        code: 400320,
        message:
          '택배사 배송조회 서비스가 점검중 입니다.\n잠시 후 다시 시도해주세요.',
      },
      error: {
        code: 400320,
        detail:
          '택배사 배송조회 서비스가 점검중 입니다.\n잠시 후 다시 시도해주세요.',
        title:
          'com.joongna.api.delivery.exception.SweetTrackerException: 택배사 배송조회 서비스가 점검중 입니다.\n잠시 후 다시 시도해주세요.',
        status: 422,
      },
    }),
  );
};

export { deliveryHistoryHandler };
export default deliveryHistoryHandler;
