import { ReactElement, useEffect, useRef, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { toastAtom, toastIsTriggeredSelector } from '@recoils/popup/toast';

import { palette } from '@lib/styles/palette';

const Toast = (): ReactElement => {
  // state for toast animation (toast Show state true at frist)
  const [isShow, setIsShow] = useState(false);
  const { message, isTriggered } = useRecoilValue(toastAtom);
  const setIsTriggered = useSetRecoilState(toastIsTriggeredSelector);

  const toastRef = useRef<HTMLDivElement>(null);
  const timeOut = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isShow) {
      if (timeOut.current) clearTimeout(timeOut.current);
      timeOut.current = setTimeout(() => {
        setIsShow(false);
      }, 3000);
    }
  }, [isShow, isTriggered]);

  useEffect(() => {
    if (isTriggered) {
      setIsTriggered(false);
      setIsShow(true);
    }
  }, [isTriggered]);

  return (
    <ToastWrap isShowToast={isShow} ref={toastRef}>
      <div className="toastWrapper">
        <div className="toast">
          <span className="text">
            {message?.split('\n')?.map((text) => (
              <span key={text}>{text}</span>
            ))}
          </span>
        </div>
      </div>
    </ToastWrap>
  );
};

interface ToastWrapProps {
  isShowToast: boolean;
}
const toastAnimationStyle = ({ isShowToast }: ToastWrapProps) =>
  css`
    bottom: ${isShowToast ? '3rem' : '-3rem'};
    opacity: ${isShowToast ? 1 : 0};
  `;

const ToastWrap = styled.div<ToastWrapProps>`
  .toastWrapper {
    width: 100%;
    height: auto;
    text-align: center;
    position: fixed;
    transition: all 0.3s ease-in-out;
    z-index: 9999;

    .toast {
      padding: 10px 20px;
      width: -moz-fit-content;
      width: fit-content;
      margin: 0 auto;
      background-color: rgba(51, 51, 51, 0.8);
      border-radius: 50px;
    }

    .text {
      font-size: 15px;
      line-height: 22px;
      color: ${palette.white};
      display: flex;
      flex-direction: column;
    }
    ${toastAnimationStyle};
  }
`;

export default Toast;
