/* 색상 팔레트 */
export const palette = {
  /* main Color */
  orange1: '#FFDFC7',
  orange2: '#FFD9BD',
  orange3: '#FFCAA3',
  orange4: '#FFB782',
  orange5: '#FFA15C',
  orange6: '#FF9242',
  orange7: '#FC842D',
  orange8: '#FA7414',
  orange9: '#FF6900',

  /* yellow */
  yellow8: '#FFB600',
  yellow9: '#F6E500',
  yellow10: '#FEE93E',

  /* red */
  red600: '#F85454',
  red700: '#FF3B30',
  red900: '#DA1E28',

  /* green */
  green7: '#47C482',
  naverGreen: '#00DE5A',
  jnGreen: '#0DCC5A', // 신규 리브랜딩 컬러 추가
  jnGreen1: '#2BEE1A', // 신규 리브랜딩 컬러 추가
  jnGreen2: '#cff9e0', // 신규 리브랜딩 컬러 추가
  jnGreen_text: '#0CB650', // 신규 리브랜딩 컬러 추가

  /* blue */
  blue5: '#0057B7',
  blue6: '#003569',
  blue7: '#0052CC',

  /* gray */
  gray0: '#F5F5F5', // Figma 참고
  gray1: '#EBEBEB', // Figma 참고
  gray2: '#E0E0E0', // Figma 참고
  gray3: '#DEE2E6',
  gray4: '#CCCCCC', // Figma 참고
  gray5: '#A3A3A3', // Figma 참고
  gray6: '#666666', // Figma 참고
  gray7: '#495057',
  gray8: '#343A40',
  gray9: '#212529',
  gray10: '#F8F8F8', // Figma 참고
  gray11: '#D8D8D8',
  gray12: '#858585',
  gray13: '#C7C7CD',
  gray14: '#9FA3A7',
  gray15: '#F1F1F1',
  gray16: '#9D9D9D',
  gray17: '#DDDDDD',
  gray18: '#C9C9C1',

  /* gray in orderPaymentDelivery */
  orderGray000: '#F7F8F9',
  orderGray100: '#F6F6F6',
  orderGray200: ' #E1E1E1',
  orderGray300: ' #E8E8E8',
  orderGray400: '#C5C5C5',
  orderGray600: ' #9B9B9B',
  orderGray700: ' #979797',
  orderGray800: ' #797979',
  orderGray900: '#0F1013',

  /* gray in Flutter - new Design System */
  gray100: '#F7F9FA',
  gray200: '#F1F4F6',
  gray300: '#DADEE5',
  gray400: '#C2C6CE',
  gray500: '#9CA3AF',
  gray600: '#787E89',
  gray700: '#5A616B',
  gray800: '#363C45',
  gray900: '#141313', // 191c21 -> 141313

  /* gray in Wiki */
  wikiGray1: '#FAFAFA',
  wikiGray2: '#434343',
  wikiGray3: '#858585',

  /* black transparent */
  opBlack05: '#00000005',
  opBlack2: '#00000020',
  opBlack3: '#00000030',
  opBlack4: '#00000040',
  opBlack5: '#00000050',
  opBlack6: '#00000060',
  opBlack8: '#00000080',

  /* white */
  white: '#FFFFFF',
  black: '#000000',

  /* black */
  black10: '#0A0400',
  black11: '#333333',

  jnSub1: '#FF5453',
  jnSub2: '#FFF5F4',
};

/* hover 색상 세트 */
interface ButtonColorProps {
  [color: string]: {
    background: string;
    color: string;
    hoverBackground: string;
  };
}

export const buttonColorMap: ButtonColorProps = {
  orange: {
    background: palette.orange9,
    color: 'white',
    hoverBackground: palette.orange8,
  },
  lightGray: {
    background: palette.gray3,
    color: palette.gray7,
    hoverBackground: palette.gray2,
  },
  gray: {
    background: palette.gray5,
    color: 'white',
    hoverBackground: palette.gray4,
  },
  darkGray: {
    background: palette.gray8,
    color: 'white',
    hoverBackground: palette.gray6,
  },
  transparent: {
    background: 'none',
    color: palette.orange6,
    hoverBackground: palette.orange1,
  },
};
