/* eslint-disable react/jsx-props-no-spreading */
import { Route } from 'react-router-dom';

import { RouteOption } from '@type/web/route';

interface RoutesType {
  route: RouteOption;
}

const Routes = (route: any): JSX.Element => {
  const { path, routes, component: RouteComponent } = route;
  return (
    <Route
      path={path}
      render={(props) => <RouteComponent {...props} routes={routes} />}
    />
  );
};

export default Routes;
