import React, { ReactElement, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { css } from '@emotion/react';

import usePortal from '@hook/usePortal';

import { PageType } from '@type/webView/comment';

import Icon from '@components/common/Icon';

import useOpenAppWithWebUrl from '@lib/hook/deepLink/useOpenAppWithWebUrl';
import { palette } from '@lib/styles/palette';
import { getToken } from '@lib/utils/auth';
import * as AppInterface from '@lib/utils/interface';
import { isAppDevice } from '@lib/utils/webview';

import AppDownloadModal from '../AppDownloadModal';

interface CommentRouteButtonPropsType {
  type: PageType;
  typeSeq: number;
}

export default function CommentCreateRouteButton({
  type,
  typeSeq,
}: CommentRouteButtonPropsType): ReactElement {
  const openAppWithWebUrl = useOpenAppWithWebUrl();
  const [isLink, setIsLink] = useState(true);

  useEffect(() => {
    setIsLink(isAppDevice());
  }, []);

  const { PopUp, onHidePopUp, onShowPopUp } = usePortal();

  return (
    <>
      {isLink ? (
        <Link
          to={`/${type}/detail/${typeSeq}/comment`}
          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
            if (getToken()) return;

            e.preventDefault();
            AppInterface.showLoginPopup();
          }}
        >
          <CommentRouteStyleDiv />
        </Link>
      ) : (
        <>
          <button
            css={buttonStyle}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              onShowPopUp();
            }}
          >
            <CommentRouteStyleDiv />
          </button>
          <PopUp>
            <AppDownloadModal
              onClick={() => {
                openAppWithWebUrl(window.location.href);
              }}
              onHide={onHidePopUp}
            />
          </PopUp>
        </>
      )}
    </>
  );
}

const buttonStyle = css`
  width: 100%;
  background-color: transparent;
  color: ${palette.gray700};
`;

function CommentRouteStyleDiv(): ReactElement {
  return (
    <div css={commentRouteStyleDiv}>
      <div className="text-area">
        댓글 쓰기
        <Icon icon="camera" stroke={palette.gray2} size={30} />
      </div>
      <div className="submit">등록</div>
    </div>
  );
}

const commentRouteStyleDiv = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid ${palette.gray2};
  margin-bottom: 2rem;

  .text-area {
    color: ${palette.gray4};
    width: 100%;
    padding: 0.7rem 1rem;
    border-right: 1px solid ${palette.gray2};
    font-size: 1.1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .submit {
    min-width: 4rem;
    padding: 1rem;
    color: ${palette.gray700};
  }
`;
