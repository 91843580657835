import { forwardRef, MouseEventHandler, PropsWithChildren, Ref } from 'react';
import { Link } from 'react-router-dom';

import { css, SerializedStyles } from '@emotion/react';

import { palette } from '@lib/styles/palette';
import typography from '@lib/styles/typography';

import ButtonLoading from '../Loading/ButtonLoading';

interface BoxButtonProps extends BoxFeatureButtonProps {
  level: 1 | 2 | 3;
  type: 'solid' | 'outline' | 'solidGreen';
}

interface BoxFeatureButtonProps {
  className?: string;
  css?: SerializedStyles;
  disabled?: boolean;
  loading?: boolean;
  onClick?: MouseEventHandler | (() => void);
  to?: string | false;
}

/**
 * BoxButton 기능부분 component
 *
 * `loading`은 `disabled`와 마찬가지로 버튼을 비활성화시키며, 추가로 innerText를 loading lottie로 대치.
 */
const BoxFeatureButton = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  PropsWithChildren<BoxFeatureButtonProps>
>(({ to, children, disabled, loading, ...props }, ref): JSX.Element => {
  const anchorRef = ref as Ref<HTMLAnchorElement>;
  const buttonRef = ref as Ref<HTMLButtonElement>;

  if (to && !(disabled && loading)) {
    return to.startsWith('/') && !to.startsWith('//') ? (
      <Link to={to} {...props} ref={anchorRef}>
        {children}
      </Link>
    ) : (
      <a href={to} target="_blank" rel="noreferrer" ref={anchorRef} {...props}>
        {children}
      </a>
    );
  }
  // loading 인 경우에도 버튼액션이 동작하지 않도록 disabled 설정.
  return (
    <button disabled={disabled || loading} ref={buttonRef} {...props}>
      {children}
    </button>
  );
});

BoxFeatureButton.displayName = 'BoxFeatureButton';

/**
 * BoxButton component
 * @param level `1 | 2 | 3`
 * @param type `solid | outline`
 * @param loading 버튼 내부에 loading animation 노출여부
 * @param ...props to, onClick, disabled, className, css
 */
const BoxButton = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<BoxButtonProps>
>(
  (
    {
      children,
      level,
      type,
      loading,
      ...props // to, onClick, disabled, className
    },
    ref,
  ): JSX.Element => {
    return (
      <BoxFeatureButton
        css={boxButtonStyle(type, level, loading)}
        loading={loading}
        ref={ref}
        {...props}
      >
        {/* loading 상태는 solid type에만 존재함. */}
        {loading && type === 'solid' ? <ButtonLoading /> : children}
      </BoxFeatureButton>
    );
  },
);

BoxButton.displayName = 'BoxButton';

const buttonStyle = css`
  position: relative;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  > *:not(:last-child) {
    margin-right: 4px;
  }
`;

const solidStyle = (loading: BoxButtonProps['loading']) => css`
  background-color: ${palette.gray900};
  color: ${palette.white};
  border: 1px solid ${palette.gray900};

  // global.less(antd) 때문에 버튼 상태별로 color값을 직접 설정해줘야함.
  :hover,
  :active,
  :disabled {
    color: ${palette.white};
  }

  :disabled {
    background-color: ${loading ? palette.gray900 : palette.gray400};
    border: 1px solid ${loading ? palette.gray900 : palette.gray400};
    opacity: ${loading ? 0.5 : 1};
  }

  :active:not(:disabled) {
    ::before {
      content: '';
      background-color: ${palette.black};
      opacity: 0.1;
      border-radius: inherit;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }
`;

const outlineStyle = css`
  background-color: ${palette.white};
  color: ${palette.black};
  border: 1px solid ${palette.gray400};

  // global.less(antd) 때문에 버튼 상태별로 color값을 직접 설정해줘야함.
  :hover,
  :active {
    color: ${palette.black};
  }

  :disabled {
    border: 1px solid ${palette.gray300};
    color: ${palette.gray500};
    :hover {
      color: ${palette.gray500};
    }
  }

  :active:not(:disabled) {
    ::before {
      content: '';
      background-color: ${palette.black};
      opacity: 0.05;
      border-radius: inherit;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
`;

const solidGreenStyle = (loading: BoxButtonProps['loading']) => css`
  background-color: ${palette.jnGreen};
  color: ${palette.white};
  border: 1px solid ${palette.jnGreen};

  // global.less(antd) 때문에 버튼 상태별로 color값을 직접 설정해줘야함.
  :hover,
  :active,
  :disabled {
    color: ${palette.white};
  }

  :disabled {
    background-color: ${loading ? palette.jnGreen : palette.gray400};
    border: 1px solid ${loading ? palette.jnGreen : palette.gray400};
    opacity: ${loading ? 0.5 : 1};
  }

  :active:not(:disabled) {
    ::before {
      content: '';
      background-color: ${palette.jnGreen};
      opacity: 0.1;
      border-radius: inherit;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }
`;

const lv1Style = css`
  ${typography.btn1}
  width: 100%;
  height: 52px;
`;

const lv2Style = css`
  ${typography.btn2}
  width: 100%;
  height: 44px;
  padding-left: 16px;
  padding-right: 16px;
`;

const lv3Style = css`
  ${typography.d1}
  height: 36px;
  padding-left: 12px;
  padding-right: 12px;
`;

export const boxButtonStyle = (
  type: BoxButtonProps['type'],
  level: BoxButtonProps['level'],
  loading: BoxButtonProps['loading'],
): SerializedStyles => css`
  ${buttonStyle}
  ${level === 1 && lv1Style}
  ${level === 2 && lv2Style}
  ${level === 3 && lv3Style}
  ${type === 'solid' && solidStyle(loading)}
  ${type === 'outline' && outlineStyle}
  ${type === 'solidGreen' && solidGreenStyle(loading)}
`;

export default BoxButton;
