import { DOMAIN } from '@lib/constants/const';
import linkInfos from '@lib/constants/linkInfos';

// web path 또는 app의 path를 통해, 해당 path가 어느 해당되는 web & app 의 link 정보를 가진 객체를 리턴
// TODO: app path로 link 정보 찾는 부분 추가 필요
const getLinkInfo = (linkInfoHint: { web?: string; app?: string }) => {
  let routerInfo = null;
  // web path로 link 정보 찾기
  if (linkInfoHint?.web) {
    let routePath = linkInfoHint?.web;
    routePath = routePath?.replace(DOMAIN || '', '');
    routerInfo = linkInfos.find((l) => {
      if (!l?.web) return false;
      const pathArr = l?.web?.split('/');
      const pathWithoutParams = pathArr
        ?.filter((p) => !p.startsWith(':'))
        ?.join('/');
      return routePath.includes(pathWithoutParams);
    });
  }
  // else {
  //
  // }
  return routerInfo;
};

export default getLinkInfo;
