import iconv from 'iconv-lite';

function getUTF8(string: string) {
  const check = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

  if (!check.test(string)) {
    const buf = iconv.encode(string, 'iso-8859-1');
    const str = iconv.decode(buf, 'utf8');
    return str;
  }
  return string;
}

export default getUTF8;
