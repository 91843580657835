import { TERMS_URL } from './const';

/** common */
export const TRADE_METHOD = {
  all: 'ALL',
  post: 'POST',
  meet: 'MEET',
  pickup: 'PICKUP',
  meet_post: 'MEET_POST',
  meet_pickup: 'MEET_PICKUP',
  post_pickup: 'POST_PICKUP',
  etc: 'ETC',
  coupon: 'COUPON',
} as const;

export const DEAL_TYPE = {
  post: TRADE_METHOD.post,
  meet: TRADE_METHOD.meet,
  pickup: TRADE_METHOD.pickup,
  etc: TRADE_METHOD.etc,
  coupon: TRADE_METHOD.coupon,
} as const;

export const DEAL_TYPE_NAME = {
  POST: '택배거래',
  MEET: '직거래',
  PICKUP: '편의점픽업',
  ETC: '기타',
  COUPON: '',
} as const;

/** oreder */
export const ORDER_TYPE = {
  default: 0,
  // 안전거래
  kb: 14,
  settle: 50,
  kakao: 60,
  naver: 70,
  galaxia: 80,
  // 기타 결제
  delivery: 5,
  cvsDelivery: 6,
  direct: 7,
  thirdParty: 8,
  etc: 9,
  cafe: 31,
  // unicro 결제수단 (추후 제거 예정)
  unicroCard: 11,
  unicroBackTransfer: 12,
  unicroDepositLess: 13,

  // 즉시송금 ?
  fastTransferPayco: 2,
  fastTransferKakaoPay: 3,
  fastTransferToss: 4,

  // 중고차 서비스 중단 상태이지만 주문상태에 따른 잔여 이슈 대비 이관
  usedCar25: 25,
  usedCar26: 26,
  usedCar27: 27,
  usedCar28: 28,
} as const;

export const PAYMENT_TYPE = {
  settle: 'SETTLE',
  galaxia: 'GALAXIA',
  kb: 'KB',
  kakao: 'KAKAO',
  naver: 'NAVER',
  payco: 'PAYCO',
  toss: 'TOSS',
  unicro: 'UNICRO',
  payletter: 'PAY_LETTER',
} as const;

export const PAYMENT_DETAIL = {
  settle: {
    card: 'SETTLE_CARD',
    depositless: 'SETTLE_DEPOSITLESS',
    bankTransfer: 'SETTLE_BANK_TRANSFER',
  },
  galaxia: {
    galaxiaPhone: 'GALAXIA_PHONE',
  },
  kb: {
    appCard: 'KB_APP_CARD',
  },
  kakao: {
    pay: 'KAKAO_PAY',
  },
  naver: {
    pay: 'NAVER_PAY',
  },
  payco: {
    pay: 'PAYCO',
  },
  toss: {
    pay: 'TOSS_PAY',
  },
  unicro: {
    depositless: 'UNICRO_DEPOSITLESS',
  },
  payletter: {
    card: 'PAY_LETTER_CARD',
    depositless: 'PAY_LETTER_DEPOSITLESS',
  },
} as const;

export const PAY_METHOD_TYPE = {
  money: 'MONEY',
  remittance: 'REMITTANCE',
  card: 'CARD',
  accountTransfer: 'ACCOUNT_TRANSFER',
  nPoint: 'NPOINT',
  phonepay: 'PHONE',
  point: 'POINT',
} as const;

export const CASH_RECEIPT_TYPE = {
  personal: 'PERSONAL',
  corporate: 'CORPORATE',
} as const;

export const CASH_RECEIPT_DETAIL = {
  personal: {
    phoneNumber: 'PHONE_NUMBER',
    cashReceiptCardNo: 'CASHRECEIPT_CARD_NO',
  },
  corporate: {
    businessLicenseNumber: 'BUSINESS_LICENSE_NUMBER',
    cashReceiptCardNo: 'CASHRECEIPT_CARD_NO',
  },
} as const;

export const ISSUE_TYPE = {
  cashReceipt: 'CASH_RECEIPT',
  taxBill: 'TAX_BILL',
  none: 'NONE',
} as const;

export const TERM_DATA_TYPE = {
  pg: 'PG',
  common: 'COMMON',
  pccc: 'PCCC',
} as const;

export const DELIVERY_TERM_DATA_TYPE = {
  common: 'COMMON',
  lotte: 'LOTTE',
};

export const CAFE_POSTING_TICKET_TERM_DATA_TYPE = {
  common: 'COMMON',
};
export const TERM_DATA_LIST = [
  {
    id: PAYMENT_TYPE.settle,
    type: TERM_DATA_TYPE.pg,
    name: '중고나라 페이 서비스 이용약관',
    url: `${TERMS_URL}/Order/settleTerms.html`,
  },
  {
    id: PAYMENT_TYPE.kb,
    type: TERM_DATA_TYPE.pg,
    name: '개인정보 제 3자 제공 동의',
    url: `${TERMS_URL}/Order/kbTerms.html`,
  },
  {
    id: 'PRIVACY',
    type: TERM_DATA_TYPE.common,
    name: '개인정보 수집 및 이용 동의',
    url: `${TERMS_URL}/Order/privacyTerms.html`,
  },
  {
    id: 'SELLER',
    type: TERM_DATA_TYPE.common,
    name: '개인정보 제3자 제공 동의',
    url: `${TERMS_URL}/Order/sellerTerms.html`,
  },
  {
    id: 'PCCC',
    type: TERM_DATA_TYPE.pccc,
    name: '개인통관고유부호 및 개인정보 제공 동의',
    url: `${TERMS_URL}/Order/pcccTerms.html`,
  },
];
export const CAFE_POSTING_TICKET_TERM_DATA_LIST = [
  {
    id: `${CAFE_POSTING_TICKET_TERM_DATA_TYPE.common}_common`,
    type: CAFE_POSTING_TICKET_TERM_DATA_TYPE.common,
    name: '셀러 지원 센터 통합 이용약관',
    url: `${TERMS_URL}/Seller/TermsOfService/TermsOfService_v`,
  },
  {
    id: `${CAFE_POSTING_TICKET_TERM_DATA_TYPE.common}_payment`,
    type: CAFE_POSTING_TICKET_TERM_DATA_TYPE.common,
    name: '셀러 지원 센터 카페 게시 이용권 결제 이용약관',
    url: `${TERMS_URL}/Order/cafePostingTicketPaymentTerms`,
  },
];

export const DELIVERY_TERM_DATA_LIST = [
  {
    id: `${DELIVERY_TERM_DATA_TYPE.common}_DELIVERY`,
    type: DELIVERY_TERM_DATA_TYPE.common,
    name: '개인정보 수집 동의',
    url: `${TERMS_URL}/Delivery/common/privacyTerms.html`,
    isRequired: true,
  },
  {
    id: `${DELIVERY_TERM_DATA_TYPE.common}_PAY`,
    type: DELIVERY_TERM_DATA_TYPE.common,
    name: '중고나라 페이 서비스 유의사항',
    url: `${TERMS_URL}/Delivery/common/jnPayTerms.html`,
    isRequired: true,
  },
  {
    id: `${DELIVERY_TERM_DATA_TYPE.common}_PRIVACY`,
    type: DELIVERY_TERM_DATA_TYPE.common,
    name: '개인정보의 제3자 제공',
    url: `${TERMS_URL}/Delivery/common/lotteTerms.html`,
    isRequired: true,
  },
  {
    id: `${DELIVERY_TERM_DATA_TYPE.lotte}_DELIVERY`,
    type: DELIVERY_TERM_DATA_TYPE.lotte,
    name: '택배 표준 약관',
    url: `${TERMS_URL}/Delivery/delivery/deliveryTerms.html`,
    isRequired: true,
  },
  {
    id: `${DELIVERY_TERM_DATA_TYPE.lotte}_PRIVACY`,
    type: DELIVERY_TERM_DATA_TYPE.lotte,
    name: '개인정보처리방침',
    url: `${TERMS_URL}/Delivery/delivery/privacyTerms.html`,
    isRequired: true,
  },
  {
    id: `${DELIVERY_TERM_DATA_TYPE.lotte}_LOCATION`,
    type: DELIVERY_TERM_DATA_TYPE.lotte,
    name: '위치기반서비스 이용약관',
    url: `${TERMS_URL}/Delivery/delivery/locationServiceTerms.html`,
    isRequired: true,
  },
  {
    id: `${DELIVERY_TERM_DATA_TYPE.lotte}_PRODUCT`,
    type: DELIVERY_TERM_DATA_TYPE.lotte,
    name: '물품 정보 유의 사항',
    url: `${TERMS_URL}/Delivery/delivery/productInfoTerms.html`,
    isRequired: true,
  },
  {
    id: `${DELIVERY_TERM_DATA_TYPE.lotte}_MARKETING`,
    type: DELIVERY_TERM_DATA_TYPE.lotte,
    name: '택배 서비스를 위한 개인정보 제3자 제공',
    url: `${TERMS_URL}/Delivery/delivery/sevenElevenTerms.html`,
    isRequired: true,
  },
] as const;

export const HOMEPICK_DELIVERY_TERM_DATA_LIST = [
  {
    id: `${DELIVERY_TERM_DATA_TYPE.common}_DELIVERY`,
    type: DELIVERY_TERM_DATA_TYPE.common,
    name: '개인정보 수집 동의',
    url: `${TERMS_URL}/Delivery/common/privacyTerms_homepick.html`,
    isRequired: true,
  },
  {
    id: `${DELIVERY_TERM_DATA_TYPE.common}_PAY`,
    type: DELIVERY_TERM_DATA_TYPE.common,
    name: '중고나라 페이 서비스 유의사항',
    url: `${TERMS_URL}/Delivery/common/jnPayTerms_homepick.html`,
    isRequired: true,
  },
  {
    id: `${DELIVERY_TERM_DATA_TYPE.common}_PRIVACY`,
    type: DELIVERY_TERM_DATA_TYPE.common,
    name: '개인정보의 제3자 제공',
    url: `${TERMS_URL}/Delivery/common/lotteTerms_homepick.html`,
    isRequired: true,
  },
  {
    id: `${DELIVERY_TERM_DATA_TYPE.lotte}_DELIVERY`,
    type: DELIVERY_TERM_DATA_TYPE.lotte,
    name: '택배 표준 약관',
    url: `${TERMS_URL}/Delivery/delivery/deliveryTerms.html`,
    isRequired: true,
  },
  {
    id: `${DELIVERY_TERM_DATA_TYPE.lotte}_PRIVACY`,
    type: DELIVERY_TERM_DATA_TYPE.lotte,
    name: '개인정보처리방침',
    url: `${TERMS_URL}/Delivery/delivery/privacyTerms.html`,
    isRequired: true,
  },
  {
    id: `${DELIVERY_TERM_DATA_TYPE.lotte}_LOCATION`,
    type: DELIVERY_TERM_DATA_TYPE.lotte,
    name: '위치기반서비스 이용약관',
    url: `${TERMS_URL}/Delivery/delivery/locationServiceTerms.html`,
    isRequired: true,
  },
  {
    id: `${DELIVERY_TERM_DATA_TYPE.lotte}_PRODUCT`,
    type: DELIVERY_TERM_DATA_TYPE.lotte,
    name: '물품 정보 유의 사항',
    url: `${TERMS_URL}/Delivery/delivery/productInfoTerms.html`,
    isRequired: true,
  },
] as const;

export const BANKS = [
  {
    code: '004',
    name: 'KB',
    desc: '국민은행',
    isSettle: true,
    isUnicro: true,
  },
  {
    code: '003',
    name: 'IBK',
    desc: '기업은행',
    isSettle: true,
    isUnicro: true,
  },
  {
    code: '020',
    name: 'WOORI',
    desc: '우리은행',
    isSettle: true,
    isUnicro: true,
  },
  {
    code: '081',
    name: 'KEBHANA',
    desc: 'KEB하나은행',
    isSettle: true,
    isUnicro: true,
  },
  {
    code: '088',
    name: 'SHINHAN',
    desc: '신한은행',
    isSettle: true,
    isUnicro: true,
  },
  { code: '011', name: 'NH', desc: '농협', isSettle: true, isUnicro: true },
  {
    code: '071',
    name: 'EPOST',
    desc: '우체국',
    isSettle: true,
    isUnicro: true,
  },
  {
    code: '032',
    name: 'BNK',
    desc: '부산은행',
    isSettle: true,
    isUnicro: false,
  },
  {
    code: '089',
    name: 'KBANK',
    desc: '케이뱅크',
    isSettle: true,
    isUnicro: false,
  },
  {
    code: '007',
    name: 'SH',
    desc: '수협',
    isSettle: false,
    isUnicro: true,
  },
  {
    code: '023',
    name: 'SC',
    desc: 'SC제일은행',
    isSettle: true,
    isUnicro: false,
  },
  {
    code: '034',
    name: 'KJ',
    desc: '광주은행',
    isSettle: true,
    isUnicro: false,
  },
] as const;

export const REQUIRED_CASH_RECEIPT = {
  notRequired: 'NOT_REQUIRED',
  required: 'REQUIRED',
} as const;

/** order status 주문 상태 */
export const ORDER_STATUS_TYPE = {
  default: '99', // 기본값
  defatultStartPay: '00', //  결제 시작
  payComplete: '01', // 결제 완료
  // 안전거래
  safePGPayInProgressCancel: '88', // PG 결제 진행 중 취소
  safeDeliveryStatusEnter: '02', // 중고나라 편의점택배 배송 현황 기입
  safeBuyConfirm: '03', // 구매확정
  safeReturnRequest: '05', // 반품신청
  safeReturnAccept: '06', // 반품수락
  safeReturnStatusEmter: '07', // 반품 배송현황 기입 (배송중)
  safeDepositLessDealCancel: '08', // 무통장 거래취소 (입금기간 만료/ 타회원의 결제 완료)
  safeDepositLessWait: '09', // 무통장 입금 예정
  safeReturnComplete: '10', // 반품완료
  safeDeliveryReady: '11', //  배송준비 (구 판매수락 )
  safeCVSDeliveryReserve: '21', // 편의점 택배 예약중
  safeDeliveryComplete: '23', // 배송완료
  safeRequestDealCancel: '80', // 거래 취소 요청
  safeSellerDealCancel: '81', // 판매자 취소
  safeBuyerDealCancel: '82', // 구매자 취소
  safeManagerDealCancel: '83', // 관리자 취소
  safeRequestSellerDealCancel: '84', // 구매자 취소 요청

  // 즉시 송금 - 페이코, 카카오페이, 토스
  easyTransferDealComplete: '60', // 거래 완료
  easyTransferWait: '61', // 송금 대기
  easyTransferFail: '62', // 송금 대기 후 실패

  // 직거래
  meetBuyerExist: '50', // 구매자 있음
  meetBuyerUnExist: '51', // 구매자 없음

  // 편의점 택배 거래
  CVSDeliveryDefault: '59', // 편의점 택배거래 기본값

  // 편의점 픽업
  편의점픽업_입고대기: '101',
  편의점픽업_픽업대기: '102',
  편의점픽업_픽업완료: '103',
  편의점픽업_회수진행중: '104',
  편의점픽업_회수완료: '105',
  편의점픽업_회수확정: '106', // 서버에서만 회수 확정 프론트에서는 회수완료로 표기
  편의점픽업_반품신청: '110',
  편의점픽업_반품입고대기: '111',
  편의점픽업_반품수령대기: '112',
  편의점픽업_반품픽업완료: '113',
  편의점픽업_반품완료: '118',
  편의점픽업_관리자회수지시: '119', // 프론트에서 관리자회수로 표기
  편의점픽업_관리자회수완료: '120', // 프론트에서 관리자회수로 표기

  // pickupReceivingWait: '101', // 입고대기
  // pickupForwardingWait: '102', // 입고완료 픽업대기
  // pickupCompleted: '103', // 픽업완료
  // pickupTakeBackRequest: '104', // 회수진행중(회수지시)
  // pickupTakeBackCompleted: '105', // 회수완료
  // pickupTakeBackCompletedRefund: '106', // 회수완료 - 서버에서만 회수 확정 프론트에서는 회수완료로 표기
  // pickupTakeBackAdminRequest: '119', // 관리자회수 - 관리자 회수 지시
  // pickupTackBackAdminCompleted: '120', // 관리자회수 - 관리자 회수 완료

  // pickupReturnRequest: '110', // 반품신청
  // pickupReturnReceivingWait: '111', // 반품 입고대기
  // pickupReturnForwardingWait: '112', // 반품 수령대기
  // pickupReturnReceivingCompleted: '113', // 반품 픽업완료
  // pickupCompletedRefund: '118', // 반품완료 (결제취소상태)

  // 인증상품 (레거시)
  certifiedProductDealComplete: '30', // 인증상품 거래완료
  certifiedProductOrderCancel: '35', // 인증상품 주문 취소

  // 유니크로
  unicroDepositWait: '13', // 입금대기
  unicroPayComplete: '31', // 결제완료
  unicroInDelivery: '32', // 배송중
  unicroInDeliveryF: '32F', // 배송중 deliveryF, delivery 차이 확인 필요
  unicroDealComplete: '33', // 거래완료
  unicroReturnRequest: '51', // 반품신청
  unicroReturnApprove: '52', // 반품동의
  unicroReturnInDelivery: '53', // 반품 배송중
  unicroTransferCancel: '14', // 입금 취소
  unicroReturnComplete: '54', // 반품 완료
  unicroOrderAccept: '61', // 주문 확인
  unicroSellCancelComplete: '36', //  판매 취소 완료
  unicroPayCancelComplete: '35', // 결제 취소 완료
  unicroManagerDealCancel: ' 37', // 운영자 거래 취소
  unicroPayCancelRequest: '34', // 결제 취소 요청
} as const;

// 구 거래내역 타입
export enum PreviousOrderHistoryType {
  buy = 'buy',
  sell = 'sell',
}
/** order history */
// 거래내역 타입
export enum OrderHistoryType {
  purchases = 'purchases',
  sales = 'sales',
}

// 거래내역 타입 KOR
export const OrderHistoryTypeStr = {
  purchases: '구매',
  sales: '판매',
};

// 편의점 택배 사용 여부 타입
export enum JnDeliveryType {
  USE = 'USE',
  NOT_USE = 'NOT_USE',
}

// 취소 사유
export const ORDER_CANCEL_TYPE = {
  buy: 'buy', // 구매- 취소
  buyRequest: 'buyRequest', // 구매- 취소 요청
  sell: 'sell', // 판매- 취소
  return: 'return', // 반품 신청
} as const;

/** delivery */
export const deliveryCorpName = {
  GS: 'GS 편의점 택배',
  GS반값택배: 'GS 반값택배',
  CU: 'CU 편의점 택배',
  CU알뜰택배: 'CU 알뜰택배',
  세븐일레븐편의점택배: '세븐일레븐 편의점택배',
  우체국택배: '우체국택배',
  CJ대한통운: 'CJ대한통운',
  로젠택배: '로젠택배',
  롯데택배: '롯데택배',
  한진택배: '한진택배',
  경동택배: '경동택배',
  우편등기: '우편등기',
  대신택배: '대신택배',
  홈픽: '홈픽',
  일양로지스: '일양로지스',
  천일택배: '천일택배',
  합동택배: '합동택배',
  GSMNtoN: 'GSM NtoN',
  // 세븐일레븐택배: '세븐일레븐 택배',
} as const;

export const deliveryCorpCode = {
  '005': 'CJ대한통운',
  '002': '우체국택배',
  '018': '우편등기',
  '026': 'GS', // (구)GS 택배 코드
  '126': 'GS', // GS 택배 코드
  '127': 'GS반값택배',
  '004': '롯데택배',
  '008': '로젠택배',
  '010': '한진택배',
  '022': '대신택배',
  '023': '일양로지스',
  '024': '경동택배',
  '025': '천일택배',
  '500': 'CU',
  '501': 'CU알뜰택배',
  '030': '합동택배',
  '031': 'GSMNtoN',
  '555': '홈픽',
  '040': '세븐일레븐편의점택배',
} as const;

export const deliveryStatus = {
  '99': '예약중',
  '01': '예약중',
  '02': '접수완료',
  '03': '배송중',
  '04': '배송완료',
  '09': '예약취소',
  '30': '배송완료/픽업대기',
  '31': '반송',
};

export const deliveryStatusMessage = {
  send: {
    '99': {
      default:
        '직접 예약을 취소하셨거나\n편의점 접수기간(72시간)이 만료되었습니다.',
      '555': '방문택배 예약을 취소하셨습니다.',
    },
    '09': {
      default:
        '직접 예약을 취소하셨거나\n편의점 접수기간(72시간)이 만료되었습니다.',
      '555': '방문택배 예약을 취소하셨습니다.',
    },
    '01': {
      default: '지정하신 방문배송 일시에 기사님이 상품을 회수할 예정입니다.',
      '500':
        '발급된 승인번호로 CU편의점 택배접수를 진행해주세요.\n72시간 이내 접수하지 않을 경우 예약이 취소됩니다.',
      '026':
        '발급된 승인번호로 GS편의점 택배접수를 진행해주세요\n72시간 이내 접수하지 않을 경우 예약이 취소됩니다.',
      '126':
        '발급된 승인번호로 GS편의점 택배접수를 진행해주세요\n72시간 이내 접수하지 않을 경우 예약이 취소됩니다.',
      '555': '지정하신 방문배송 일시에 기사님이 상품을 회수할 예정입니다.',
    },
    '02': {
      default:
        '판매자가 택배접수를 완료하였습니다.\n자세한 내용은 배송조회를 통해 확인해주세요.',
    },
    '03': { default: '택배기사님이 상품을 수거하여\n구매자에게 배송중입니다.' },
    '04': {
      default:
        '안전거래를 이용하신 경우\n구매자에게 구매확인 요청을 진행해주세요.',
    },
  },
  receive: {
    '99': {
      default:
        '판매자가 예약을 취소하였거나\n편의점 접수기간(72시간)이 만료되었습니다.',
      '555': '판매자가 방문택배 예약을 취소하였습니다',
    },
    '09': {
      default:
        '판매자가 예약을 취소하였거나\n편의점 접수기간(72시간)이 만료되었습니다.',
      '555': '판매자가 방문택배 예약을 취소하였습니다',
    },
    '01': {
      default: '조금만 기다려주세요.\n판매자가 택배를 예약하는 중입니다.',
    },
    '02': {
      default:
        '판매자가 택배접수를 완료하였습니다.\n자세한 내용은 배송조회를 통해 확인해주세요.',
    },
    '03': { default: '택배기사님이 상품을 수거하여\n회원님께 배송중입니다.' },
    '04': {
      default:
        '상품 잘 받으셨나요? 안전거래를 이용하셨다면,\n구매확인 후 거래후기를 작성해주세요.',
    },
  },
};

export const deliveryStepProgressStatus = {
  // 편의점 픽업
  PICKUP: {
    // 정상 canceledOrder: true
    true: {
      sell: ['결제완료', '주문확인', '입고완료', '픽업완료', '판매완료'],
      buy: ['결제완료', '주문확인', '입고완료', '픽업완료', '구매완료'],
    },
    // 반품 canceledOrder: false
    false: {
      sell: [
        '반품신청',
        '반품수락',
        '반품입고완료',
        '반품픽업완료',
        '반품완료',
      ],
      buy: ['반품신청', '반품수락', '반품입고완료', '반품픽업완료', '반품완료'],
    },
  },
  // 택배거래
  POST: {
    // 정상 canceledOrder: true
    true: {
      sell: ['결제완료', '주문확인', '배송중', '배송완료', '판매완료'],
      buy: ['결제완료', '주문확인', '배송중', '배송완료', '구매완료'],
    },
    // 반품 canceledOrder: false
    false: {
      sell: ['반품신청', '반품수락', '반품배송중', '반품완료'],
      buy: ['반품신청', '반품수락', '반품배송중', '반품완료'],
    },
  },
  // 직거래
  MEET: {
    sell: ['결제완료', '주문확인', '판매완료'],
    buy: ['결제완료', '주문확인', '구매완료'],
  },
};

export const pickupExpireDateMap = {
  입고기한: 'shopReceiveExpireDate', // 입고기한 (주문확인 + 5일)
  픽업기한: 'shopForwardExpireDate', // 픽업 기한(입고완료 + 3일)
  반품입고기한: 'shopReceiveExpireDate', // 반품 입고기한 (반품수락 + 3일)
  반품픽업기한: 'shopForwardExpireDate', // 반품 픽업 기한 (반품 입고 + 3일)
  회수기한: 'shopTakeBackExpireDate', // 회수 기한 (회수지시 + 1일)
} as const;

export const cvsDeliveryMethod = {
  CU: 'cu',
  GS: 'gs',
  SEVEN_ELEVEN: 'seven_eleven',
  CU_HALF: 'cu_half',
  LOTTE_HOME_PICK: 'lotte_home_pick',
} as const;

export const cvsCorpStatusName = {
  '500': 'CU',
  '026': 'GS', // (구)GS 택배 코드
  '126': 'GS',
  '501': 'CU_HALF',
  '040': 'SEVEN_ELEVEN',
  '004': 'LOTTE_HOME_PICK',
} as const;

export const deliveryCorpStatusCode = {
  show: 'SHOW',
  hide: 'HIDE',
  disabled: 'DISABLED',
  notice: 'NOTICE',
} as const;

export const cvsListStatusCode = {
  hidden: 'HIDDEN',
  blocked: 'BLOCKED',
  available: 'AVAILABLE',
} as const;

export const REVIEWER_TYPE = {
  buyer: 'BUYER',
  seller: 'SELLER',
} as const;

export const REVIEW_ITEM_TYPE = {
  manner: 'sellerFeedback',
  chat: 'replySpeedFeedback',
  product: 'descriptionMatchFeedback',
  transaction: 'satisfactionFeedback',
  block: 'isNoNextDealFeedback',
} as const;

export const REVIEW_GRADE_TYPE = {
  BEST: 2,
  GOOD: 1,
  UNSATISFACTION: 0,
} as const;

export const PICKUP_REVIEW_GRADE_TYPE = {
  SATISFACTION: 1,
  UNSATISFACTION: 0,
} as const;

export const OrderReviewTypeFilter = [
  {
    text: '전체 후기',
    value: 'ALL',
  },
  {
    text: '판매자 후기',
    value: 'SELLER',
  },
  {
    text: '구매자 후기',
    value: 'BUYER',
  },
] as const;

export const OrderReviewTypeStr = {
  ALL: '전체 후기',
  SELLER: '판매자 후기',
  BUYER: '구매자 후기',
};

// 거래내역 필터 : 기간
// 검색기간 (API Response Key)
export const OrderHistoryPeriod = [
  {
    text: '최근 1년',
    value: '',
  },
  {
    text: '1주일',
    value: '1',
  },
  {
    text: '1개월',
    value: '2',
  },
  {
    text: '3개월',
    value: '3',
  },
  {
    text: '6개월',
    value: '4',
  },
];

export const boxSizeObj = {
  SMALL: {
    title: '소형',
    description: '가로+세로+높이 80cm, 무게 2kg 이하',
    value: 'SMALL',
  },
  MEDIUM: {
    title: '중형',
    description: '가로+세로+높이 120cm, 무게 10kg 이하',
    value: 'MEDIUM',
  },
  LARGE: {
    title: '대형',
    description: '가로+세로+높이 160cm, 무게 20kg 이하',
    value: 'LARGE',
  },
};
