import { atom } from 'recoil';

import { UserStateInfoType } from '@type/api/base/user';

export const userStateInfo = atom<UserStateInfoType>({
  key: 'USER/USER_STATE_INFO',
  default: {
    userSeq: 0,
    status: {
      userSeq: 0,
      userId: '',
      userStatus: 0,
      userJoinType: 0,
      emailJoin: 0,
      naverJoin: 0,
      kakaoJoin: 0,
      facebookJoin: 0,
      paycoJoin: 0,
      appleJoin: 0,
      emailStatus: 0,
      naverStatus: 0,
      naverCafeStatus: 0,
      kakaoStatus: 0,
      facebookStatus: 0,
      paycoStatus: 0,
      appleStatus: 0,
    },
    marketing: {
      smsYn: 0,
      emailYn: 0,
      pushYn: 0,
      marketingAcceptDate: [],
      agree: true,
    },
    cafeUseYn: 0,
    safetyNoUseYn: 0,
    phoneNoShowYn: 0,
    userId: '',
    nickName: '',
    profileImgUrl: '',
    userPhoneNo: '',
    gaUserInfo: {
      gaUserId: '',
      jnUserId: '',
      partnerUserId: null,
      adid: '',
      gender: '',
      ageRange: 0,
      pointRange: 0,
      membershipDesc: '',
    },
    isThirdPartyConsent: true,
  },
});

export default { userStateInfo };
