/**
 * 댓글 달린 페이지 타입
 */
export enum PageType {
  Event = 'event',
  Product = 'product',
  Content = 'content',
}

export interface DeleteCommentPropsType {
  type: PageType;
  commentSeq: number;
}

export interface GetCommentListPropsType {
  type: PageType;
  typeSeq: number;
  startIndex: number;
}

export enum CommentStatusType {
  UserDelete,
  Show,
  ManagerDelte,
}

export interface CommentItemType {
  childCount: number;
  commentContent: string;
  commentSeq: number;
  commentStatus: CommentStatusType;
  deviceType: number;
  groupSeq: number;
  groupSort: number;
  imgUrl: string;
  nickName: string;
  parentNickName: string;
  parentStoreSeq: number;
  parentYn: number;
  profileImg: string;
  regDate: string;
  storeSeq: number;
  updateDate: string;
}

export interface GetCommentListReturnType {
  commentCount: number;
  commentList: CommentItemType[];
}

export interface PostCommentPropsType {
  input: PostCommentInputType;
  type: PageType;
  typeSeq: number;
  imgData: any;
}

export interface PutCommentPropsType {
  input: PutCommentInputType;
  type: PageType;
  typeSeq: number;
  commentSeq: number;
  imgData: any;
}

/** 댓글 타입
 * - commentContent: 댓글 내용
 * - imgUrl: 이미지 URL
 */
export interface CommentInputType {
  commentContent: string;
  imgUrl: string;
}

/** 댓글 등록 타입
 * - commentGroupSeq: 답글인 경우 댓글 그룹 일련번호 (답글 아니면 0)
 * - parentCommentStoreSeq: 답글인 경우 댓글의 작성자 스토어 seq (답글 아니면 0)
 */
export interface PostCommentInputType extends CommentInputType {
  commentGroupSeq: number;
  parentCommentStoreSeq: number;
}

/** 댓글 수정 타입
 * - parentStoreSeq: 상위 댓글 번호
 */
export interface PutCommentInputType extends CommentInputType {
  parentStoreSeq: number;
}

/** 댓글 수정 시 기존 댓글 정보 타입 */
export interface GetSpecificCommentWhenModifyReturnType {
  commentContent: string;
  imgUrl: string;
}

/** 답글시 상위 댓글 정보 타입 */
export interface GetSpecificCommentWhenReplyReturnType {
  commentContent: string;
  commentSeq: number;
  groupSeq: number;
  imgUrl: string;
  parentNickName: string;
  parentStoreSeq: number;
  parentProfileImg: string;
  updateDate: string;
}
