import { useEffect, useRef } from 'react';

import Lottie from 'lottie-web';

import { css } from '@emotion/react';

import { palette } from '@lib/styles/palette';

import LoadingLottieFile from './jn_all_loading.json';

type LoadingProp = {
  size?: 'full' | 'partial' | 'inherit';
};

export default function Loading({ size = 'full' }: LoadingProp): JSX.Element {
  const loadingRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (loadingRef.current) {
      Lottie.loadAnimation({
        container: loadingRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: LoadingLottieFile,
      });
    }
  }, []);

  return (
    <section css={containerStyle(size)}>
      <div ref={loadingRef} />
    </section>
  );
}

const containerStyle = (size: LoadingProp['size']) => css`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  ${size === 'full'
    ? css`
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 502;
        &::before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: ${palette.white};
          opacity: 0.5;
        }
      `
    : css`
        height: auto;
      `}

  ${size === 'inherit' &&
  css`
    width: 100%;
    height: 100%;
  `}

  & > div {
    max-width: 80px;
    max-height: 80px;
  }
`;
