import { useEffect, useState } from 'react';

import { ApiMeta, ApiResponse } from '@type/web/api';

interface ParamAxios<R> {
  request: (...params: any[]) => Promise<ApiResponse<R>>;
  parameter?: any | null;
  errorFunction?: ((...params: any[]) => void) | null;
}
interface ReturnAxios<R> {
  data: R | null;
  meta: ApiMeta | null;
  loading: boolean;
}

const useAxios = <R>({
  request,
  parameter = null,
  errorFunction = null,
}: ParamAxios<R>): ReturnAxios<R> => {
  const [response, setResponse] = useState<ApiResponse<R> | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetch = async () => {
    try {
      const fetchRequest = parameter !== null ? request(parameter) : request();
      const result: ApiResponse<R> = await fetchRequest;
      setResponse(result);
      setLoading(false);
    } catch (error) {
      // const {config,data,headers,request,status,statusText}= error.response
      if (errorFunction !== null) {
        errorFunction(error);
      }
    }
  };

  useEffect(() => {
    fetch();
  }, [parameter]);

  if (response !== null) {
    return { data: response.data, meta: response.meta, loading };
  }

  return { data: null, meta: null, loading };
};

export default useAxios;
