import React, { ReactElement, ReactNode } from 'react';

import { css, SerializedStyles } from '@emotion/react';

import useKeyDown from '@hook/useKeyDown';

interface PopUpChildren {
  children: ReactNode;
  opacity: SerializedStyles;
  onHide: () => void;
}

function Dim({ children, opacity, onHide }: PopUpChildren): ReactElement {
  /** ESC 눌렀을 경우 팝업 닫기 */
  useKeyDown('Escape', onHide);

  return (
    <div css={popUp}>
      <div className="dim-popup-container">{children}</div>
      <div css={[dim, opacity]} onClick={onHide} />
    </div>
  );
}

const popUp = css`
  display: flex;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  z-index: 500;
  bottom: 0;
`;

const dim = css`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.6;
`;

export default Dim;
