import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import * as AppInterface from '@lib/utils/interface';

const useGoBack = (): (() => void) => {
  const history = useHistory();

  const goBack = () => {
    const prevPage = window.location.href;

    history.goBack();
    setTimeout(() => {
      if (prevPage === window.location.href) {
        AppInterface.closeWebView();
      }
    }, 200);
  };

  return goBack;
};

export default useGoBack;
