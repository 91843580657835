import { KakaoData } from '@type/web/product';

import { interfaceShareKakao } from './interface';
import { isAppDevice } from './webview';

// 카카오 공유
export const sendKakaoLink = (data: KakaoData): void => {
  const content = {
    title: data.title,
    description: data.description,
    imageUrl: data.imageUrl,
    link: {
      mobileWebUrl: encodeURIComponent(data.link),
      webUrl: encodeURIComponent(data.link),
    },
  };
  if (data.imageWidth) {
    (content as any).imageWidth = data.imageWidth;
  }
  if (data.imageHeight) {
    (content as any).imageHeight = data.imageHeight;
  }
  if (isAppDevice()) {
    interfaceShareKakao({ ...content, shareUrl: data.link });
  } else if (window.Kakao) {
    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content,
      buttons: [
        {
          title: data.btn_title,
          link: {
            mobileWebUrl: data.link,
            webUrl: data.link,
          },
        },
      ],
      installTalk: true,
      fail: () => {
        if (isAppDevice()) {
          interfaceShareKakao(content);
        } else {
          console.log('fail');
        }
      },
    });
  }
};

// 페이스북 공유
export const sendFacebooklink = (link: string): void => {
  const shareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    link,
  )}`;

  if (isAppDevice()) {
    window.location.href = `jnapps3://?openweb=${shareLink}`;
  } else {
    window.open(shareLink);
  }
};

// Web Share API
export const shareURL = ({
  title,
  text,
  url,
}: {
  title: string;
  text?: string;
  url: string;
}): boolean => {
  let isWorking = false;

  if (navigator.share) {
    try {
      navigator.share({
        title,
        text,
        url,
      });

      isWorking = true;
    } catch {
      isWorking = false;
    }
  }

  return isWorking;
};
