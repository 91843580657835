import { MouseEventHandler } from 'react';

import { css } from '@emotion/react';

import Icon, { IconProps, IconType } from '@components/common/Icon/Icon';

import { palette } from '@lib/styles/palette';

export interface HeaderButtonPropsType {
  name?: string;
  icon?: IconType | IconProps;
  isDisabled?: boolean;
  onClick?: MouseEventHandler;
  className?: string;
  isTextBtn?: boolean;
}

const HeaderButton = ({
  className,
  name,
  onClick,
  isDisabled,
  icon,
  isTextBtn,
}: HeaderButtonPropsType): JSX.Element => {
  return (
    <button
      className={className}
      css={headerButtonStyle}
      onClick={onClick}
      disabled={isDisabled}
      name={name}
    >
      {isTextBtn
        ? name
        : icon && <Icon {...(typeof icon === 'string' ? { icon } : icon)} />}
    </button>
  );
};

const headerButtonStyle = css`
  width: 100%;
  height: 100%;
  font-weight: 400;
  font-size: 16px;
  color: ${palette.gray900};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default HeaderButton;
