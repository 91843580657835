import ReactLazyPreload from '@components/common/ReactLazyPreload';

import ImportantDayPage from '@pages/webView/ImportantDay/ImportantDayPage';
import OrderReviewListPage from '@pages/webView/Order/OrderReviewListPage';

const LoginPage = ReactLazyPreload(() => import('@pages/web/Login'));
const NotFoundPage = ReactLazyPreload(
  () => import('@pages/web/Error/NotFoundPage'),
);
const Home = ReactLazyPreload(() => import('@pages/web/Home'));
const RedirectSignUpApp = ReactLazyPreload(
  () => import('@pages/webView/App/RedirectSignUpApp'),
);
const ContentDetailCreateCommentPage = ReactLazyPreload(
  () => import('@pages/webView/Content/ContentDetailCreateCommentPage'),
);
const ContentDetailCreateReplyPage = ReactLazyPreload(
  () => import('@pages/webView/Content/ContentDetailCreateReplyPage'),
);
const ContentDetailModifyCommentPage = ReactLazyPreload(
  () => import('@pages/webView/Content/ContentDetailModifyCommentPage'),
);
const ContentDetailPage = ReactLazyPreload(
  () => import('@pages/webView/Content/ContentDetailPage'),
);
const CsContainer = ReactLazyPreload(() => import('@pages/webView/Cs'));
const CsInfo = ReactLazyPreload(
  () => import('@pages/webView/Cs/CsInfo/CsInfo'),
);
const CsInfoDetail = ReactLazyPreload(
  () => import('@pages/webView/Cs/CsInfo/CsInfoDetail'),
);
const CsService = ReactLazyPreload(() => import('@pages/webView/Cs/CsService'));
const CsQnaForm = ReactLazyPreload(() => import('@pages/webView/Cs/CsQnaForm'));
const CsQnaSearchStore = ReactLazyPreload(
  () => import('@pages/webView/Cs/CsQnaForm/CsSearchStore'),
);
const CsQna = ReactLazyPreload(() => import('@pages/webView/Cs/CsQna'));
const CsFaqListPage = ReactLazyPreload(
  () => import('@pages/webView/Cs/CsFaq/CsFaqListPage'),
);
const CsFaqSearchPage = ReactLazyPreload(
  () => import('@pages/webView/Cs/CsFaq/CsFaqSearchPage'),
);
const Notice = ReactLazyPreload(() => import('@pages/webView/Cs/Notice'));
const CvsVoucherPage = ReactLazyPreload(
  () => import('@pages/webView/CvsVoucher/CvsVoucherPage'),
);
const AddressManagePage = ReactLazyPreload(
  () => import('@pages/webView/Delivery/AddressManagePage'),
);
const AddressRegistPage = ReactLazyPreload(
  () => import('@pages/webView/Delivery/AddressRegistPage'),
);
const ReceiverAddressRegistPage = ReactLazyPreload(
  () => import('@pages/webView/Delivery/ReceiverAddressRegistPage'),
);
const AddressSearchPage = ReactLazyPreload(
  () => import('@pages/webView/Delivery/AddressSearchPage'),
);
const CvsDeliveryDetailPage = ReactLazyPreload(
  () => import('@pages/webView/Delivery/CvsDeliveryDetailPage'),
);
const CvsDeliveryListPage = ReactLazyPreload(
  () => import('@pages/webView/Delivery/CvsDeliveryListPage'),
);
const CvsProductListPage = ReactLazyPreload(
  () => import('@pages/webView/Delivery/CvsProductListPage'),
);
const CvsDeliveryRegistComplete = ReactLazyPreload(
  () => import('@pages/webView/Delivery/CvsDeliveryRegistComplete'),
);
const CvsDeliveryRegistPageForDeliveryAPI = ReactLazyPreload(
  () =>
    import(
      '@pages/webView/Delivery/DeliveryAPI/CvsDeliveryRegistPageForDeliveryAPI'
    ),
);
const DeliveryServiceInfoPage = ReactLazyPreload(
  () => import('@pages/webView/Delivery/DeliveryServiceInfoPage'),
);
const DeliveryStatusInfoPage = ReactLazyPreload(
  () => import('@pages/webView/Delivery/DeliveryStatusInfoPage'),
);

const DeliveryProductInfoPage = ReactLazyPreload(
  () => import('@pages/webView/Delivery/DeliveryProductInfoPage'),
);
const DeliveryTermsPage = ReactLazyPreload(
  () => import('@pages/webView/Delivery/DeliveryTermsPage'),
);

const DeliveryHistoryPage = ReactLazyPreload(
  () => import('@pages/webView/Delivery/DeliveryHistoryPage'),
);
const SafeDeliveryRegistEditPageForDeliveryAPI = ReactLazyPreload(
  () =>
    import(
      '@pages/webView/Delivery/DeliveryAPI/SafeDeliveryRegistEditPageForDeliveryAPI'
    ),
);
const EventDetailCreateCommentPage = ReactLazyPreload(
  () => import('@pages/webView/Event/EventDetailCreateCommentPage'),
);
const EventDetailCreateReplyPage = ReactLazyPreload(
  () => import('@pages/webView/Event/EventDetailCreateReplyPage'),
);
const EventDetailModifyCommentPage = ReactLazyPreload(
  () => import('@pages/webView/Event/EventDetailModifyCommentPage'),
);
const EventDetailPage = ReactLazyPreload(
  () => import('@pages/webView/Event/EventDetailPage'),
);
const FraudPage = ReactLazyPreload(
  () => import('@pages/webView/Fraud/FraudPage'),
);
const FraudResultPage = ReactLazyPreload(
  () => import('@pages/webView/Fraud/FraudResultPage'),
);
const LocationSharehPage = ReactLazyPreload(
  () => import('@pages/webView/Location/LocationSharePage'),
);
const StoreSearchPage = ReactLazyPreload(
  () => import('@pages/webView/Location/StoreSearchPage'),
);
const TownSearchPage = ReactLazyPreload(
  () => import('@pages/webView/Location/TownSearchPage'),
);
const TownResultPage = ReactLazyPreload(
  () => import('@pages/webView/Location/TownResultPage'),
);
const MyLocationMapPage = ReactLazyPreload(
  () => import('@pages/webView/Location/LocationMapPage'),
);
const AccountManagePage = ReactLazyPreload(
  () => import('@pages/webView/Order/AccountManagePage'),
);
const AccountRegistPage = ReactLazyPreload(
  () => import('@pages/webView/Order/AccountRegistPage'),
);
const NaverPayResultPage = ReactLazyPreload(
  () => import('@pages/webView/Order/NaverPayResultPage'),
);
const OrderHistoryDetailPage = ReactLazyPreload(
  () => import('@pages/webView/Order/OrderHistoryDetailPage'),
);
const CafePostingPassDetailPage = ReactLazyPreload(
  () => import('@pages/webView/Order/CafePostingPassDetailPage'),
);
const OrderHistoryListPage = ReactLazyPreload(
  () => import('@pages/webView/Order/OrderHistoryListPage'),
);
const OrderPaymentPage = ReactLazyPreload(
  () => import('@pages/webView/Order/OrderPaymentPage'),
);
const OrderResultPage = ReactLazyPreload(
  () => import('@pages/webView/Order/OrderResultPage'),
);
const SellerOrderResultPage = ReactLazyPreload(
  () => import('@pages/webView/Order/SellerOrderResultPage'),
);
const OrderBidirectionalReviewPage = ReactLazyPreload(
  () => import('@pages/webView/Order/OrderBidirectionalReviewPage'),
);
const OrderReviewDetailPage = ReactLazyPreload(
  () => import('@pages/webView/Order/OrderReviewDetailPage'),
);
const OrderTotalReviewPage = ReactLazyPreload(
  () => import('@pages/webView/Order/OrderTotalReviewPage'),
);
const OrderSaleReturnPage = ReactLazyPreload(
  () => import('@pages/webView/Order/OrderSaleReturnPage'),
);
const SettleDetailPage = ReactLazyPreload(
  () => import('@pages/webView/Order/SettleDetailPage'),
);
const SettleListPage = ReactLazyPreload(
  () => import('@pages/webView/Order/SettleListPage'),
);
const PointEventPage = ReactLazyPreload(
  () => import('@pages/webView/PointEvent/PointEventPage'),
);
const AppRegistrationFormPage = ReactLazyPreload(
  () => import('@pages/webView/RegistrationForm/AppRegistrationFormPage'),
);
const PayBadgeRegistration = ReactLazyPreload(
  () => import('@pages/webView/PayBadge/PayBadgeRegistration'),
);
const SearchPricePage = ReactLazyPreload(
  () => import('@pages/webView/Search/price/SearchPricePage'),
);
const ThreeDViewerPage = ReactLazyPreload(
  () => import('@pages/webView/ThreeDImageViewer/ThreeDViewerPage'),
);
const TermsPage = ReactLazyPreload(() => import('@pages/webView/Terms'));
const PrivatePolicyPage = ReactLazyPreload(
  () => import('@pages/webView/PrivacyPolicy'),
);
const MemberDisputePage = ReactLazyPreload(
  () => import('@pages/webView/MemberDispute'),
);
const YouthPolicyPage = ReactLazyPreload(
  () => import('@pages/webView/YouthPolicy'),
);
const DamageReportTermsPage = ReactLazyPreload(
  () => import('@pages/webView/DamageReportTerms'),
);
const WikiComment = ReactLazyPreload(
  () => import('@pages/webView/Wiki/WikiComment'),
);
const WikiCommentEdit = ReactLazyPreload(
  () => import('@pages/webView/Wiki/WikiCommentEdit'),
);
const WikiCommentRequest = ReactLazyPreload(
  () => import('@pages/webView/Wiki/WikiCommentRequest'),
);
const WikiDetail = ReactLazyPreload(
  () => import('@pages/webView/Wiki/WikiDetail/WikiDetail'),
);
const WikiInfoRequest = ReactLazyPreload(
  () => import('@pages/webView/Wiki/WikiInfoRequest/WikiInfoRequest'),
);
const WikiLabelList = ReactLazyPreload(
  () => import('@pages/webView/Wiki/WikiLabel/WikiLabelList'),
);
const WikiMain = ReactLazyPreload(
  () => import('@pages/webView/Wiki/WikiMain/WikiMain'),
);
const WikiCategoryList = ReactLazyPreload(
  () => import('@pages/webView/Wiki/WikiMenu/Category/WikiCategoryList'),
);
const ReferenceDocument = ReactLazyPreload(
  () => import('@pages/webView/Wiki/WikiMenu/Document/ReferenceDocument'),
);
const WikiMenu = ReactLazyPreload(
  () => import('@pages/webView/Wiki/WikiMenu/WikiMenu'),
);
const WikiReqeustMain = ReactLazyPreload(
  () => import('@pages/webView/Wiki/WikiRequest/Main/RequestMain'),
);
const WikiSearchMain = ReactLazyPreload(
  () => import('@pages/webView/Wiki/WikiSearch/WikiSearchMain'),
);
const WikiSearchResult = ReactLazyPreload(
  () =>
    import('@pages/webView/Wiki/WikiSearch/WikiSearchResult/WikiSearchResult'),
);
const RatingPage = ReactLazyPreload(() => import('@pages/webView/Rating'));
const MaintenancePage = ReactLazyPreload(
  () => import('@pages/webView/Maintenance'),
);

export default [
  {
    path: '/location/search/store',
    component: StoreSearchPage,
    exact: false,
    isAuth: true,
    title: '편의점 찾기 페이지',
  },
  {
    path: `/location/share`,
    component: LocationSharehPage,
    exact: false,
    isAuth: true,
    title: '위치 공유 페이지',
  },
  {
    path: `/location-town`,
    component: TownSearchPage,
    exact: false,
    isAuth: true,
    title: '동네 찾기 페이지',
  },
  {
    path: `/location-result`,
    component: TownResultPage,
    isAuth: true,
    exact: true,
    title: '동네 찾기 결과 페이지',
  },
  {
    path: `/location-map`,
    component: MyLocationMapPage,
    exact: true,
    isAuth: true,
    title: '동네 찾기 페이지',
  },
  {
    path: '/app',
    component: RedirectSignUpApp,
    exact: false,
    isAuth: false,
    title: '회원가입 이동 페이지',
  },
  {
    path: '/fraud/result',
    component: FraudResultPage,
    exact: true,
    isAuth: false,
    title: '사기조회결과',
  },
  {
    path: '/fraud',
    component: FraudPage,
    exact: true,
    isAuth: false,
    title: '사기조회',
  },
  {
    path: '/cs/notice',
    component: Notice,
    exact: true,
    isAuth: false,
    title: '공지사항',
  },
  {
    path: '/cs/notice/fraud/:seq?',
    component: Notice,
    exact: true,
    isAuth: false,
    title: '사기예방',
  },
  {
    path: '/cs/notice/service/:seq?',
    component: Notice,
    exact: true,
    isAuth: false,
    title: '공지사항',
  },
  {
    path: '/cs',
    component: CsContainer,
    exact: false,
    isAuth: false,
    title: '고객센터',
    routes: [
      {
        path: '/cs/main/',
        component: CsService,
        exact: true,
        isAuth: false,
        title: '고객센터메인',
      },
      {
        path: '/cs/info/',
        component: CsInfo,
        exact: true,
        isAuth: false,
        title: '고객센터 이용안내',
      },
      {
        path: '/cs/info/detail/:seq',
        component: CsInfoDetail,
        exact: true,
        isAuth: false,
        title: '고객센터 이용안내 상세보기',
      },
      {
        path: '/cs/qna/form',
        component: CsQnaForm,
        exact: true,
        isAuth: true,
        title: '1:1 문의',
      },
      {
        path: '/cs/qna/form/search',
        component: CsQnaSearchStore,
        exact: true,
        isAuth: true,
        title: '가게 검색',
      },
      {
        path: '/cs/qna',
        component: CsQna,
        exact: true,
        isAuth: true,
        title: '문의 내역',
      },
      {
        path: '/cs/faq/list',
        component: CsFaqListPage,
        exact: true,
        isAuth: false,
        title: '고객센터 faq 리스트',
      },
      {
        path: '/cs/faq/search',
        component: CsFaqSearchPage,
        exact: true,
        isAuth: false,
        title: '고객센터 faq 검색',
      },
    ],
  },
  {
    path: '/wiki',
    component: WikiMain,
    exact: true,
    isAuth: false,
    title: '위키메인',
  },
  {
    path: '/wiki/menu',
    component: WikiMenu,
    exact: true,
    isAuth: false,
    title: '위키메뉴',
  },
  {
    path: '/wiki/menu/reference/:categorySeq',
    component: ReferenceDocument,
    exact: true,
    isAuth: false,
    title: '위키참고문서',
  },
  {
    path: '/wiki/menu/category/:categorySeq',
    component: WikiCategoryList,
    exact: true,
    isAuth: false,
    title: '위키하위메뉴',
  },
  {
    path: '/wiki/menu/product/:categorySeq',
    component: ReferenceDocument,
    exact: true,
    isAuth: false,
    title: '위키카테고리결과문서',
  },
  {
    path: '/wiki/search',
    component: WikiSearchMain,
    exact: true,
    isAuth: false,
    title: '위키검색',
  },
  {
    path: '/wiki/search/:keyword',
    component: WikiSearchResult,
    exact: true,
    isAuth: false,
    title: '위키검색결과',
  },
  {
    path: '/wiki/request',
    component: WikiReqeustMain,
    exact: true,
    isAuth: true,
    title: '위키등록',
  },
  {
    path: '/wiki/request/:documentSeq',
    component: WikiReqeustMain,
    exact: true,
    isAuth: true,
    title: '위키정정',
  },
  {
    path: '/wiki/detail/:documentSeq',
    component: WikiDetail,
    exact: true,
    isAuth: false,
    title: '위키문서',
  },
  {
    path: '/wiki/detail/label/:title',
    component: WikiLabelList,
    exact: true,
    isAuth: false,
    title: '위키라벨리스트',
  },
  {
    path: '/wiki/:documentSeq/comment',
    component: WikiComment,
    exact: true,
    isAuth: true,
    title: '위키정보',
  },
  {
    path: '/wiki/:documentSeq/comment/requestInfo',
    component: WikiInfoRequest,
    exact: true,
    isAuth: true,
    title: '위키정보작성',
  },
  {
    path: '/wiki/:documentSeq/comment/requestComment',
    component: WikiCommentRequest,
    exact: true,
    isAuth: true,
    title: '위키댓글작성',
  },
  {
    path: '/wiki/:documentSeq/comment/:parentCommentSeq/edit/:commentSeq',
    component: WikiCommentEdit,
    exact: true,
    isAuth: true,
    title: '위키댓글수정',
  },
  {
    path: '/important-day/:date',
    component: ImportantDayPage,
    exact: true,
    isAuth: false,
    title: '중요일 이벤트',
  },
  {
    path: '/point-event/:date',
    component: PointEventPage,
    exact: true,
    isAuth: false,
    title: '출석 체크 이벤트',
  },
  {
    path: '/event/detail/:eventSeq',
    component: EventDetailPage,
    exact: true,
    isAuth: false,
    title: '이벤트 상세보기',
  },
  {
    path: '/event/detail/:eventSeq/comment',
    component: EventDetailCreateCommentPage,
    exact: true,
    isAuth: false,
    title: '이벤트 댓글 달기',
  },
  {
    path: '/event/detail/:eventSeq/comment/:commentSeq',
    component: EventDetailModifyCommentPage,
    exact: true,
    isAuth: false,
    title: '이벤트 댓글/답글 수정',
  },
  {
    path: '/event/detail/:eventSeq/reply/:commentSeq/:groupSeq/:parentStoreSeq',
    component: EventDetailCreateReplyPage,
    exact: true,
    isAuth: false,
    title: '이벤트 답글 달기',
  },
  {
    path: '/content/detail/:contentSeq',
    component: ContentDetailPage,
    exact: true,
    isAuth: false,
    title: '컨텐츠 상세보기',
  },
  {
    path: '/content/detail/:contentSeq/comment',
    component: ContentDetailCreateCommentPage,
    exact: true,
    isAuth: false,
    title: '컨텐츠 댓글 달기',
  },
  {
    path: '/content/detail/:contentSeq/comment/:commentSeq',
    component: ContentDetailModifyCommentPage,
    exact: true,
    isAuth: false,
    title: '컨텐츠 댓글/답글 수정',
  },
  {
    path: '/content/detail/:contentSeq/reply/:commentSeq/:groupSeq/:parentStoreSeq',
    component: ContentDetailCreateReplyPage,
    exact: true,
    isAuth: false,
    title: '컨텐츠 답글 달기',
  },
  {
    path: '/delivery-history/:type/:seq/:deliveryCorpCode?/:invoiceNo?',
    component: DeliveryHistoryPage,
    exact: true,
    isAuth: false,
    title: '배송 조회',
  },
  /** 거래 내역 */
  {
    path: '/order-history/list/sell',
    component: OrderHistoryListPage,
    exact: true,
    isAuth: false,
    title: '판매 거래 내역',
  },
  {
    path: '/order-history/list/buy',
    component: OrderHistoryListPage,
    exact: true,
    isAuth: false,
    title: '구매 거래 내역',
  },
  {
    path: '/order-history/detail/sell',
    component: OrderHistoryDetailPage,
    exact: true,
    isAuth: false,
    title: '판매 내역 상세',
  },
  {
    path: '/order-history/detail/buy',
    component: OrderHistoryDetailPage,
    exact: true,
    isAuth: false,
    title: '구매 내역 상세',
  },
  {
    path: '/cafe-posting-pass/sell',
    component: CafePostingPassDetailPage,
    exact: true,
    isAuth: false,
    title: '판매 내역 상세',
  },
  {
    path: '/cafe-posting-pass/purchase',
    component: CafePostingPassDetailPage,
    exact: true,
    isAuth: false,
    title: '구매 내역 상세',
  },
  /** 주문/결제/배송 */
  {
    // 앱 > 설정 > 계좌관리
    path: '/mysettlement',
    component: SettleListPage,
    exact: true,
    isAuth: false,
    title: '정산 관리',
  },
  {
    path: '/mysettlement-detail/:orderNo',
    component: SettleDetailPage,
    exact: true,
    isAuth: false,
    title: '정산 관리',
  },
  {
    path: '/account-regist',
    component: AccountRegistPage,
    exact: true,
    isAuth: false,
    title: '계좌 신규등록',
  },
  {
    path: '/account-manage',
    component: AccountManagePage,
    exact: true,
    isAuth: false,
    title: '계좌 관리',
  },
  {
    path: '/order/:productSeq',
    component: OrderPaymentPage,
    exact: true,
    isAuth: false,
    title: '주문/결제',
  },
  {
    name: '주문/결제 완료',
    isPrivate: true,
    exact: true,
    path: '/order-result',
    component: OrderResultPage,
    isAccess: false,
  },
  {
    name: '주문/결제 완료',
    isPrivate: true,
    exact: true,
    path: '/order-result/seller-center',
    component: SellerOrderResultPage,
    isAccess: false,
  },
  {
    path: '/pay/result/naver',
    component: NaverPayResultPage,
    exact: true,
    isAuth: true,
    title: '네이버페이 결제 결과',
  },
  {
    name: '반품수락 ( 배송지 정보 )',
    isPrivate: true,
    exact: true,
    path: '/order-sale-return/:orderNo/:type?',
    component: OrderSaleReturnPage,
    isAccess: false,
  },
  {
    name: '중고나라 결제방법',
    isPrivate: false,
    exact: true,
    path: '/payment-guide/:type?',
    // component: PaymentGuid,
    isAccess: true,
  },
  {
    name: '결제 관련 이용약관',
    isPrivate: false,
    exact: false,
    path: '/order-terms',
    // component: OrderTerms,
    isAccess: true,
  },
  {
    name: '안전거래 배송등록',
    isPrivate: true,
    exact: true,
    path: '/delivery-regist/:type/:orderNo',
    // component: SafeDeliveryRegistEditPage,
    component: SafeDeliveryRegistEditPageForDeliveryAPI,
    isAccess: false,
  },
  {
    name: '안전거래 배송수정',
    isPrivate: true,
    exact: true,
    path: '/delivery-edit/:type/:orderNo',
    // component: SafeDeliveryRegistEditPage,
    component: SafeDeliveryRegistEditPageForDeliveryAPI,
    isAccess: false,
  },
  {
    name: '안전거래 배송조회',
    isPrivate: true,
    exact: true,
    path: '/delivery-history/:type/:seq/:deliveryCorpCode?/:invoiceNo?',
    component: DeliveryHistoryPage,
    isAccess: false,
  },
  {
    name: '택배 서비스 이용안내',
    isPrivate: false,
    exact: true,
    path: '/delivery-service-info/:type(cvs|homepick)',
    component: DeliveryServiceInfoPage,
    isAccess: true,
  },
  {
    name: '배송상태 안내',
    isPrivate: false,
    exact: true,
    path: '/delivery-status-info/:type(cvs|half)',
    component: DeliveryStatusInfoPage,
    isAccess: true,
  },
  {
    name: '물품 정보 유의사항',
    isPrivate: false,
    exact: true,
    path: '/delivery-product-info',
    component: DeliveryProductInfoPage,
    isAccess: true,
  },
  {
    name: '택배이용약관',
    isPrivate: false,
    exact: true,
    path: '/delivery-terms/:id',
    component: DeliveryTermsPage,
    isAccess: true,
  },
  {
    name: '택배관리',
    isPrivate: true,
    exact: true,
    path: '/cvs/:menu?/:type?/:seq?/:orderStatus?',
    component: CvsDeliveryListPage,
    isAccess: false,
  },
  {
    name: '편의점택배 보낼 상품',
    isPrivate: true,
    exact: true,
    path: '/cvs-select-products',
    component: CvsProductListPage,
    isAccess: false,
  },
  {
    name: '편의점택배 신청',
    isPrivate: true,
    exact: true,
    path: '/cvs-regist/:type/:seq/:orderStatus?',
    component: CvsDeliveryRegistPageForDeliveryAPI,
    // component: CvsDeliveryRegistPage,
    isAccess: false,
  },
  {
    name: '편의점택배 상세',
    isPrivate: true,
    exact: true,
    path: '/cvs-detail/:type/:deliverySeq/:productSeq?',
    component: CvsDeliveryDetailPage,
    isAccess: false,
  },
  {
    name: '편의점택배 신청완료',
    isPrivate: true,
    exact: true,
    path: '/cvs-complete/:deliveryCorpName',
    component: CvsDeliveryRegistComplete,
    isAccess: false,
  },
  {
    name: '편의점택배 접수처 찾기',
    isPrivate: true,
    exact: true,
    path: '/cvs-find/:deliveryCorpName?',
    // component: CvsFind,
    isAccess: false,
  },
  {
    path: '/voucher',
    component: CvsVoucherPage,
    exact: true,
    isAuth: false,
    title: '편의점 픽업 바우처',
  },
  {
    name: '주소 찾기',
    isPrivate: true,
    exact: true,
    path: '/address-search/:registType/:seq?',
    component: AddressSearchPage,
    isAccess: false,
  },
  {
    name: '주소 관리',
    isPrivate: true,
    exact: true,
    path: '/address-manage',
    component: AddressManagePage,
    isAccess: false,
  },
  {
    name: '배송지 추가 (받는 분)',
    isPrivate: true,
    exact: true,
    path: '/address-regist/receiver',
    component: ReceiverAddressRegistPage,
    isAccess: false,
  },
  {
    name: '배송지 추가 (보내는 분)',
    isPrivate: true,
    exact: true,
    path: '/address-regist',
    component: AddressRegistPage,
    isAccess: false,
  },
  {
    name: '주소 수정',
    isPrivate: true,
    exact: true,
    path: '/address-edit/:seq',
    component: ReceiverAddressRegistPage,
    isAccess: false,
  },
  {
    path: '/review/:orderNo',
    component: OrderBidirectionalReviewPage,
    exact: true,
    isAuth: true,
    title: '후기 작성하기',
  },
  {
    path: '/review/detail/:orderNo',
    component: OrderReviewDetailPage,
    exact: true,
    isAuth: true,
    name: '후기 상세',
  },
  {
    path: '/review/store/:storeSeq',
    component: OrderReviewListPage,
    exact: true,
    isAuth: true,
    name: '가게 거래 후기',
  },
  {
    path: '/review/store/all/:storeSeq',
    component: OrderTotalReviewPage,
    exact: true,
    isAuth: true,
    name: '후기 전체 보기',
  },
  {
    path: '/search-price',
    component: SearchPricePage,
    exact: true,
    isAuth: false,
    title: '시세조회',
  },
  {
    path: '/app-registration-form',
    component: AppRegistrationFormPage,
    exact: true,
    isAuth: false,
    title: '카페 상품 앱 자동 등록 신청서',
  },
  {
    path: '/pay-badge-regist',
    component: PayBadgeRegistration,
    exact: true,
    isAuth: true,
    title: '카페 상품 앱 등록 시, 중나페이 적용 신청 페이지',
  },
  {
    path: '/terms',
    component: TermsPage,
    exact: true,
    isAuth: false,
    title: '이용약관 페이지',
  },
  {
    path: '/terms/location',
    component: TermsPage,
    exact: true,
    isAuth: false,
    title: '위치기반 이용약관 페이지',
  },
  {
    path: '/privacy-policy',
    component: PrivatePolicyPage,
    exact: true,
    isAuth: false,
    title: '개인정보처리방침 페이지',
  },
  {
    path: '/member/dispute',
    component: MemberDisputePage,
    exact: true,
    isAuth: false,
    title: '분쟁처리절차 페이지',
  },
  {
    path: '/youthpolicy',
    component: YouthPolicyPage,
    exact: true,
    isAuth: false,
    title: '청소년보호정책 페이지',
  },
  {
    path: '/damage-report-terms',
    component: DamageReportTermsPage,
    exact: true,
    isAuth: false,
    title: '피해신고 공정준수 약관 페이지',
  },
  {
    path: '/3dimage-viewer/:id',
    component: ThreeDViewerPage,
    exact: true,
    isAuth: false,
    title: '3D 이미지',
  },
  {
    path: '/login',
    component: LoginPage,
    exact: true,
    isAuth: false,
    title: '로그인',
  },
  {
    path: '/',
    component: Home,
    exact: true,
    isAuth: false,
    title: '메인',
  },
  {
    path: '/404',
    component: NotFoundPage,
    exact: false,
    isAuth: false,
    title: '404',
  },
  {
    path: '/rating',
    component: RatingPage,
    exact: false,
    isAuth: false,
    title: '유저 등급 및 등급 안내 페이지',
  },
  {
    path: '/maintenance',
    component: MaintenancePage,
    exact: true,
    isAuth: false,
    title: '점검',
  },
];
