export function isNumeric(data: number): boolean {
  return !Number.isNaN(data);
}

export function addZero(num: number): string {
  return num < 10 ? `0${num}` : `${num}`;
}

export function onlyNumber(string = ''): string {
  return string.trim().replace(/[^0-9]/g, '');
}

export function stringToNumber(string: string): number {
  return Number(onlyNumber(string));
}

export function numFormat(num: string | number): string {
  const number = typeof num === 'string' ? stringToNumber(num) : num;
  return Number(number)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    .split('.')[0];
}

export function getPercentage(x: number, y: number): string {
  return y < x ? '100%' : `${Math.floor((x / y) * 100)}%`;
}

export const formatPrice = (n: string | number): string => {
  if (isNumeric(Number(n))) {
    return Number(n) === 0 ? '무료나눔' : `${numFormat(Number(n))}원`;
  }
  return '';
};
export const formatNumberPrice = (n: string | number): string => {
  if (isNumeric(Number(n))) {
    return `${numFormat(Number(n))}원`;
  }
  return '';
};

export const phoneFomatter = (tel: string, type = 0): string => {
  if (tel.length === 11) {
    if (type === 0) {
      return tel.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');
    }
    return tel.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  }
  if (type === 0) {
    return tel.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
  }
  return tel.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
};

export const cvsReservationNumFormatter = (reservationNo: string) => {
  return reservationNo.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');
};
