import { ReactElement } from 'react';

import { css, SerializedStyles } from '@emotion/react';

import useOpenAppWithWebUrl from '@lib/hook/deepLink/useOpenAppWithWebUrl';
import { palette } from '@lib/styles/palette';
import { setCloseTodayAppDownloadPopUp } from '@lib/utils/popUp';

import CiSquircleR16 from '@asset/img/ci-squircle-r16.png';

interface PopUpChildren {
  toggle: () => void;
  opacity: SerializedStyles;
}

function PopUpAppDownload({ toggle, opacity }: PopUpChildren): ReactElement {
  const openAppWithWebUrl = useOpenAppWithWebUrl();
  // 오늘 하루 보지 않기
  const onClickDisabledToday = () => {
    setCloseTodayAppDownloadPopUp();
    toggle();
  };

  const openAppBtnOnClickHandler = () => {
    openAppWithWebUrl(window.location.href);
  };

  return (
    <div css={popUp}>
      <div css={infomation}>
        <div css={textWrap}>
          <p>중고나라 앱 설치하고</p>
          <p>편리한 거래 경험을 해보세요</p>
          <p>{/* 소구문구 들어가기 */}</p>
        </div>
        <img src={CiSquircleR16} alt="logo" />
      </div>
      <button type="button" css={orangeBtn} onClick={openAppBtnOnClickHandler}>
        앱으로 보기
      </button>
      <button type="button" css={closeBtn} onClick={onClickDisabledToday}>
        오늘 하루 보지 않기
      </button>
      <div css={[dim, opacity]} />
    </div>
  );
}

const popUp = css`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 234px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  z-index: 500;
  bottom: 0;

  padding: 24px 20px 20px;
`;

const infomation = css`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  img {
    width: 80px;
    height: 80px;
  }
`;

const textWrap = css`
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;

  p {
    margin-top: 8px;

    &:nth-of-type(2) {
      margin-top: 6px;
    }

    &:last-child {
      color: #a3a3a3;
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

const orangeBtn = css`
  width: 100%;
  margin-top: 26px;
  padding: 15px 0;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  border-radius: 4px;
  background-color: #ff6900;
`;

const closeBtn = css`
  width: 100%;
  margin-top: 16px;
  color: #fff;
  font-size: 12px;
  background: none;
`;

const dim = css`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: ${palette.black10};
  opacity: 0.9;
`;

export default PopUpAppDownload;
