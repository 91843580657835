import { ReactElement, ReactNode } from 'react';

import { css } from '@emotion/react';

import useResponsive from '@hook/useResponsive';

import sizeType from '@lib/styles/sizeType';

interface SpaceLayoutProps {
  children?: ReactNode;
  margin?: [number, number];
  padding?: [number, number];
  border?: [string, string];
}

interface RangeSpaceType {
  readonly [key: string]: number;
}

const SpaceLayout = ({
  children,
  margin = [0, 0],
  padding = [0, 0],
  border = ['', ''],
}: SpaceLayoutProps): ReactElement => {
  const { isMobile, isTablet } = useResponsive();

  const rangeSpace: RangeSpaceType = {
    MOBILE_SIZE: 20,
    TABLET_SIZE: 24,
    PC_SIZE: 40,
  } as const;

  const resizingSpace = (): number => {
    if (isMobile) return rangeSpace.MOBILE_SIZE;
    if (isTablet) return rangeSpace.TABLET_SIZE;
    return rangeSpace.PC_SIZE;
  };

  return (
    <div css={spaceLayout(resizingSpace, margin, padding, border)}>
      {children}
    </div>
  );
};

const spaceLayout = (
  resizeFunc: () => number,
  margin: [number, number],
  padding: [number, number],
  border: [string, string],
) => css`
  max-width: ${sizeType.sizeLayout.large};
  margin: auto;
  position: relative;
  padding-left: ${resizeFunc()}px;
  padding-right: ${resizeFunc()}px;
  ${margin.map((value: number, i: number): string => {
    if (i === 0) return `margin-top: ${value}px`;
    if (i === 1) return `margin-bottom: ${value}px`;
    return '';
  })}
  ${padding.map((value: number, i: number): string => {
    if (i === 0) return `padding-top: ${value}px`;
    if (i === 1) return `padding-bottom: ${value}px`;
    return '';
  })}
  ${border.map((value: string, i: number): string => {
    if (i === 0) return `border-top: ${value}`;
    if (i === 1) return `border-bottom: ${value}`;
    return '';
  })}
`;

export default SpaceLayout;
