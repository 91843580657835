import { atom } from 'recoil';

import { InitData } from '@type/web/init';

export const initData = atom<InitData>({
  key: 'INIT/INIT_DATA',
  default: {
    domain: [
      { name: 'main', url: 'https://main-api-stg.joongna.com' },
      { name: 'auto', url: 'https://auto-api-stg.joongna.com' },
      { name: 'chat', url: 'https://edge-stg.joongna.com/api/chat' },
      { name: 'front', url: 'https://stage.m.joongna.com' },
      { name: 'point', url: 'https://point-api-stg.joongna.com' },
      { name: 'pick-point', url: 'https://edge-stg.joongna.com/api/pickpoint' },
      { name: 'ticket', url: 'https://ticket-api-stg.joongna.com' },
      { name: 'marketing', url: '' },
      { name: 'search', url: 'https://search-api-stg.joongna.com' },
      { name: 'user', url: 'https://main-api-stg.joongna.com' },
      { name: 'init', url: 'https://boot-stg.joongna.com/api/init' },
      { name: 'message', url: 'https://edge-stg.joongna.com/api/message' },
      { name: 'product', url: 'https://product-api-stg.joongna.com' },
      { name: 'image', url: 'https://img2-stg.joongna.com' },
      { name: 'image-wiki', url: 'https://img-wiki-stg.joongna.com' },
      { name: 'wiki', url: 'https://edge-stg.joongna.com/api/wiki' },
      { name: 'wikiSearch', url: 'https://search-wiki-api-stg.joongna.com' },
      { name: 'common', url: 'https://common.joongna.com' },
      { name: 'escrow', url: '' },
      { name: 'aws-api-gateway', url: 'https://gw-stg.joongna.com' },
      {
        name: 'standard-price',
        url: 'https://standard-price-api-stg.joongna.com',
      },
      { name: 'vertical', url: 'https://edge-stg.joongna.com/api/vertical' },
      { name: 'media', url: 'https://edge-stg.joongna.com/api/media' },
    ],
    notification: {
      chat: {
        adminStoreSeq: 4243078,
        topBannerUrl: '{main}/notice/2/banner',
        warnImageUrl: '{image}/common/chat/warnImage.png',
        warnText:
          '중고나라 채팅, 중고나라 페이가 가장 안전합니다.\n거래 시 메신저(카카오톡, 라인 등)로 유도하거나\nURL(링크)결제 유도 및 직접송금을 요구하는 경우\n피해 위험이 있을 수 있으니 주의하세요!',
        warnButtonText: '중고나라 페이 이용방법',
        warnButtonLink: '{front}/cs/info-detail/8',
      },
      alarm: {
        minKeywordAlarmLength: 1,
        keywordAlarmValidToast: '1자~12자의 키워드만\n등록 가능합니다.',
        topBannerUrl: '{main}/notice/1/banner',
      },
    },
    gnb: {
      refreshTime: '2020-03-11 10:00:00',
      list: [
        { name: '우리동네', url: '/maintab/town' },
        { name: '티켓/쿠폰', url: '/maintab/ticket' },
      ],
    },
    user: {
      join: {
        title: '회원가입',
        content:
          '<p>중고나라 이용약관, 위치정보<br>이용약관과 개인정보 수집 및 이용에<br>동의하셔야 <span>회원 가입이 가능합니다.</span></p>',
        termGuideMessage:
          '전체 이용약관(필수), 개인정보 수집 및 이용(필수), 마케팅 수신(선택)에 동의합니다.',
        termAdditionalMessage:
          '* 선택 동의항목에 동의하지 않아도 서비스를 이용할 수 있습니다.',
        responsibility:
          '“중고나라” 상점의 판매상품을 제외한 모든 상품들에 대하여 (주)중고나라는 통신판매중개자로서 거래 당사자가 아니며 판매 회원과 구매 회원 간의 상품거래 정보 및 거래에 관여하지 않고 어떠한 의무와 책임도 부담하지 않습니다.',
        terms: [
          {
            name: '중고나라 이용약관',
            isRequired: true,
            linkTitle: '약관 전체보기',
            url: '/terms?tab=service&only=true',
          },
          {
            name: '위치정보 이용약관',
            isRequired: true,
            linkTitle: '약관 전체보기',
            url: '/terms?tab=location&only=true',
          },
          {
            name: '개인정보 수집 및 이용',
            isRequired: true,
            linkTitle: '내용보기',
            url: '/terms?tab=privacyCollection&only=true',
          },
          { name: 'SMS, E-mail, PUSH 마케팅 수신', isRequired: false },
        ],
      },
    },
    payment: { safePayGuideUrl: '{web}/event/detail/615' },
    inquire: {
      url: 'https://cafe-help-stg.joongna.com',
      waitMessage:
        '문의하신 내용은 순차적으로 답변드릴 예정입니다.\n문의내용 및 유형에 따라 답변기간은 영업일 기준 최대 7일 정도 소요될 수 있으니 조금만 시간 양해 부탁드리겠습니다. 감사합니다.',
      cafeTypePopupText:
        '<strong><font color="#333333">중고나라 카페 관련 문의는\n중고나라 카페전용 고객센터에서\n상담이 가능합니다.</font></strong><br><br><font color="#979797">중고나라 카페전용 고객센터로 이동하시겠습니까?</font>',
      information:
        '<strong>카페 관련 문의는</strong> <a href="https://cafe-help-stg.joongna.com"><font color="#11c482">https://cafe-help-stg.joongna.com</font></a>를 통해서 남겨주시면 확인 후 답변드리고 있습니다.',
    },
    productDetail: { isReviewAverageView: true },
    myStore: {
      isReviewCountView: true,
      inaccessibleMessage: '접근 불가 상품입니다.\n다시 확인해 주세요.',
      userReportMessage: '다수의 사용자신고로 숨김 처리되었습니다.',
      adminReportMessage: '관리자에 의해 숨김 처리 되었습니다.',
    },
    configuration: {
      appExceptionMessage: {
        serverException:
          '서버 연결이 원활하지 않습니다.\n잠시 후 다시 이용해 주시기 바랍니다.',
        networkException:
          '네트워크 연결 상태가 좋지 않습니다.\n연결 상태 확인 후 다시 시도해 주세요.',
      },
      popup: {
        legalRepresentative: {
          popupTitle: '법정 대리인 인증',
          popupDesc:
            '고객님은 만 14세 미만 가입자로 보호자(법정 대리인) 인증이 필요합니다.',
          popupPassDesc:
            'PASS 앱이 실행됐을 경우, 중고나라 앱으로 돌아와서 [문자로 인증하기]를 진행해주세요.',
          checkText: '법정대리인의 개인정보 수집에 동의합니다.',
          checkDesc:
            '개인정보보호법 제22조 및 제 39조의3 제4항에 따라, 보호자(법정대리인) 인증이 필수로 진행되며, 이를 동의하지 않을 경우 서비스 이용이 불가합니다.',
          termsDesc: [
            {
              title: '- 수집항목 : ',
              desc: '이름, 생년월일, 성별, 휴대폰번호, 본인인증정보(ci/di)',
            },
            {
              title: '- 수집목적 : ',
              desc: '법정대리인 본인확인 및 미성년자 회원가입/SNS 연동 시 법정대리인의 동의',
            },
            { title: '- 보유기간 : ', desc: '회원탈퇴 시 파기' },
          ],
          buttonText: '법정 대리인 휴대폰 인증하기',
        },
      },
      toggle: {
        sso: { isActive: true },
        home: { isActive: true },
        homeV2: { isActive: true },
      },
      footer: {
        companyName: '',
        companyAdditionalTitle: '(주)중고나라 사업자 정보',
        companyAdditional:
          '(주)중고나라 | 대표자 : 최인욱\n사업자 등록번호 : 215-87-87482\n통신판매신고번호 : 제2019-서울서초-0097호\n주소 : 서울특별시 서초구 서초대로 301 동익성봉빌딩 7층\n대표번호 : 1533-7861\n메일 : joongna@joonggonara.co.kr\n호스팅제공자 : 아마존웹서비스',
        responsibility:
          '“중고나라” 상점의 판매상품을 제외한 모든 상품들에 대하여 (주)중고나라는 통신판매중개자로서 거래 당사자가 아니며 판매 회원과 구매 회원 간의 상품거래 정보 및 거래에 관여하지 않고 어떠한 의무와 책임도 부담하지 않습니다.',
        terms: [
          {
            name: '회사소개',
            type: 'info',
            url: 'https://jn-careers.notion.site/05e979832ee249989f3810af08271744',
          },
          { name: '이용약관', type: 'terms', url: '/terms' },
          {
            name: '개인정보처리방침',
            type: 'privacy-policy',
            url: '/privacy-policy',
            style: 'bold',
          },
          { name: '분쟁처리절차', type: 'member', url: '/member/dispute' },
          { name: '청소년보호정책', type: 'youthpolicy', url: '/youthpolicy' },
          {
            name: '사업자정보확인',
            type: 'external',
            url: 'http://www.ftc.go.kr/bizCommPop.do?wrkr_no=2158787482&apv_perm_no=',
          },
        ],
      },
      webViewCache: {
        enabled: true,
        url: '/webview-cache',
        webViewTimeout: 3000,
      },
      placeholder: { home: { search: '검색어를 입력하세요.' } },
      redirect: { root: { url: 'main' } },
    },
    shortcuts: [
      {
        title: '우리동네',
        isLogin: false,
        titleColor: '#333333',
        image: {
          url: '{image}/common/home/v2/shortcut/my_village.png',
          width: 24,
          height: 24,
        },
        backgroundImage: {
          url: '{image}/common/home/v2/shortcut/background.png',
          width: 107,
          height: 40,
        },
        linkUrl: '/maintab/town',
        linkText: '우리동네 가기',
        isFeedBackAction: true,
      },
      {
        title: '거래내역',
        isLogin: true,
        titleColor: '#ff6c2d',
        image: {
          url: '{image}/common/home/v2/shortcut/order_history.png',
          width: 24,
          height: 24,
        },
        backgroundImage: {
          url: '{image}/common/home/v2/shortcut/background.png',
          width: 107,
          height: 40,
        },
        linkUrl: '/order-history/list/buy',
        linkText: '거래내역 가기',
        isFeedBackAction: true,
      },
    ],
    quick: {
      widthSize: 5,
      menu: [
        {
          title: '중고폰',
          isLogin: false,
          icon: {
            url: '{image}/common/home/v2/quick/mobile_v2.png',
            width: 24,
            height: 24,
          },
          linkUrl: '/mobile',
          requireLogin: true,
          isFeedBackAction: true,
          isAnimationImage: false,
        },
        {
          title: '이벤트',
          isLogin: false,
          icon: {
            url: '{image}/common/home/v2/quick/event.png',
            width: 24,
            height: 24,
          },
          linkUrl: '/event-list',
          requireLogin: true,
          isFeedBackAction: true,
          isAnimationImage: false,
        },
        {
          title: '티켓',
          isLogin: false,
          icon: {
            url: '{image}/common/home/v2/quick/ticket.gif',
            width: 0,
            height: 0,
          },
          linkUrl: '/maintab/ticket',
          requireLogin: true,
          isFeedBackAction: true,
          isAnimationImage: true,
        },
        {
          title: '시세조회',
          isLogin: false,
          icon: {
            url: '{image}/common/home/v2/quick/avg_price.png',
            width: 24,
            height: 24,
          },
          linkUrl: '/avg-price',
          requireLogin: true,
          isFeedBackAction: true,
          isAnimationImage: false,
        },
        {
          title: '거래후기',
          isLogin: false,
          icon: {
            url: '{image}/common/home/v2/quick/trade_review.png',
            width: 24,
            height: 24,
          },
          linkUrl:
            'https://cafe.naver.com/ArticleList.nhn?search.clubid=10050146&search.menuid=122&search.boardtype=L',
          requireLogin: false,
          isFeedBackAction: true,
          isAnimationImage: false,
        },
      ],
    },
    splash: {
      lastUpdateTime: '2021-06-14 15:30:00',
      image: {
        url: '{image}/common/home/v2/splash/mobile_splash_210614.jpg',
        foldUrl: '{image}/common/home/v2/splash/fold_splash_210614.jpg',
      },
    },
    logo: {
      home: {
        icon: {
          front: {
            url: '{image}/common/home/v2/logo/home/front_icon.png',
            width: 120,
            height: 120,
          },
        },
        full: {
          front: {
            url: '{image}/common/home/v2/logo/home/front_full.png',
            width: 816,
            height: 176,
          },
        },
      },
    },
    search: {
      valid: { minSearchLength: 1, minSearchToast: '검색어를 입력해 주세요.' },
    },
    grade: { userGradeGuideUrl: '{front}/rating' },
    phone: {
      footer: {
        companyAdditional:
          '(주)에이씨엘 | 대표자 : 배황근\n사업자등록번호 : 263-86-003\n통신판매업번호 : 제2019-인천연수구-1257호\n주소 : 인천광역시 연수구 송도미래로 30 (송도동) E동 7층 708호\n카카오 채널 고객센터 : @중고나라\n모바일고객센터 번호 : 032-503-7903\n대표이메일 : ycy005@aclife.co.kr\n응대가능시간 : 주말, 공휴일 제외 9:30~18:30 / 점심시간 12:30~13:30',
        responsibility:
          '중고나라 모바일 서비스에서 제공되는 상품 정보는 주식회사 에이씨엘에서 제공하며, 중고나라 모바일 서비스에서 제공되는 모든 상품과 관련된 의무와 책임은 주식회사 에이씨엘의 약관을 따릅니다.',
      },
    },
    navigation: {
      bottom: {
        buttons: {
          regist: {
            title: '등록',
            shortcuts: [
              {
                title: '일반상품',
                titleColor: '#979797',
                linkUrl: '/product-regist',
                isLogin: true,
                image: {
                  url: '{image}/common/home/v2/navigation/bottom/shortcut/general_product.png',
                  width: 24,
                  height: 24,
                },
                backgroundImage: {
                  url: '{image}/common/home/v2/navigation/bottom/shortcut/background.png',
                  width: 107,
                  height: 40,
                },
              },
              {
                title: '자동차',
                titleColor: '#979797',
                linkUrl: '/auto/product-regist',
                isLogin: true,
                image: {
                  url: '{image}/common/home/v2/navigation/bottom/shortcut/car.png',
                  width: 24,
                  height: 24,
                },
                backgroundImage: {
                  url: '{image}/common/home/v2/navigation/bottom/shortcut/background.png',
                  width: 107,
                  height: 40,
                },
              },
            ],
          },
          cancel: { title: '취소' },
          menus: [
            {
              title: '홈',
              name: 'home',
              linkUrl: '/home',
              isLogin: false,
              image: {
                selectUrl:
                  '{image}/common/home/v2/navigation/bottom/home_select.png',
                unSelectUrl:
                  '{image}/common/home/v2/navigation/bottom/home_unselect.png',
                spriteUrl: '',
              },
            },
            {
              title: '카테고리',
              name: 'category',
              linkUrl: '/category',
              isLogin: false,
              image: {
                selectUrl:
                  '{image}/common/home/v2/navigation/bottom/category_select.png',
                unSelectUrl:
                  '{image}/common/home/v2/navigation/bottom/category_unselect.png',
                spriteUrl: '',
              },
            },
            {
              title: '마이태그',
              name: 'mytag',
              linkUrl: '/mytag',
              isLogin: true,
              image: {
                selectUrl:
                  '{image}/common/home/v2/navigation/bottom/my_tag_select.png',
                unSelectUrl:
                  '{image}/common/home/v2/navigation/bottom/my_tag_unselect.png',
                spriteUrl: '',
              },
            },
            {
              title: '내정보',
              name: 'mypage',
              linkUrl: '/mypage',
              isLogin: true,
              image: {
                selectUrl:
                  '{image}/common/home/v2/navigation/bottom/my_info_select.png',
                unSelectUrl:
                  '{image}/common/home/v2/navigation/bottom/my_info_unselect.png',
                spriteUrl: '',
              },
            },
          ],
        },
      },
    },
    auto: {
      garage: {
        mileageValidDescription: '숫자만 입력해주세요.',
        accidentStandard:
          '자동차 관리법에 따라 차량의 성능에 문제가 되는 부분이 있거나 성능점검기록부 상에서 프레임의 수리/교환은 사고로 분류하고 외판의 수리/교환은 무사고로 분류합니다.',
        loanLinkUrl: '{front}/auto/autoloan',
        loanInquiryLinkUrl: '{front}/auto/instalment-check',
      },
      inspect: {
        agreementProvideUrl: '/auto-thirdparties-terms',
        agreementPopupAosWidth: 300,
        agreementPopupAosHeight: 373,
        agreementPopupIosWidth: 315,
        agreementPopupIosHeight: 313,
        reservationChangePopupTitle: '진단예약을 변경 하시겠습니까?',
        reservationChangePopupContent:
          '예약일시만 변경 가능합니다. 정비소 변경은 진단취소 후에 다시 진단신청을 해주세요.',
        reservationChangeFailPopupContent:
          '예약시간이 지났거나 진단이 시작된 경우 예약을 변경할 수 없습니다.',
        reservationCancelPopupTitle: '진단신청을 취소하시겠습니까?',
        reservationCancelPopupContent:
          '빠른 판매를 원하시면 차량진단을 받으세요. 차량진단을 받지 않은 차량은 할부대출을 받을 수 없습니다. \n* 진단 취소 시 내 차고에서 진단신청 가능',
        reservationCancelFailPopupContent:
          '예약시간이 지났거나 진단이 시작된 경우 진단신청을 취소할 수 없습니다.',
        reservationChangeApiFailPopupContent:
          '선택한 날짜와 시간에는 예약이 불가능합니다. 다른 날짜와 시간을 선택해주세요.',
        reservationChangeCancelPopupContent:
          '진단예약 변경을 취소 하시겠습니까?',
        reservationIncompletePopupTitle: '차량진단 후 차량 문의가 가능합니다.',
        reservationIncompletePopupContent:
          '찜하시면 차량진단이 완료된 후에푸시 알림을 받을 수 있습니다.',
        reservationCompletePopupTitle:
          '실매물 확인을 위해직거래 등록 시 차량진단 필수',
        reservationCompletePopupContent:
          '차량진단 후에 직거래 등록이 완료됩니다.',
        inspectFirstPopupTitle: '직거래 등록 완료!',
        inspectFirstPopupContent:
          '직거래 판매 등록이 완료되었습니다.\n등록한 차량을 확인하시겠습니까?',
        isInspectSecondPopup: false,
        reservationCompleteApiFailPopupContent:
          '정비소 사정으로 진단신청이 취소되었습니다. 진단신청을 다시 시도해주세요.',
        commonExceptionPopupTitle: '올바르지 않은 URL 입니다.',
        commonExceptionPopupContent: '잠시 후 다시 시도해 주세요.',
        inspectValidateContent: '필수항목 입력 후 진단신청이 가능합니다.',
        inspectSecondPopupImageUrl:
          '{image}/common/auto/inspectSecondPopup.png',
      },
      escrow: { escrowUrl: '{escrow}/escrow' },
    },
    paging: {
      configs: { searchMain: { firstQuantity: 10, quantity: 10, page: 0 } },
    },
  },
});

export default { initData };
