import getUTF8 from '@lib/utils/getUTF8';

function getQueryStringSafe(name: string, url = window.location.href) {
  try {
    const results = new RegExp(`[?&]${name}=([^&#]*)`).exec(url);
    if (results === null) {
      return '';
    }
    let result = results[1];
    if (result.indexOf('+') > 0) {
      result = decodeURI(results[1]).replace(/\+/g, ' ');
    }
    result = result.replace(/%(?![0-9a-fA-F][0-9a-fA-F]+)/g, '%25');
    return decodeURIComponent(getUTF8(result)) || '';
  } catch (e) {
    console.log(e);
    return '';
  }
}

export default getQueryStringSafe;
